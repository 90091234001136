import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { PlanFeature, StripeProductType } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface CalendlyState {}

export const state = (): CalendlyState => ({})

export const getters: GetterTree<CalendlyState, RootState> = {

}

export const mutations: MutationTree<CalendlyState> = {
}

export const actions: ActionTree<CalendlyState, RootState> = {
  async openCalendly ({ rootGetters, dispatch }: any) {
    const meetingExpertWithoutAbbyContact = this.$planManager.has([StripeProductType.MEETING_WITH_AN_EXPERT])
    const meetingExpertWithAbbyContact = this.$planManager.has([StripeProductType.ABBY_CONTACT]) || this.$planManager.has(this.$planManager.whichPlanFor(PlanFeature.CREATION_CALL_EXPERT))

    const meetingExpertNewLink = 'https://calendly.com/abby-customer-success/echangez-avec-un-conseiller-expert-1'
    const meetingExpertWithoutAbbyContactLink = 'https://calendly.com/abby-customer-success/echangez-avec-un-conseiller-expert-3'
    const meetingExpertWithAbbyContactLink = 'https://calendly.com/abby-customer-success/echangez-avec-un-conseiller-expert-2'

    try {
      const openCalendlyLink = async () => {
        const confirm = await this.$confirmManager.ask(
          {
            title: 'Accompagnement personnalisé',
            content: 'Vous allez être redirigé vers la page de prise de rendez-vous avec notre expert',
            confirmText: 'Prendre rendez-vous',
            confirmButton: { color: 'primary', depressed: true },
          })
        if (!confirm) {
          return
        }
        let link = meetingExpertWithAbbyContact
          ? meetingExpertWithAbbyContactLink
          : meetingExpertWithoutAbbyContact ? meetingExpertWithoutAbbyContactLink : ''
        link += `?email=${rootGetters['auth/user'].email}&full_name=${rootGetters['auth/user'].firstname + ' ' + rootGetters['auth/user'].lastname}&phone_number=${rootGetters['auth/user'].phone || '+33'}`
        window.open(link, '_blank')
      }

      await dispatch('company/fetchPlans', {}, { root: true })
      if ((meetingExpertWithoutAbbyContact || meetingExpertWithAbbyContact) && this.$planManager.hasAccessTo(PlanFeature.CREATION_CALL_EXPERT)) {
        await openCalendlyLink()
        return
      }
      if (!this.$planManager.hasAccessTo(PlanFeature.CREATION_CALL_EXPERT)) {
        this.$planManager.openAbbyPlansModal({
          feature: PlanFeature.CREATION_CALL_EXPERT,
          callbacks: {
            succeed: () => {
              openCalendlyLink()
            },
          },
        })
        return
      }
      const link = `${meetingExpertNewLink}?email=${rootGetters['auth/user'].email}&full_name=${rootGetters['auth/user'].firstname + ' ' + rootGetters['auth/user'].lastname}&phone_number=${rootGetters['auth/user'].phone || '+33'}`
      window.open(link, '_blank')
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
}
