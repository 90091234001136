
import { Action, Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { ReadCompanyOpportunityCategory, ReadOpportunity, UpdateOpportunity } from '@abby/core-legacy'

@Component({})
export default class OpportunityDetailsStateSelector extends Vue {
  @Prop({ required: true })
  opportunity!: ReadOpportunity

  @Getter('opportunity/categories') categories!: ReadCompanyOpportunityCategory[];
  @Getter('opportunity/opportunities') opportunities!: {[K: string]: ReadOpportunity[]};
  @Getter('opportunity/pagination') pagination!: { [K: string]: { id: string, totalAmount: number, totalItems: number } };

  @Action('opportunity/updateOpportunity') updateOpportunity!: (payload: UpdateOpportunity & { id: string }) => Promise<ReadOpportunity>;
  @Action('opportunity/setOpportunities') setOpportunities!: (payload: { id: string, data: ReadOpportunity[] }) => Promise<void>;
  @Action('opportunity/setPagination') setPagination!: (payload: { totalAmount: number, categoryId: string, totalItems: number }) => Promise<void>;

  get opportunityCategory () {
    return this.categories.find(c => c.id === this.opportunity.categoryId)
  }

  get selectableCategories () {
    return this.categories.filter(category => category.id !== this.opportunityCategory?.id)
  }

  async changeCategory (category: ReadCompanyOpportunityCategory) {
    try {
      const oldCategory = this.opportunity.categoryId
      const afterRank = this.opportunities[category.id][0]?.rank || '0|000000:'

      const updatedOpportunity = await this.updateOpportunity({
        id: this.opportunity.id,
        categoryId: category.id,
        afterRank,
      })

      await this.setOpportunities({
        id: oldCategory,
        data: this.opportunities[oldCategory].filter((o) => {
          return o.id !== this.opportunity.id
        }),
      })
      await this.setOpportunities({
        id: category.id,
        data: [
          updatedOpportunity,
          ...this.opportunities[category.id],
        ],
      })
      if (this.pagination[category.id].totalItems) {
        const pagination = this.pagination[category.id]
        await this.setPagination({
          totalAmount: pagination.totalAmount + (this.opportunity.amount || 0),
          categoryId: category.id,
          totalItems: pagination.totalItems + 1,
        })
      }
      if (this.pagination[oldCategory].totalItems) {
        const pagination = this.pagination[oldCategory]
        await this.setPagination({
          totalAmount: pagination.totalAmount - (this.opportunity.amount || 0),
          categoryId: oldCategory,
          totalItems: pagination.totalItems - 1,
        })
      }
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }
}
