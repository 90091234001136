
import { Action, Component, Getter, Vue, Watch } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  ABGroupPricing,
  ABGroupPricingConfig,
  BillingAction,
  CampaignType,
  CreationForm,
  IBilling,
  ICompany,
  ICreationCompany,
  IExperience,
  ITransaction,
  PlanFeature,
  productionDatePackaging,
  ReadAccount,
  ReadStripeAccount,
  SegmentEvent,
  StripeProductFrequency, StripeProductType,
} from '@abby/core-legacy'
import { Action as AbbyAction } from '@abby/shared'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import Navigation from '~/components-legacy/layout/Navigation.vue'
import { CreationState } from '~/store/creation'
import AppSkeletonLoader from '~/components-legacy/layout/loaders/AppSkeletonLoader.vue'
import { AppState, CommunityEvent } from '~/store/app'
import { IAuthUser } from '~/store/auth'
import LegacyCompatProductPanel from '~/components-legacy/sidePanels/LegacyCompatProductPanel.vue'
import IntercomButton from '~/components-legacy/helpCenter/IntercomButton.vue'
import ExtendedTrialModal from '~/components-legacy/modals/ExtendedTrialModal.vue'
import BillingValidityDateSignatureModal from '~/components-legacy/modals/BillingValidityDateSignatureModal.vue'
import Drawer from '~/components-legacy/layout/Drawer.vue'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import { useLayout } from '~/composables/useLayout'
import OpportunitySidePanelLayout from '~/components/opportunity/layout/OpportunitySidePanelLayout.vue'
import ClientSidePanelLayout from '~/components/client/ClientSidePanelLayout.vue'
import ClientModalLayout from '~/components/client/ClientModalLayout.vue'
import HomeGuideModalDeactivateTestBilling
  from '~/components-legacy/home/home-guide/HomeGuideModalDeactivateTestBilling.vue'
import HomeGuideModalBankSynchro from '~/components-legacy/home/home-guide/HomeGuideModalBankSynchro.vue'
import HomeGuideModalOnlinePayment from '~/components-legacy/home/home-guide/HomeGuideModalOnlinePayment.vue'
import HomeGuideModalConnectUrssaf from '~/components-legacy/home/home-guide/HomeGuideModalConnectUrssaf.vue'
import HomeGuideModalImmediateAdvance from '~/components/tiers-prestation/HomeGuideModalImmediateAdvance.vue'
import UpdateTaxRateJuly2024Modal from '~/components-legacy/modals/urssaf/UpdateTaxRateJuly2024Modal.vue'
import BillingModalLayout from '~/components/billing/BillingModalLayout.vue'
import ArcadeModal from '~/components/abby/arcades/ArcadeModal.vue'
import BlackFridayReport2024 from '~/components-legacy/modals/commercialOffers/BlackFridayReport2024.vue'
import PaymentFailedModal from '~/components-legacy/modals/commercialOffers/PaymentFailedModal.vue'
import { usePromotionManager } from '~/composables/marketing/usePromotionManager'
import { Promotion } from '~/services/marketing/promotion'
import MultipleYearsSubscriptionModal
  from '~/components-legacy/modals/commercialOffers/MultipleYearsSubscriptionModal.vue'

@Component({
  setup () {
    const { offsetTop } = useLayout()
    const { getActivePromotion } = usePromotionManager()
    return {
      offsetTop,
      getActivePromotion,
    }
  },
  middleware: ['redirection', 'auth'],
  components: {
    MultipleYearsSubscriptionModal,
    PaymentFailedModal,
    LottieAnimation,
    BlackFridayReport2024,
    UpdateTaxRateJuly2024Modal,
    HomeGuideModalImmediateAdvance,
    HomeGuideModalConnectUrssaf,
    HomeGuideModalOnlinePayment,
    HomeGuideModalBankSynchro,
    HomeGuideModalDeactivateTestBilling,
    BillingModalLayout,
    ClientModalLayout,
    OpportunitySidePanelLayout,
    ClientSidePanelLayout,
    AbbyPlanChip,
    BillingValidityDateSignatureModal,
    Drawer,
    IntercomButton,
    ExtendedTrialModal,
    Navigation,
    AppSkeletonLoader,
    LegacyCompatProductPanel,
    ArcadeModal,
    OnboardingImmediateAdvanceModal: () => import('~/components-legacy/modals/OnboardingImmediateAdvanceModal.vue'),
    EmailModificationTPModal: () => import('~/components-legacy/modals/EmailModificationTPModal.vue'),
    TaskPanelManager: () => import('~/components/productivity/tasks/panels/TaskPanelManager.vue'),
    PartnersModal: () => import('~/components-legacy/modals/PartnersModal.vue'),
    UpdateBillingAdditionalLogosModal: () => import('~/components-legacy/modals/billingConfiguration/UpdateBillingAdditionalLogosModal.vue'),
    CreationToGestionModal: () => import('~/components-legacy/modals/CreationToGestionModal.vue'),
    CommunityEventModal: () => import('~/components-legacy/modals/CommunityEventModal.vue'),
    ExpiredTrial: () => import('~/components-legacy/modals/ExpiredTrial.vue'),
    BillingFrequencyModal: () => import('~/components-legacy/modals/BillingFrequencyModal.vue'),
    BillingUpdateReminderModal: () => import('~/components-legacy/modals/billingsTables/BillingUpdateReminderModal.vue'),
    BillingPanelManager: () => import('~/components-legacy/sidePanels/BillingPanelManager.vue'),
    NewPacksModal: () => import('~/components-legacy/modals/NewPacksModal.vue'),
    BillingAsPaidModal: () => import('~/components-legacy/modals/BillingAsPaidModal.vue'),
    UpdateBillingNumberFormatModal: () => import('~/components/billing/modals/UpdateBillingNumberFormatModal.vue'),
    CustomerTaxReturnDocumentModal: () => import('~/components-legacy/modals/CustomerTaxReturnDocumentModal.vue'),
    OpportunityDetailsPanelManager: () => import('~/components-legacy/opportunity/OpportunityDetailsPanelManager.vue'),
    CustomerDetailsPanelManager: () => import('~/components-legacy/sidePanels/CustomerDetailsPanelManager.vue'),
    ProductDetailsPanelManager: () => import('~/components-legacy/sidePanels/ProductDetailsPanelManager.vue'),
    ProviderDetailsPanelManager: () => import('~/components-legacy/sidePanels/ProviderDetailsPanelManager.vue'),
    SendBillingByEmailModal: () => import('~/components-legacy/modals/SendBillingByEmailModal.vue'),
    UpdateBillingTitleModal: () => import('~/components-legacy/modals/UpdateBillingTitleModal.vue'),
    HelpCenterModal: () => import('~/components-legacy/modals/HelpCenterModal.vue'),
    OpportunityPanel: () => import('~/components-legacy/opportunity/OpportunityPanel.vue'),
    SupportPhoneModal: () => import('~/components-legacy/modals/SupportPhoneModal.vue'),
    SupportTicketModal: () => import('~/components-legacy/modals/SupportTicketModal.vue'),
    AskActivityModal: () => import('~/components-legacy/modals/AskActivityModal.vue'),
    AlertsManager: () => import('~/components-legacy/layout/managers/AlertsManager.vue'),
    CompanyFormModal: () => import('~/components/company/CompanyFormModal.vue'),
    JoinCommunity: () => import('~/components-legacy/modals/JoinCommunity.vue'),
    BillingCreationModal: () => import('~/components-legacy/modals/BillingCreationModal.vue'),
    ConfirmModal: () => import('~/components-legacy/modals/ConfirmModal.vue'),
    DeclareBalancesModal: () => import('~/components-legacy/modals/urssaf/DeclareBalancesModal.vue'),
    CreationFormModal: () => import('~/components-legacy/modals/CreationFormModal.vue'),
    AcreEligibilityModal: () => import('~/components-legacy/modals/AcreEligibilityModal.vue'),
    MeetingWithAnExpert: () => import('~/components-legacy/modals/commercialOffers/MeetingWithAnExpert.vue'),
    SuggestedTransactionsToAnnotateModal: () => import('~/components-legacy/modals/SuggestedTransactionsToAnnotateModal.vue'),
    UpdateUserBirthDateModal: () => import('~/components-legacy/modals/UpdateUserBirthDateModal.vue'),
    CreateMandatSepaModal: () => import('~/components-legacy/modals/urssaf/CreateMandatSepaModal.vue'),
    CreateMandatUrssafModal: () => import('~/components-legacy/modals/urssaf/CreateMandatUrssafModal.vue'),
    FreeCotisationsEstimationModal: () => import('~/components-legacy/modals/urssaf/FreeCotisationsEstimationModal.vue'),
    CreationDocumentDepositModal: () => import('~/components-legacy/modals/creation/CreationDocumentDepositModal.vue'),
    UpsellsModal: () => import('~/components-legacy/modals/commercialOffers/UpsellsModal.vue'),
    InitialDeclarationCfeModal: () => import('~/components-legacy/modals/InitialDeclarationCfeModal.vue'),
    CampaignsManager: () => import('~/components-legacy/layout/managers/CampaignsManager.vue'),
    UpdateBillingInitialNumberModal: () => import('~/components/billing/modals/UpdateBillingInitialNumberModal.vue'),
    DeclarationPanel: () => import('~/components-legacy/declarations/DeclarationPanel.vue'),
    CompletenessTaskInfoModal: () => import('~/components-legacy/modals/CompletenessTaskInfoModal.vue'),
    FirstTimeBillingConfiguration: () => import('~/components-legacy/modals/FirstTimeBillingConfiguration.vue'),
    UpdateBillingLogoModal: () => import('~/components-legacy/modals/billingConfiguration/UpdateBillingLogoModal.vue'),
    UpdateBillingStyleModal: () => import('~/components-legacy/modals/billingConfiguration/UpdateBillingStyleModal.vue'),
    UpdateBillingShapeModal: () => import('~/components-legacy/modals/billingConfiguration/UpdateBillingShapeModal.vue'),
    ExportModal: () => import('~/components/exports/ExportModal.vue'),
    CustomerPanel: () => import('~/components-legacy/sidePanels/CustomerPanel.vue'),
    ProviderPanel: () => import('~/components-legacy/sidePanels/ProviderPanel.vue'),
    FrillSidePanel: () => import('~/components-legacy/sidePanels/FrillSidePanel.vue'),
    AbbyPlansModal: () => import('~/components-legacy/modals/commercialOffers/AbbyPlansModal.vue'),
    AbbySaleFeatureModal: () => import('~/components/abby/utils/AbbySaleFeatureModal.vue'),
    TaskModal: () => import('~/components/productivity/tasks/modals/TaskModal.vue'),
    IntegrationAppArticleModal: () => import('~/components/integration/IntegrationAppArticleModal.vue'),
    IntegrationSuggestedUseCasesPanel: () => import('~/components/integration/IntegrationSuggestedUseCasesPanel.vue'),
    CreateOrUpdateTimeRecordPanel: () => import('~/components-legacy/timeTracking/CreateOrUpdateTimeRecordPanel.vue'),
    AppUpdateModal: () => import('~/components-legacy/modals/AppUpdateModal.vue'),
    AbbyToaster: () => import('~/components/abby/messaging/AbbyToaster.vue'),
    BillingCancelModal: () => import('~/components-legacy/modals/BillingCancelModal.vue'),
    UpdateBillingMentionMediatorModal: () => import('~/components-legacy/modals/billingConfiguration/UpdateBillingMentionMediatorModal.vue'),
    WelcomeVideo: () => import('~/components-legacy/modals/WelcomeVideo.vue'),
    TrialJanuary2024: () => import('~/components-legacy/modals/TrialJanuary2024.vue'),
  },
})

export default class Default extends Vue {
  @Getter('auth/user')
  user!: IAuthUser

  @Getter('app/isContainerFullHeight')
  isContainerFullHeight!: boolean

  @Getter('app/logoutLoading')
  logoutLoading!: boolean

  @Getter('app/initTimestamp')
  initTimestamp!: number

  @Getter('app/communityEventInBanner')
  communityEventInBanner!: CommunityEvent

  @Getter('app/menuIsMini')
  menuIsMini!: boolean

  @Getter('helpCenter/helpCenterIsOpen')
  helpCenterIsOpen!: boolean

  @Getter('billingConfiguration/stripeAccount')
  stripeAccount!: ReadStripeAccount | null

  @Getter('app/loading') loading!: AppState['loading']

  @Action('billingConfiguration/fetchStripeAccount')
  fetchStripeAccount!: () => Promise<void>

  @Action('billingConfiguration/fetchBillingPaymentAccountLink')
  fetchBillingPaymentAccountLink!: () => Promise<void>

  @Getter('accounts/synchronizedAccounts')
  synchronizedAccounts!: ReadAccount[]

  getActivePromotion!: Function

  chatIsOpen = false

  showBanner = true

  offsetTop!: number

  promotionTimer = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    daysStr1: '0',
    daysStr2: '0',
    hoursStr1: '0',
    hoursStr2: '0',
    minutesStr1: '0',
    minutesStr2: '0',
    secondsStr1: '0',
    secondsStr2: '0',
  }

  displayRenewOfferBanner = true

  get displayRenewOfferCampaign () {
    return (!this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1Lzgk0I21zkxZU1xi4tu1Yc9) || !this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1M7TPuI21zkxZU1xH06XYnPS)) && this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_CAMPAIGN_END) && this.displayRenewOfferBanner && this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_PRO && !this.$planManager.has(StripeProductType.ABBY_CE)
  }

  openRenewOfferModal () {
    this.$store.dispatch('campaigns/openModal', 'multipleYearsSubscription')
  }

  closeRenewOfferBanner () {
    this.displayRenewOfferBanner = false
  }

  get PlanFeature () {
    return PlanFeature
  }

  get activePromotion (): Promotion | undefined {
    return this.getActivePromotion()
  }

  get vMainMarginTop () {
    if (this.displayLargeHeadBanner) {
      return `${90}px`
    }
    return `${this.displayHeadBanner ? 44 : 0}px`
  }

  get displayVatAlert () {
    return !this.$planManager.hasAccessTo(PlanFeature.BILLING_VAT)
  }

  get displayLargeHeadBanner () {
    return this.displayHeadBanner && (this.isPromotionDay && this.activePromotion?.bannerApp && this.showBanner) && !this.$vuetify.breakpoint.smAndDown && !this.isLockedBecauseOfFailedPayment && !this.displayFailedPaymentBanner && !this.hasExpiredPaymentMethod && !this.hasSoonExpiredPaymentMethod && !this.displayVatAlert && !this.displayRenewOfferCampaign
  }

  get hasDouble2Offer () {
    return !this.$campaignsManager.isDisplayable(CampaignType.DOUBLE_2) && (this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_FREE || this.$planManager.isTrial())
  }

  dismissBlackFriday2024Campaign () {
    this.showBanner = false
  }

  get isPromotionDay () {
    const isSubBusinessYearly = this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_BUSINESS && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    return !isSubBusinessYearly && this.activePromotion && (!!this.promotionTimer.days || !!this.promotionTimer.hours || !!this.promotionTimer.minutes || !!this.promotionTimer.seconds)
  }

  get promotionDayConfig () {
    if (this.activePromotion && this.isPromotionDay && this.activePromotion?.bannerApp) {
      return {
        title: this.activePromotion.bannerApp.title,
        subtitle: this.activePromotion.bannerApp.subtitle,
        mobileTitle: this.activePromotion.bannerApp.mobileTitle,
        miniTitle: this.activePromotion.bannerApp.miniTitle,
      }
    }
    return {}
  }

  get is3daysRemainingBeforeEndOfPromotion () {
    const days = this.$dayjs(this.activePromotion?.endDate || '2025-12-09').diff(this.$dayjs(), 'days')
    return days <= 3
  }

  get hasAbbyCreationStartBusiness () {
    return this.$planManager.has(StripeProductType.ABBY_CREATION_START_BUSINESS)
  }

  beforeDestroy () {
    this.$help.toggleChat(false)
  }

  get displayHeadBanner () {
    if (this.hasAbbyCreationStartBusiness) { return false }
    if (this.$planManager.displayAbbyCreationPlans()) { return false }
    if (this.offsetTop) { return false }
    return this.hasDouble2Offer || this.displayCommunityEventInBanner || (this.isPromotionDay && this.activePromotion?.bannerApp && this.showBanner) || this.displayFailedPaymentBanner || this.hasExpiredPaymentMethod || this.hasSoonExpiredPaymentMethod || this.isLockedBecauseOfFailedPayment || this.displayVatAlert || this.displayRenewOfferCampaign
  }

  get displayFailedPaymentBanner () {
    return this.$planManager.hasPaymentFailed()
  }

  get isLockedBecauseOfFailedPayment () {
    return this.$planManager.isLockedBecauseOfFailedPayment()
  }

  get hasExpiredPaymentMethod () {
    return this.$planManager.hasExpiredPaymentMethod()
  }

  get hasSoonExpiredPaymentMethod () {
    return this.$planManager.hasSoonExpiredPaymentMethod()
  }

  get displayCommunityEventInBanner () {
    return this.$vuetify.breakpoint.smAndDown && !!this.communityEventInBanner
  }

  head () {
    return this.$brandManager.head()
  }

  @Getter('billingCreation/billingDocument')
  billingDocument!: IBilling;

  @Getter('company/isCreationToGestionModalOpened') isCreationToGestionModalOpened !: boolean

  @Getter('billingCreation/isBillingCreationModalOpened')
  isBillingCreationModalOpened!: boolean

  @Getter('accountingAccounts/isDetailedTransactionOpened')
  isDetailedTransactionOpened!: boolean

  @Getter('accountingAccounts/isSelectionModalOpened')
  isSelectionModalOpened!: boolean

  @Getter('accountingAccounts/isSuggestedModalOpened')
  isSuggestedModalOpened!: boolean

  @Getter('accountingAccounts/detailedTransaction')
  detailedTransaction!: ITransaction | null

  @Getter('billingCreation/isBillingCreationModalMinimized')
  isBillingCreationModalMinimized!: boolean

  @Getter('billingCreation/isBillingEditionModalOpened')
  isBillingEditionModalOpened!: boolean

  @Getter('billingCreation/isBillingEditionModalMinimized')
  isBillingEditionModalMinimized!: boolean

  @Getter('company/company')
  company!: ICompany | null

  @Getter('creationForm/form')
  creationForm!: CreationForm

  @Getter('company/creation')
  creation!: ICreationCompany;

  @Getter('company/isInCreation')
  isInCreation!: boolean

  @Getter('experience/customExperience')
  customExperience!: IExperience

  @Getter('creation/form')
  form!: CreationState['form'];

  @Getter('company/isVerified')
  isVerified!: boolean;

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  @Action('creation/fetchOnboarding')
  fetchOnboarding!: () => Promise<void>

  @Action('creationForm/fetch')
  fetchCreationForm!: ({ companyId }: { companyId: string }) => Promise<void>

  debounceTrack = this.$lodash.debounce(() => {
    this.$ap.sendToGTM({
      event: SegmentEvent.PAGE_VIEW,
    })
    this.$ap.track(AbbyAction.PAGE_VIEWED)
  }, 500)

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () {
    this.debounceTrack()
  }

  openBillingCreationModal () {
    if (this.isBillingCreationModalMinimized) {
      this.$store.commit('billingCreation/SET_IS_BILLING_CREATION_MODAL_OPENED', true)
      this.$store.commit('billingCreation/SET_IS_BILLING_CREATION_MODAL_MINIMIZED', false)
    } else if (this.isBillingEditionModalMinimized) {
      this.$store.commit('billingCreation/SET_IS_BILLING_EDITION_MODAL_OPENED', true)
      this.$store.commit('billingCreation/SET_IS_BILLING_EDITION_MODAL_MINIMIZED', false)
    }
  }

  openBFPlanModal () {
    this.$store.dispatch('campaigns/openModal', 'blackFridayReport2024')
  }

  async closeBillingCreationModal () {
    const confirm = await this.$confirmManager.ask({
      icon: 'save',
      title: this.$t(`confirmBilling.${BillingAction[BillingAction.DO_NOT_SAVE]}.title`) as string,
      content: this.$t(`confirmBilling.${BillingAction[BillingAction.DO_NOT_SAVE]}.content`) as string,
      confirmText: this.$t(`confirmBilling.${BillingAction[BillingAction.DO_NOT_SAVE]}.button`) as string,
    })
    if (!confirm) {
      return
    }
    this.$store.commit('billingCreation/SET_IS_BILLING_CREATION_MODAL_OPENED', false)
    this.$store.commit('billingCreation/SET_IS_BILLING_CREATION_MODAL_MINIMIZED', false)
    this.$store.commit('billingCreation/SET_BILLING_DOCUMENT', null)
    this.$store.commit('billingCreation/SET_CREATE_FROM_TIME_RECORDS', null)
  }

  get appLoading () {
    return this.$store.getters['app/appLoading']
  }

  get displayMinimizerBilling () {
    return !!this.billingDocument && ((this.isBillingCreationModalMinimized && !this.isBillingCreationModalOpened) || (this.isBillingEditionModalMinimized && !this.isBillingEditionModalOpened))
  }

  get displayNewPacksModal () {
    return !this.isInCreation && this.$dayjs().diff(productionDatePackaging, 'd') <= 0 && this.$campaignsManager.isDisplayable(CampaignType.NEW_PACKAGING_ANNOUNCEMENT_MODAL)
  }

  @Watch('displayDetailedTransaction')
  handleDisplayDetailedTransaction (value: boolean) {
    if (value) {
      this.$help.toggleHelpCenter(false)
    } else {
      this.$help.toggleHelpCenter(false)
    }
  }

  @Watch('isVerified')
  handleIsVerified (value: boolean) {
    if (value) {
      this.openVerifiedModal()
    }
  }

  openVerifiedModal () {
    this.$store.dispatch('company/openCreationToGestionModalOpened', true)
  }

  get hasAbbyStart () {
    return this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_START
  }

  get hasAbbyPro () {
    return this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_PRO
  }
  //

  async fetchReport () {
    try {
      const { data }: any = await this.$axios.get('/v2/statistics/personal-report')
      delete data.companyId
      delete data.createdAt
      delete data.id
      delete data.updatedAt
      return data
    } catch (e) {
      return null
    }
  }

  async mounted () {
    if (!this.$cookies.get('ay_anonymous_id')) {
      if (!process.browser) { return }
      // @ts-ignore
      this.$cookies.set('ay_anonymous_id', window.crypto.randomUUID(), {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.abby.fr',
      })
    }
    this.$mixpanel.init()
    // Permet de gérer la campagne d'activation auto des périodes d'essai à la connexion pour les utilisateurs sans abonnements https://linear.app/abby-france/issue/P-1509/reinitialiser-les-periodes-dessai-et-activer-automatiquement-une-pe
    if (this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_FREE && this.$planManager.canExtendTrial() && !this.$planManager.has([StripeProductType.ABBY_CREATION_START, StripeProductType.ABBY_CREATION_START_PREMIUM, StripeProductType.ABBY_CREATION_START_BUSINESS])) {
      try {
        await this.$store.dispatch('payment/extendOrStartTrialBusiness')
        this.$help.waitUntilIntercomIsSet(() => {
          this.$help.sendChatEvent('FreeTrialExtensionStartedAutomatically')
        })
        this.$ap.track(AbbyAction.FREE_TRIAL_EXTENSION_STARTED_AUTOMATICALLY)
      } catch (_) {}
    }

    // Permet de gérer la campagne d'activation auto des périodes d'essai à la connexion pour les utilisateurs avec abonnements https://linear.app/abby-france/issue/P-1507/pouvoir-tester-un-plan-superieur-lorsuquon-est-deja-abonne
    if ((this.hasAbbyStart || this.hasAbbyPro) && !this.$planManager.isTrial()) {
      try {
        await this.$store.dispatch('payment/addNewTrialForExistingSubscription', StripeProductType.ABBY_BUSINESS)
        this.$help.waitUntilIntercomIsSet(() => {
          this.$help.sendChatEvent('NewTrialForExistingSubscriptionAddedAutomatically')
        })
        this.$ap.track(AbbyAction.NEW_TRIAL_FOR_EXISTING_SUBSCRIPTION_ADDED_AUTOMATICALLY)
      } catch (_) {}
    }
    this.$help.waitUntilIntercomIsSet(() => {
      this.$help.onChatShow(() => {
        this.chatIsOpen = true
      })
      this.$help.onChatHide(() => {
        this.chatIsOpen = false
      })
    })

    if (this.$campaignsManager.isDisplayable(CampaignType.AB_ATTRIBUTION_ONLINE_SIGNATURE_PAYMENT_ACTIVATION)) {
      this.$help.updateChatData({}, { 'Online payment and signature activation group': Math.random() < 0.5 ? 'A' : 'B' })
      this.$campaignsManager.doNotDisplayAnymore(CampaignType.AB_ATTRIBUTION_ONLINE_SIGNATURE_PAYMENT_ACTIVATION)
    }
    if (this.$campaignsManager.isDisplayable(CampaignType.AB_ATTRIBUTION_CHECKOUT) && this.$planManager.whichPlanCompanyHasSubscribed() !== AbbyPlans.ABBY_BUSINESS) {
      const group = Math.random() < 0.5 ? 'A' : 'B'
      this.$campaignsManager.doNotDisplayAnymore(
        group === 'A' ? CampaignType.AB_GROUP_A_CHECKOUT : CampaignType.AB_GROUP_B_CHECKOUT,
      )
      this.$ap.startExperimentation({ experimentName: 'Checkout', variantName: group })
      this.$campaignsManager.doNotDisplayAnymore(CampaignType.AB_ATTRIBUTION_CHECKOUT)
    }
    setInterval(() => {
      const endDate = this.$dayjs('2025-03-31').endOf('d').toDate().getTime()
      const now = this.$dayjs().toDate().getTime()
      const diff = endDate - now

      let seconds = Math.floor(diff / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      seconds %= 60
      minutes %= 60
      hours %= 24

      this.promotionTimer = {
        days,
        hours,
        minutes,
        seconds,
        daysStr1: days.toString().padStart(2, '0').charAt(0),
        daysStr2: days.toString().padStart(2, '0').charAt(1),
        hoursStr1: hours.toString().padStart(2, '0').charAt(0),
        hoursStr2: hours.toString().padStart(2, '0').charAt(1),
        minutesStr1: minutes.toString().padStart(2, '0').charAt(0),
        minutesStr2: minutes.toString().padStart(2, '0').charAt(1),
        secondsStr1: seconds.toString().padStart(2, '0').charAt(0),
        secondsStr2: seconds.toString().padStart(2, '0').charAt(1),
      }
    }, 1000)
    this.$store.dispatch('timeTracking/resumeTimer')
    if (this.$route.query.promoCode) {
      this.$store.dispatch('upsells/setPromoCode', this.$route.query.promoCode)
    }
    await this.loadUserExperience()
    await this.fetchStripeAccount()
    if (this.stripeAccount) {
      await this.fetchBillingPaymentAccountLink()
    }
    this.$help.waitUntilAppIsLoaded(() => {
      this.loadAnalytics()
      this.$store.dispatch('company/fetchPaymentMethods')
      this.$store.dispatch('webinar/fetchWebinar')
      this.$updateManager.initRemoteCheckForUpdate()
    })

    if (this.$dayjs(this.user.createdAt).add(2, 'd').isBefore(this.$dayjs()) && this.$campaignsManager.isDisplayable(CampaignType.WELCOME_VIDEO_AFTER_2_DAYS)) {
      this.$store.dispatch('campaigns/openModal', 'welcomeVideo')
    }

    if (this.$dayjs().tz('Europe/Paris').diff(this.$dayjs(this.user.createdAt).tz('Europe/Paris'), 'hour') >= 3 && this.$campaignsManager.isDisplayable(CampaignType.RELOGGED_CAMPAIGN)) {
      this.$ap.sendToGTM({
        event: SegmentEvent.RELOGGED,
      })
      this.$ap.track(AbbyAction.RELOGGED)
      this.$api.advertising.trackRelogged()
      await this.$campaignsManager.doNotDisplayAnymore(CampaignType.RELOGGED_CAMPAIGN)
    }
    if (this.$dayjs(this.user.createdAt).isBefore('2024-07-10', 'YYYY-MM-DD') && this.$campaignsManager.isDisplayable(CampaignType.UPDATE_TAX_RATE_JULY_2024) && !this.connectedFromDashboard) {
      this.$store.dispatch('urssaf/openModal', 'updateTaxRateJuly2024')
    }
    if (!this.isVerified) { return }
    this.openVerifiedModal()
  }

  loadGTM () {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    const script = document.createElement('script')
    script.async = true
    script.id = 'gtm'
    script.src = '/scripts/mtg-app.js'
    document.head.appendChild(script)
  }

  loadAnalytics () {
    setTimeout(() => {
      this.loadGTM()
      setTimeout(() => {
        this.$intercom.init()
        this.$help.init()
        this.$hotjar.init()
        // Must check if user is null
        // This can happen if user disconnect quickly after login
        if (!this.user) {
          return
        }
        if (this.$dayjs().isBetween(this.$dayjs(this.user.createdAt).subtract(1, 'd'), this.$dayjs(this.user.createdAt).add(16, 'd'))) {
          this.$hotjar.sendHotjarEvent('Fresh user session')
        }
      }, 1000)
    }, 2000)
  }

  async loadUserExperience () {
    let activityId: number | null = null
    await this.$store.dispatch('activities/fetchActivities')
    if (!this.creationForm || !this.creationForm.activity?.activityId) {
      await this.fetchOnboarding()
      activityId = this.form.activity?.activityId
    } else if (this.creationForm.activity?.activityId) {
      activityId = this.creationForm.activity?.activityId
    }
    await this.$xpm.loadExperience(activityId || null)
  }

  closeCommunityEventInBanner () {
    this.$store.dispatch('app/closeCommunityEventInBanner')
  }

  openCommunityEventLink (event: CommunityEvent) {
    window.open(event.url, '_blank')
  }
}

