import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  BillingType,
  ProductUnit,
  calculateDuration,
  calculateQuantity,
  groupTimeRecordsByDate,
  formatCents,
  getLineAmount,
} from '@abby/core-legacy'

import dayjs from 'dayjs'
import { TimeUnit } from 'chart.js'
import type {
  BasePaginate,
  CreateTimeRecord,
  IBilling,
  ReadTimeRecord,
  UpdateTimeRecord,
  ReadOpportunity,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export type CurrentTimer = {
  startDate: null | Date,
  name: string,
  unitRate?: number | null,
  unit?: TimeUnit | null,
  opportunity?: ReadOpportunity | null
}

export interface TimeTrackingState {
  timeRecordsPaginated: BasePaginate<ReadTimeRecord> | null
  loadMoreLoading: boolean
  timeInSeconds: number
  intervalId: number | null
  timeRecordToEdit: ReadTimeRecord | null,
  currentTimer: CurrentTimer,
  pagination: {
    limit: number,
    page: number,
  },
  panels: {
    editOrUpdate: boolean
  }
}

export type TimeTrackingPanels = Extract<keyof TimeTrackingState['panels'], string>

export const state = (): TimeTrackingState => ({
  timeRecordsPaginated: null,
  timeInSeconds: 0,
  intervalId: null,
  loadMoreLoading: false,
  timeRecordToEdit: null,
  pagination: {
    limit: 5,
    page: 1,
  },
  currentTimer: {
    name: '',
    startDate: null,
  },
  panels: {
    editOrUpdate: false,
  },
})

export const getters: GetterTree<TimeTrackingState, RootState> = {
  timeRecordsPaginated: state => state.timeRecordsPaginated,
  timeRecordsByDate: state => groupTimeRecordsByDate(state.timeRecordsPaginated?.data || []),
  currentTimer: state => state.currentTimer,
  timeInSeconds: state => state.timeInSeconds,
  panels: state => state.panels,
  timeRecordToEdit: state => state.timeRecordToEdit,
  timerIsRunning: state => !!state.currentTimer.startDate,
  loadMoreLoading: state => state.loadMoreLoading,
}

export const mutations: MutationTree<TimeTrackingState> = {
  SET_TIME_RECORDS_PAGINATED (state: TimeTrackingState, value: BasePaginate<ReadTimeRecord>) {
    state.timeRecordsPaginated = value
  },
  SET_LOAD_MORE_LOADING (state: TimeTrackingState, value: boolean) {
    state.loadMoreLoading = value
  },
  SET_CURRENT_TIMER (state: TimeTrackingState, value: CurrentTimer) {
    state.currentTimer = {
      ...state.currentTimer,
      ...value,
    }
    localStorage.setItem('currentTimeTracking', JSON.stringify(state.currentTimer))
  },
  SET_PAGINATION (state: TimeTrackingState, pagination: TimeTrackingState['pagination']) {
    state.pagination = {
      ...state.pagination,
      ...pagination,
    }
  },
  SET_TIME_RECORD_TO_EDIT (state: TimeTrackingState, value: ReadTimeRecord) {
    state.timeRecordToEdit = value
  },
  UPDATE_TIMER (state: TimeTrackingState) {
    state.timeInSeconds = dayjs().diff(state.currentTimer.startDate as any, 'seconds')
  },
  PUSH_TIME_RECORD (state: TimeTrackingState, timeRecord: ReadTimeRecord) {
    state.timeRecordsPaginated?.data?.unshift(timeRecord)
  },
  UPDATE_TIME_RECORD (state: TimeTrackingState, timeRecord: ReadTimeRecord) {
    const index = state.timeRecordsPaginated?.data?.findIndex(t => t.id === timeRecord.id)
    if (index !== undefined) {
      state.timeRecordsPaginated?.data.splice(index, 1, timeRecord)
    }
  },
  DELETE_TIME_RECORD (state: TimeTrackingState, id: string) {
    const index = state.timeRecordsPaginated?.data?.findIndex(t => t.id === id)
    if (index !== undefined) {
      state.timeRecordsPaginated?.data.splice(index, 1)
    }
  },
  OPEN_PANEL (state: TimeTrackingState, { key, timeRecord } : { key: TimeTrackingPanels, timeRecord?: ReadTimeRecord }) {
    state.panels[key] = true
    state.timeRecordToEdit = timeRecord || null
  },
  CLOSE_PANEL (state: TimeTrackingState, key: TimeTrackingPanels) {
    state.panels[key] = false
    state.timeRecordToEdit = null
  },
  SET_INTERVAL_ID (state: TimeTrackingState, value: number | null) {
    state.intervalId = value
  },
  RESET_TIMER (state: TimeTrackingState) {
    state.currentTimer = {
      startDate: null, name: '',
    }
    localStorage.removeItem('currentTimeTracking')
    state.intervalId = null
    state.timeInSeconds = 0
  },
  RESET (_currentState: TimeTrackingState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<TimeTrackingState, RootState> = {
  async getTimeRecordsPaginated ({ commit, state, dispatch }) {
    const records = await this.$api.timeTracking.getPaginated(state.pagination)
    commit('SET_TIME_RECORDS_PAGINATED', records)
  },
  async loadMoreTimeRecords ({ commit, dispatch, state }) {
    try {
      commit('SET_LOAD_MORE_LOADING', true)
      commit('SET_PAGINATION', { limit: state.pagination.limit + 5 })
      await dispatch('getTimeRecordsPaginated')
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    } finally {
      commit('SET_LOAD_MORE_LOADING', false)
    }
  },
  async createTimeRecord ({ commit, dispatch }, payload: CreateTimeRecord) {
    const timeRecord = await this.$api.timeTracking.create(payload)
    commit('PUSH_TIME_RECORD', timeRecord)
  },
  async updateTimeRecord ({ commit, dispatch }, { payload, id }: { payload: UpdateTimeRecord, id: string }) {
    const timeRecord = await this.$api.timeTracking.update(payload, id)
    commit('UPDATE_TIME_RECORD', timeRecord)
    this.$busManager.emit('timeRecordUpdated', timeRecord)
  },
  async deleteTimeRecord ({ commit, dispatch }, id: string) {
    await this.$api.timeTracking.delete(id)
    commit('DELETE_TIME_RECORD', id)
  },
  startTimer ({ commit, state }, params?: CurrentTimer) {
    if (state.intervalId || state.currentTimer.startDate) {
      return
    }
    const intervalId = setInterval(() => {
      commit('UPDATE_TIMER')
    }, 300)

    commit('SET_INTERVAL_ID', intervalId)
    commit('SET_CURRENT_TIMER', params || { startDate: new Date() })
  },
  async stopTimer ({ commit, state, dispatch }) {
    if (state.intervalId) {
      clearInterval(state.intervalId)
    }
    const timeRecord = await this.$api.timeTracking.create({
      startDate: state.currentTimer.startDate || new Date(),
      name: state.currentTimer.name,
      endDate: new Date(),
      opportunityId: state.currentTimer.opportunity?.id,
    })
    commit('PUSH_TIME_RECORD', timeRecord)
    dispatch('openCreateOrEditPanel', timeRecord)
    commit('RESET_TIMER')
  },
  resumeTimer ({ dispatch }) {
    const currentTimeTracking = JSON.parse(localStorage.getItem('currentTimeTracking') || '{}')
    if (currentTimeTracking?.startDate) {
      dispatch('startTimer', currentTimeTracking)
    }
  },
  openCreateOrEditPanel ({ commit }, timeRecord?: ReadTimeRecord) {
    commit('OPEN_PANEL', { key: 'editOrUpdate', timeRecord })
  },
  async createInvoiceFromTimeRecords ({ dispatch }, timeRecords: ReadTimeRecord[]) {
    // toutes les opportunités
    const opportunityIds = timeRecords.map(t => t.opportunity?.id).filter(t => t)

    // si plusieurs opportunités différentes alors prendre le client
    const uniqueValues = new Set(opportunityIds)
    const hasDifferentOpportunities = uniqueValues.size > 1

    const firstOpportunityFound = timeRecords.find(t => t.opportunity)?.opportunity

    const billingRelatesTo = {
      ...hasDifferentOpportunities
        ? {
          customer: firstOpportunityFound?.customer,
          // Permet de résoudre le problème https://app.intercom.com/a/inbox/hu6d8oic/inbox/shared/mentions/conversation/123506?view=List
          // customerId: firstOpportunityFound?.customerId || null,
        }
        : {
          opportunity: firstOpportunityFound || null,
          opportunityId: firstOpportunityFound?.id || null,
        },
    }

    const billingDocument: Partial<IBilling> = {
      ...billingRelatesTo,
      billingType: BillingType.INVOICE,
      product: timeRecords.map((timeRecord) => {
        const duration = calculateDuration(timeRecord.startDate, timeRecord.endDate)
        const quantity = calculateQuantity(duration, timeRecord.unit || ProductUnit.HEURE)
        const lineAmount = getLineAmount({ quantity, unitPrice: timeRecord.unitRate, designation: '' })

        return {
          designation: timeRecord.name || '',
          quantity,
          productUnit: timeRecord.unit || ProductUnit.HEURE,
          unitPrice: timeRecord.unitRate,
          priceWithoutTaxBeforeDiscount: formatCents(lineAmount.priceWithoutTaxBeforeDiscount).text,
          priceWithoutTax: formatCents(lineAmount.priceWithoutTax).text,
          priceTotalTax: formatCents(lineAmount.priceTotalTax).text,
          discount: lineAmount.discount ? formatCents(-lineAmount.discount).text : undefined,
        }
      }),
    }
    await dispatch('billingCreation/openCreationBilling', { billingDocument, createFromTimeRecords: timeRecords }, { root: true })
  },
  closeCreateOrEditPanel ({ commit }) {
    commit('CLOSE_PANEL', 'editOrUpdate')
  },
  setTimeRecordsPaginated ({ commit }, timeRecordsPaginated: BasePaginate<ReadTimeRecord>) {
    commit('SET_TIME_RECORDS_PAGINATED', timeRecordsPaginated)
  },
}
