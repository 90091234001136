import { RequestStatus } from '@abby/core-legacy'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import debounce from 'lodash/debounce'
import type { ILog, QueryApiLogs } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface LogState {
  paginationQuery: QueryApiLogs;
  queryLoading: boolean;
  logs: ILog[] | null;
  selectedLog: ILog | null;
  totalPages: number;
}

export const state = (): LogState => ({
  paginationQuery: {
    page: 1,
    limit: 25,
    search: null,
    status: RequestStatus.ALL,
  },
  totalPages: 0,
  queryLoading: true,
  logs: null,
  selectedLog: null,
})

export const getters: GetterTree<LogState, RootState> = {
  logs: (state: LogState) => state.logs,
  queryLoading: (state: LogState) => state.queryLoading,
  paginationQuery: (state: LogState) => state.paginationQuery,
  selectedLog: (state: LogState) => state.selectedLog,
  totalPages: (state: LogState) => state.totalPages,
}

export const mutations: MutationTree<LogState> = {
  SET_LOGS (state: LogState, logs: ILog[]) {
    state.logs = logs
  },
  RESET (_currentState: LogState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_QUERY_LOADING (state: LogState, value: boolean) {
    state.queryLoading = value
  },
  SET_SELECTED_LOG (state: LogState, value: ILog) {
    state.selectedLog = value
  },
  SET_PAGINATION_QUERY (state: LogState, value: QueryApiLogs) {
    state.paginationQuery = value
  },
  SET_TOTAL_PAGES (state: LogState, value: number) {
    state.totalPages = value
  },
}

export const actions: ActionTree<LogState, RootState> = {
  setSelectedLog ({ commit }, log: ILog) {
    commit('SET_SELECTED_LOG', log)
  },
  async setPaginationQuery ({ commit, dispatch }, value: QueryApiLogs) {
    commit('SET_PAGINATION_QUERY', value)
    try {
      await dispatch('debounceFetchLogs')
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  debounceFetchLogs: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchLogs')
  }, 300, { leading: true }),
  async loadMoreLogs ({ commit, state }) {
    try {
      if (state.totalPages === state.paginationQuery.page) {
        return
      }
      commit('SET_QUERY_LOADING', true)
      commit('SET_PAGINATION_QUERY', {
        ...state.paginationQuery,
        page: state.paginationQuery.page + 1,
      })
      const results = await this.$api.log.getAll(state.paginationQuery)
      commit('SET_TOTAL_PAGES', results?.totalPages)
      commit('SET_LOGS', [
        ...(state.logs || []),
        ...(results?.docs || []),
      ])
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  async fetchLogs ({ commit, state }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const results = await this.$api.log.getAll({
        ...state.paginationQuery,
      })
      if (results?.docs?.length) {
        commit('SET_SELECTED_LOG', results?.docs?.[0])
      } else {
        commit('SET_SELECTED_LOG', null)
      }
      commit('SET_LOGS', results.docs)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
}
