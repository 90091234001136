
import { Action, Component, Getter, ModelSync, Prop, Vue, Watch } from 'nuxt-property-decorator'
import {
  BasePaginate,
  IBilling, PlansConfig,
  RankTaskMode,
  ReadCompanyOpportunityCategory, ReadOpportunity, ReadTask, ReadTimeRecord, UpdateOpportunity,
} from '@abby/core-legacy'
import BillingLines from '~/components-legacy/BillingLines.vue'
import TaskAddCard from '~/components/productivity/tasks/cards/TaskAddCard.vue'
import TaskColumnList from '~/components/productivity/tasks/TaskColumnList.vue'
import { CurrentTimer } from '~/store/timeTracking'
import TimeRecordsGroupItem from '~/components-legacy/timeTracking/TimeRecordsGroupItem.vue'
import AbbyInputEditor from '~/components/abby/inputs/AbbyInputEditor.vue'

@Component({
  components: {
    AbbyInputEditor,
    TimeRecordsGroupItem,
    BillingLines,
    TaskAddCard,
    TaskColumnList,
  },
})
export default class OpportunityDetailsPanel extends Vue {
  @Action('opportunity/updateOpportunity') updateOpportunity!: (payload: UpdateOpportunity & { id: string }) => Promise<void>;
  @Action('opportunity/fetchCategories') fetchCategories!: () => Promise<void>;
  @Action('opportunity/setOpportunity') setOpportunity!: (opportunity: ReadOpportunity | null) => Promise<void>;
  @Action('timeTracking/setTimeRecordsPaginated') setTimeRecordsPaginated!: (timeRecordsPaginated: BasePaginate<ReadTimeRecord>) => Promise<void>;
  @Action('billingCreation/openCreationBilling')
  openCreationBilling!: (payload: { createFormBilling?: IBilling, billingDocument?: Partial<IBilling> }) => Promise<void>

  @Action('timeTracking/startTimer') startTimer!: (value: CurrentTimer) => void;

  @Getter('opportunity/categories') categories!: ReadCompanyOpportunityCategory[];
  @Getter('timeTracking/timeRecordsPaginated') timeRecordsPaginated!: BasePaginate<ReadTimeRecord>;

  @ModelSync('value', 'input')
  readonly isOpen!: boolean;

  @Prop({ required: true })
  opportunity!: ReadOpportunity

  updateKey: string = ''

  openTaskForm = false

  itemTasks: ReadTask[] = [];

  get itemTimeRecords () {
    return this.timeRecordsPaginated?.data || []
  }

  get limitNumber () {
    const plan = this.$planManager.whichPlanCompanyHas()
    return PlansConfig[plan].timeTracking.max
  }

  get tasks () {
    return this.itemTasks
  }

  set tasks (value: ReadTask[]) {
    this.itemTasks = value
  }

  get RankTaskMode () {
    return RankTaskMode
  }

  get hasCategories () {
    return !!this.categories?.length
  }

  serverOpportunity: ReadOpportunity | null = null

  get opportunityToDisplay () {
    return this.serverOpportunity || this.opportunity
  }

  isDescriptionEditable = false
  editableDescription: string = ''

  get billingsFilter () {
    return {
      customerId: this.opportunityToDisplay.customerId,
      opportunityId: this.opportunityToDisplay.id,
    }
  }

  editDescription () {
    if (window && window.getSelection()?.toString().length) {
      return
    }
    this.isDescriptionEditable = true
  }

  get hasDescription () {
    return this.opportunityToDisplay?.description?.length
  }

  cancelDescriptionEditing () {
    this.editableDescription = '' + (this.opportunityToDisplay?.description || '')
    this.isDescriptionEditable = false
  }

  onTransitionend (e: any) {
    this.$emit('transitionend', e)
  }

  onInput (e: any) {
    this.$emit('input', e)
  }

  makeid () {
    let result = ''
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() *
          charactersLength))
    }
    return result
  }

  async fetchTasks () {
    try {
      const results = await this.$api.task.getTasks({ limit: 50, opportunityId: this.opportunity.id, rankMode: RankTaskMode.OPPORTUNITY })
      this.itemTasks = results.data
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }

  async fetchTimeRecords () {
    try {
      const results = await this.$api.timeTracking.getPaginated({ limit: 50, opportunityId: this.opportunity.id, page: 1 })
      await this.setTimeRecordsPaginated(results)
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }

  opportunityUpdatedId = ''
  billingUpdatedId = ''
  billingCreatedId = ''
  billingRemovedId = ''
  taskUpdatedId = ''

  async mounted () {
    this.taskUpdatedId = this.$busManager.on('taskUpdated', (value: ReadTask) => {
      const taskIndex = this.itemTasks.findIndex(t => t.id === value.id)
      if (taskIndex === -1 && value.opportunityId === this.opportunity.id) {
        this.itemTasks = [...this.itemTasks, { ...value, rank: value.opportunityRank }].sort((a, b) => a.rank?.localeCompare?.(b.rank))
        return
      }
      if (taskIndex === -1) { return }
      this.updateTask(value)
    })

    this.opportunityUpdatedId = this.$busManager.on('opportunityUpdated', (o: ReadOpportunity) => {
      if (o.id !== this.opportunityToDisplay.id) { return }
      this.serverOpportunity = o
      this.editableDescription = '' + (this.opportunityToDisplay?.description || '')
      this.updateKey = this.makeid()
    })
    this.billingUpdatedId = this.$busManager.on('billingUpdated', (b: IBilling) => {
      if (b.opportunityId !== this.opportunityToDisplay.id) { return }
      this.updateKey = this.makeid()
    })
    this.billingCreatedId = this.$busManager.on('billingCreated', (b: IBilling) => {
      if (b.opportunityId !== this.opportunityToDisplay.id) { return }
      this.updateKey = this.makeid()
    })
    this.billingRemovedId = this.$busManager.on('billingRemoved', (b: IBilling) => {
      if (b.opportunityId !== this.opportunityToDisplay.id) { return }
      this.updateKey = this.makeid()
    })

    this.editableDescription = '' + (this.opportunityToDisplay?.description || '')

    if (!this.hasCategories) {
      try {
        await this.fetchCategories()
      } catch (e: any) {
        this.$alertsManager.autoError(e as any)
      }
    }

    await this.fetchTasks()
    await this.fetchTimeRecords()
  }

  beforeDestroy () {
    this.$busManager.off(this.opportunityUpdatedId)
    this.$busManager.off(this.taskUpdatedId)
    this.$busManager.off(this.billingUpdatedId)
    this.$busManager.off(this.billingCreatedId)
    this.$busManager.off(this.billingRemovedId)
  }

  async createBillingDocument () {
    await this.openCreationBilling({ billingDocument: { opportunityId: this.opportunityToDisplay?.id, customer: this.opportunityToDisplay.customer } })
  }

  updateTask (task: ReadTask) {
    this.itemTasks = this.itemTasks.map(t => t.id === task.id ? { ...t, ...task } : t)
  }

  addTask (task: ReadTask) {
    this.itemTasks.push(task)
  }

  duplicateTask (task: ReadTask) {
    this.itemTasks = [
      ...this.itemTasks,
      task,
    ].sort((a, b) => a.rank?.localeCompare?.(b.rank))
  }

  async updateDescription () {
    if (!this.opportunityToDisplay) { return }
    try {
      await this.updateOpportunity({
        id: this.opportunityToDisplay.id,
        description: this.editableDescription || '',
      })
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.isDescriptionEditable = false
    }
  }

  openTimeTrackingPanel () {
    this.setOpportunity(this.opportunity)
    this.$store.dispatch('timeTracking/openCreateOrEditPanel')
  }
}
