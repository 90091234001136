import { Context } from '@nuxt/types'
import { sdk } from '@abby/sdk'
import debounce from 'lodash/debounce'
import { checkIfUser, refreshToken, retrieveToken } from '~/plugins/axios'
import { resetCache } from '~/plugins/etag'

// Screen dimension state management
let cachedScreenHeight: number = window.innerHeight
let cachedScreenWidth: number = window.innerWidth

// Debounced dimension update function
const updateScreenDimensions = debounce(() => {
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth
}, 250)

export default (context: Context, inject: any) => {
  let tokenPromise: Promise<string> | null = null
  let token: string | null = null

  const logout = async () => {
    resetCache()
    await context.store.dispatch('auth/logout', null, { root: true })
  }

  const resetToken = () => {
    token = null
    tokenPromise = null
  }

  const setToken = (value: string) => {
    token = value
  }

  // Set initial dimensions
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth

  // Add event listener for resize with debounce
  window.addEventListener('resize', updateScreenDimensions)

  // Request interceptor
  sdk.client.interceptors.request.use(async (request: Request) => {
    // Add screen dimensions and support headers
    request.headers.set('screen-height', cachedScreenHeight.toString())
    request.headers.set('screen-width', cachedScreenWidth.toString())
    request.headers.set('support', 'web-app')

    // Handle authentication
    const expirationTime = context.store.getters['auth/expirationTime'] || 0
    const hasUser = checkIfUser(context.$fire)
    const hasFirebaseUser = !!context.$fire.auth.currentUser

    if (hasUser && expirationTime < Date.now()) {
      try {
        if (!tokenPromise) {
          tokenPromise = refreshToken(context.$fire, hasFirebaseUser, context.store, () => { tokenPromise = null })
        }
        const refreshedToken = await tokenPromise
        token = refreshedToken
        request.headers.set('Authorization', `Bearer ${refreshedToken}`)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Token refresh failed:', e)
        await logout()
      }
    } else {
      if (!token) {
        token = retrieveToken().token
      }
      request.headers.set('Authorization', `Bearer ${token}`)
    }

    return request
  })

  sdk.client.interceptors.response.use((response: Response) => {
    if (response.status === 401) {
      logout()
    }
    return response
  })

  sdk.client.setConfig({
    baseUrl: '/api',
    throwOnError: true,
  })

  inject('sdk', {
    ...sdk,
    resetToken,
    setToken,
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
  interface Context {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
}

declare module 'vuex' {
  interface Store<S> {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
}
