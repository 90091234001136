import {
  PlanFeature,
  ReadCompanyStripeProduct,
  SegmentEvent,
  StripeProductFrequency,
  StripeProductType,
  UpdateTrial,
} from '@abby/core-legacy'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export type PaymentCallbacks = {
  succeed?: () => Promise<void> | any,
  failed?: () => Promise<void>,
  canceled?: () => Promise<void>
}

export enum AbbyPlansStep {
  PLANS = 'plans',
  CE = 'ce',
  PAYMENT = 'payment',
  THANKS = 'thanks',

}

export type PaymentParams = {
  frequency?: StripeProductFrequency,
  product?: StripeProductType,
  step?: AbbyPlansStep,
  closeDelay?: number
  // TODO: refacto après le refonte vers vue 3
  options?: {
    totalBankAccountsSynchronized?: number
  }
}

export interface PaymentState {
  subscriptions: ReadCompanyStripeProduct[]
  trials: ReadCompanyStripeProduct[]
  currentTrial: ReadCompanyStripeProduct | null
  callbacks: PaymentCallbacks
  params: PaymentParams
  feature: PlanFeature | null
  isAbbyPlansModalOpened: boolean
  isAbbySaleFeatureModalOpened: boolean
  isTrialEditModalOpened: boolean
  isExpiredTrialOpened: boolean
  isCEUpsellModalOpened: boolean
  isFailedPaymentModalOpened: boolean
  paymentModal: {
    product?: StripeProductType | null
    frequency?: StripeProductFrequency | null
    option?: StripeProductType | null
    opened: false,
    message?: string,
    landOnFinalStep?: boolean,
    step?: AbbyPlansStep,
  }
}

export const state = (): PaymentState => ({
  subscriptions: [],
  trials: [],
  currentTrial: null,
  callbacks: {},
  params: {},
  feature: null,
  isAbbyPlansModalOpened: false,
  isAbbySaleFeatureModalOpened: false,
  isTrialEditModalOpened: false,
  isExpiredTrialOpened: false,
  isCEUpsellModalOpened: false,
  isFailedPaymentModalOpened: false,
  paymentModal: {
    product: null,
    option: null,
    opened: false,
  },
})

export const getters: GetterTree<PaymentState, PaymentState> = {
  subscriptions: state => state.subscriptions,
  trials: state => state.trials,
  params: state => state.params,
  feature: state => state.feature,
  currentTrial: state => state.currentTrial,
  callbacks: state => state.callbacks,
  isAbbyPlansModalOpened: state => state.isAbbyPlansModalOpened,
  isAbbySaleFeatureModalOpened: state => state.isAbbySaleFeatureModalOpened,
  isTrialEditModalOpened: state => state.isTrialEditModalOpened,
  isFailedPaymentModalOpened: state => state.isFailedPaymentModalOpened,
  isExpiredTrialOpened: state => state.isExpiredTrialOpened,
  isCEUpsellModalOpened: state => state.isCEUpsellModalOpened,
  paymentModal: state => state.paymentModal,
}

export const mutations: MutationTree<PaymentState> = {
  SET_SUBSCRIPTIONS: (state, value: ReadCompanyStripeProduct[]) => {
    state.subscriptions = value
  },
  SET_TRIALS: (state, value: ReadCompanyStripeProduct[]) => {
    state.trials = value
  },
  SET_CURRENT_TRIAL: (state, value: ReadCompanyStripeProduct) => {
    state.currentTrial = value
  },
  SET_CALLBACKS (state, value: PaymentCallbacks | null | undefined) {
    state.callbacks = value || {}
  },
  SET_PARAMS (state, value: PaymentParams | null | undefined) {
    state.params = value || {}
  },
  SET_FEATURE (state, value: PlanFeature | null) {
    state.feature = value
  },
  SET_ABBY_PLANS_MODAL (state, value: boolean) {
    state.isAbbyPlansModalOpened = value
  },
  SET_ABBY_SALE_FEATURE_MODAL (state, value: boolean) {
    state.isAbbySaleFeatureModalOpened = value
  },
  SET_TRIAL_EDIT_MODAL (state, value: boolean) {
    state.isTrialEditModalOpened = value
  },
  SET_EXPIRED_TIRAL_OPENED (state, value: boolean) {
    state.isExpiredTrialOpened = value
  },
  SET_IS_CE_UPSELL_MODAL_OPENED (state, value: boolean) {
    state.isCEUpsellModalOpened = value
  },
  SET_PAYMENT_MODAL (state, value: PaymentState['paymentModal']) {
    state.paymentModal = value
  },
  SET_IS_PAYMENT_MODAL_OPENED (state, value: PaymentState['isFailedPaymentModalOpened']) {
    state.isFailedPaymentModalOpened = value
  },
  RESET (_currentState: PaymentState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<PaymentState, PaymentState> = {
  setAbbyPlansModalOpened ({ dispatch }, value: boolean) {
    if (value) {
      dispatch('openAbbyPlansModal', {})
      return
    }
    dispatch('closeAbbyPlansModal')
  },
  setCurrentTrial ({ commit }, value: ReadCompanyStripeProduct) {
    commit('SET_CURRENT_TRIAL', value)
  },
  openAbbyPlansModal ({ commit }, { callbacks, params, feature }: { callbacks?: PaymentCallbacks, params?: PaymentParams, feature?: string }) {
    commit('SET_ABBY_PLANS_MODAL', true)
    commit('SET_FEATURE', feature)
    commit('SET_CALLBACKS', callbacks)
    commit('SET_PARAMS', params)
  },
  closeAbbyPlansModal ({ commit }) {
    commit('SET_ABBY_PLANS_MODAL', false)
    commit('SET_CALLBACKS', {})
    commit('SET_PARAMS', {})
  },
  openAbbySaleFeatureModal ({ commit }, { feature, callbacks, params }: { feature: PlanFeature, callbacks?: PaymentCallbacks, params?: PaymentParams }) {
    commit('SET_ABBY_SALE_FEATURE_MODAL', true)
    commit('SET_FEATURE', feature)
    commit('SET_CALLBACKS', callbacks)
    commit('SET_PARAMS', params)
  },
  closeAbbySaleFeatureModal ({ commit }, clearParams?: boolean) {
    commit('SET_ABBY_SALE_FEATURE_MODAL', false)
    if (clearParams) {
      commit('SET_FEATURE', null)
      commit('SET_CALLBACKS', {})
      commit('SET_PARAMS', {})
    }
  },
  openTrialEditModal ({ commit }) {
    commit('SET_TRIAL_EDIT_MODAL', true)
  },
  closeTrialEditModal ({ commit }) {
    commit('SET_TRIAL_EDIT_MODAL', false)
  },
  openExpiredTrialModal ({ commit }) {
    commit('SET_EXPIRED_TIRAL_OPENED', true)
  },
  closeExpiredTrialModal ({ commit }) {
    commit('SET_EXPIRED_TIRAL_OPENED', false)
  },
  openCEUpsellModal ({ commit }) {
    commit('SET_IS_CE_UPSELL_MODAL_OPENED', true)
  },
  closeCEUpsellModal ({ commit }) {
    commit('SET_IS_CE_UPSELL_MODAL_OPENED', false)
  },
  openFailedPaymentModalOpened ({ commit }) {
    commit('SET_IS_PAYMENT_MODAL_OPENED', true)
  },
  closeFailedPaymentModalOpened ({ commit }) {
    commit('SET_IS_PAYMENT_MODAL_OPENED', false)
  },
  openPaymentModal ({ commit }, params: PaymentState['paymentModal']) {
    commit('SET_PAYMENT_MODAL', {
      ...params,
      opened: true,
    })
    this.$hotjar.sendHotjarEvent(SegmentEvent.PAYMENT_MODAL_OPENED as string)
  },
  closePaymentModal ({ commit }) {
    commit('SET_PAYMENT_MODAL', {
      opened: false,
      frequency: null,
      product: null,
      options: null,
    })
  },
  async fetchSubscriptions ({ commit }) {
    const results = await this.$api.payment.getSubscriptions()
    commit('SET_SUBSCRIPTIONS', results)
  },
  async fetchTrials ({ commit }, companyId: string) {
    const results = await this.$api.payment.getTrials(companyId)
    commit('SET_TRIALS', results)
  },
  cancelSubscription ({ commit, getters }, id: string) {
    commit('SET_SUBSCRIPTIONS', getters.subscriptions.map((s: ReadCompanyStripeProduct) => ({
      ...s,
      ...(s.productId === id
        ? {
          canceledAt: new Date().toISOString(),
        }
        : {}),
    })))
  },
  async startSubscriptionTrial ({ dispatch }, params: { id: StripeProductType, trialMode?: string}) {
    const result = await this.$api.payment.startTrial(params.id, { trialMode: params.trialMode })
    this.$ap.sendToGTM({
      event: SegmentEvent.START_TRIAL,
      data: {
        productId: params.id,
        amount: result.amount,
        product: {
          id: params.id,
          value: result.amount,
        },
        orderId: result.id,
      },
    })
    await dispatch('company/fetchPlans', undefined, { root: true })
    // await dispatch('company/setPlans', [
    //   ...rootGetters['company/plans'],
    //   result,
    // ], { root: true })
    // commit('SET_SUBSCRIPTIONS', [
    //   ...getters.subscriptions,
    //   result,
    // ])
  },
  async extendSubscriptionTrial ({ commit, dispatch, rootGetters, getters }, id: string) {
    const result = await this.$api.payment.extendTrial(id)
    await dispatch('company/setPlans', [
      ...rootGetters['company/plans'].filter((p: ReadCompanyStripeProduct) => p.productId !== id),
      result,
    ], { root: true })
    commit('SET_SUBSCRIPTIONS', [
      ...getters.subscriptions.filter((s:ReadCompanyStripeProduct) => s.id !== id),
      result,
    ])
  },
  async extendOrStartTrialPro ({ commit, dispatch, rootGetters, getters }) {
    await this.$api.payment.extendOrStartTrialPro()
    await dispatch('company/fetchPlans', {}, { root: true })
  },
  async extendOrStartTrialBusiness ({ commit, dispatch, rootGetters, getters }) {
    await this.$api.payment.extendOrStartTrialBusiness()
    await dispatch('company/fetchPlans', {}, { root: true })
  },
  async addNewTrialForExistingSubscription ({ commit, dispatch, rootGetters, getters }, plan: StripeProductType) {
    await this.$api.payment.addNewTrialForExistingSubscription(plan)
    await dispatch('company/fetchPlans', {}, { root: true })
  },
  async reactivateSubscription ({ commit, getters }, id: string) {
    await this.$api.payment.reactivateSubscription(id)
    commit('SET_SUBSCRIPTIONS', getters.subscriptions.map((s: ReadCompanyStripeProduct) => ({
      ...s,
      ...(s.id === id
        ? {
          canceledAt: null,
        }
        : {}),
    })))
  },
  async extendSubscriptionTrialDashboard ({ commit }, payload: UpdateTrial) {
    const result = await this.$api.payment.extendTrialDashboard(payload)
    commit('SET_TRIALS', result)
  },
}
