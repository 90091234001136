
import { Component, Vue, Prop, Watch, Ref } from 'nuxt-property-decorator'
// eslint-disable-next-line import/named
import Player, { Options as VimeoPlayerOptions } from '@vimeo/player'
import { VimeoVideoProgress } from '@abby/core-legacy'

@Component
export default class VimeoPlayer extends Vue {
  @Prop({ required: true })
  videoOptions!: VimeoPlayerOptions

  @Prop()
  progression?: VimeoVideoProgress

  @Prop({ required: false, default: false })
  isMobile!: boolean

  @Prop({ required: false, default: false })
  contain!: boolean

  @Prop({ required: false, default: false })
  portrait!: boolean

  @Ref('playerContainer')
  playerContainer!: HTMLElement;

  @Prop({ required: false, default: false })
  gifUrl!: string

  player!: Player

  displayGIF: boolean = true;

  init () {
    try {
      this.player = new Player(this.playerContainer, {
        ...this.videoOptions,
        // id: Math.floor(Math.random() * 1000) + 1,
      })
      if (this.progression) {
        this.player.setCurrentTime(this.progression.seconds)
      }
      this.player.on('play', () => {
        this.videoPlayed()
      })

      this.player.on('timeupdate', this.timeUpdated)

      setTimeout(() => {
        if (!this.videoOptions.autoplay) {
          return
        }
        this.player.play()
      }, 50)
    } catch (e: any) {}
  }

  mounted () {
    this.init()
  }

  playVideoWithFullscreen () {
    this.player.play()
    // @ts-ignore
    this.player.requestFullscreen()
  }

  playVideo () {
    this.player.play()
    this.displayGIF = false
  }

  beforeDestroy () {
    this.player.destroy()
  }

  @Watch('videoOptions', { deep: true })
  handler () {
    this.player.destroy()
    this.init()
  }

  videoPlayed () {
    this.$emit('played')
  }

  timeUpdated (payload: any) {
    this.$emit('timeupdate', payload)
  }
}

