import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  LegalStatus,
  OnboardingStepsCreation,
  SignupType,
} from '@abby/core-legacy'
import { $enum } from 'ts-enum-util'
import type {
  ICreationPackItem,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface CreationState {
  progression: number;
  step: OnboardingStepsCreation | null;
  isOnboardingModalOpened: boolean,
  isAbbyPlusToAbbyContactModalOpened: boolean,
  form: {
    personal: {
      isUnderAge: boolean | null,
      isEmancipate: boolean | null,
      isFrench: boolean | null;
      isEuropean: boolean | null;
      isAlgerian: boolean | null;
      hasResidencePermit: boolean | null;
    },
    activity: {
      wantsActivityStartAt: string | null;
      commercialName: string | null;
      name: string | null;
      activity: number | null,
      activityId: number | null,
      otherActivity: string | null,
      secondaryActivity: string | null,
      creationActivityDescription: string | null;
      legalStatus: LegalStatus | null;
      isEIRL: boolean | null;
      parentActivityId: number | null;
      address: {
        label: string;
        housenumber: string;
        id: string;
        name: string;
        postcode: string;
        citycode: string;
        x: number;
        y: number;
        city: string;
        context: string;
        type: string;
        street: string;
      } | null
    }
  },
  creationPackItems: ICreationPackItem[],
}

export const state = (): CreationState => ({
  step: OnboardingStepsCreation.ACTIVITY,
  progression: 10,
  isOnboardingModalOpened: false,
  isAbbyPlusToAbbyContactModalOpened: false,
  form: {
    personal: {
      isUnderAge: null,
      isEmancipate: null,
      isFrench: null,
      isEuropean: null,
      isAlgerian: null,
      hasResidencePermit: null,
    },
    activity: {
      parentActivityId: null,
      wantsActivityStartAt: null,
      commercialName: null,
      name: null,
      legalStatus: LegalStatus.MICRO_ENTREPRISE,
      activity: null,
      activityId: null,
      isEIRL: false,
      otherActivity: null,
      secondaryActivity: null,
      creationActivityDescription: null,
      address: null,
    },
  },
  creationPackItems: [],
})

export const getters: GetterTree<CreationState, RootState> = {
  step: state => state.step,
  isOnboardingModalOpened: state => state.isOnboardingModalOpened,
  isAbbyPlusToAbbyContactModalOpened: state => state.isAbbyPlusToAbbyContactModalOpened,
  progression: state => state.progression,
  form: state => state.form,
  creationPackItems: state => state.creationPackItems,
}

export const mutations: MutationTree<CreationState> = {
  CHANGE_STEP (state, value) {
    state.step = value
    const values = $enum(OnboardingStepsCreation).getValues()
    const position = values.indexOf(value)
    state.progression = Math.round(position / values.length * 100) || 10
  },
  CHANGE_NEW_STEP (state, value) {
    state.step = value
    const values = $enum(OnboardingStepsCreation).getValues()
    const position = values.indexOf(value)
    state.progression = Math.round(position / values.length * 100) || 10
  },
  SET_IS_ONBOARDING_MODAL_OPENED (state, value: boolean) {
    state.isOnboardingModalOpened = value
  },
  SET_IS_ABBY_PLUS_TO_ABBY_CONTACT_MODAL_OPENED (state, value: boolean) {
    state.isAbbyPlusToAbbyContactModalOpened = value
  },
  SET_FORM (state, value: any) {
    state.form = {
      ...state.form,
      ...value,
    }
  },
  SET_CREATION_PACK_ITEMS (state, creationPackItems: ICreationPackItem[]) {
    state.creationPackItems = creationPackItems
  },
  SET_CREATION_PACK_ITEM (state, creationPackItem: ICreationPackItem) {
    const index = state.creationPackItems.findIndex(cpi => cpi.id === creationPackItem.id)
    state.creationPackItems.splice(index, 1, creationPackItem)
  },
  RESET (_currentState: CreationState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<CreationState, CreationState> = {
  async changeStep ({ commit }, value: string) {
    commit('CHANGE_STEP', value)
    await this.$api.onboarding.update({
      step: value,
    })
    const redirect = `/start/creation/${value}`
    if (this.$router.currentRoute.path === redirect) { return }
    await this.$router.push(redirect)
  },
  async changeLocalStep ({ commit }, value: OnboardingStepsCreation) {
    if (!value) {
      await this.$router.push('/start/gestion/company')
      return
    }
    commit('CHANGE_NEW_STEP', value)
    localStorage.onboardingCreationStep = value
    const redirect = `/start/creation/${value}`
    if (this.$router.currentRoute.path === redirect) { return }
    await this.$router.push(window.location.search ? `${redirect}?${window.location.search}` : redirect)
  },
  saveOnboarding ({ getters }) {
    localStorage.onboardingCreationStep = getters.step
    localStorage.onboardingCreationForm = JSON.stringify(getters.form)
  },
  loadOnboarding ({ commit, dispatch }) {
    if (!localStorage.onboardingCreationForm) {
      if (this.$router.currentRoute.path === '/start/creation/activity') { return }
      this.$router.push('/start/creation/activity')
      return
    }

    const form = JSON.parse(localStorage.onboardingCreationForm)
    commit('SET_FORM', form)
    const step = localStorage.onboardingCreationStep
    dispatch('changeLocalStep', step)
  },
  cleanLocalOnboarding () {
    delete localStorage.onboardingCreationStep
    delete localStorage.onboardingCreationForm
  },
  async fetchOnboarding ({ commit }) {
    const result = await this.$api.onboarding.get()
    commit('CHANGE_STEP', result.step)
    commit('SET_FORM', result.creation)
  },
  async updateOnboarding ({ getters }) {
    await this.$api.onboarding.update({
      creation: getters.form,
      type: SignupType.CREATION,
    })
  },
  async submitOnboarding () {
    await this.$api.onboarding.submit()
  },
  setIsOnboardingModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_ONBOARDING_MODAL_OPENED', value)
  },
  setIsAbbyPlusToAbbyContactModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_ABBY_PLUS_TO_ABBY_CONTACT_MODAL_OPENED', value)
  },
  setForm ({ commit }, data: any) {
    commit('SET_FORM', data)
  },
  async fetchCreationPackItems ({ commit }, { parentActivityId, limit }: { parentActivityId: number, limit?: number }) {
    try {
      const results = await this.$api.creationPackItem.getAll(parentActivityId, limit)
      commit('SET_CREATION_PACK_ITEMS', results)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  setCreationPackItem ({ commit }, creationPackItem: ICreationPackItem) {
    commit('SET_CREATION_PACK_ITEM', creationPackItem)
  },
}
