import Vue from 'vue'
import Router from 'vue-router'

// Refacto menu et UX : restructuration des pages et migrations pour conserver le fonctionnement des liens en durs (youtube, blog, partenaires...)
const migratedPaths = [
  {
    from: '/billing/settings/immediate-advance',
    to: '/settings/billing/immediate-advance',
  },
  {
    from: '/settings/banks/connect',
    to: '/settings/accounting/bank-connect',
  },
  {
    from: '/settings/banks',
    to: '/settings/accounting/banks',
  },
  {
    from: '/settings/accounting/banks',
    to: '/settings/accounting/v2/banks',
  },
  {
    from: '/settings/payment',
    to: '/settings/profile/subscription',
  },
  // There is also a redirect in the page, keep it for now
  {
    from: '/administration/company',
    to: '/company/information',
  },
  {
    from: '/accounting',
    to: '/accounting/v2/operations',
  },
  {
    from: '/accounting/operations',
    to: '/accounting/v2/operations',
  },
  {
    from: '/tasks',
    to: '/productivity/tasks/all',
  },
  {
    from: '/tasks/today',
    to: '/productivity/tasks/today',
  },
  {
    from: '/tasks/upcoming',
    to: '/productivity/tasks/upcoming',
  },
  {
    from: '/tasks/late',
    to: '/productivity/tasks/late',
  },
  {
    from: '/tasks/completed',
    to: '/productivity/tasks/done',
  },
  {
    from: '/opportunity',
    to: '/productivity/board',
  },
  {
    from: '/timeTracking',
    to: '/productivity/time-tracking',
  },
  {
    from: '/billing',
    to: '/billing/documents/invoices',
  },
  {
    from: '/billing/assets',
    to: '/billing/documents/assets',
  },
  {
    from: '/billing/estimates',
    to: '/billing/documents/estimates',
  },
  {
    from: '/billing/purchaseOrders',
    to: '/billing/documents/purchase-orders',
  },
  {
    from: '/billing/customers',
    to: '/customers',
  },
  {
    from: '/billing/providers',
    to: '/customers',
  },
  {
    from: '/billing/products',
    to: '/billing/products',
  },
  {
    from: '/nova',
    to: '/billing/EMA-declarations',
  },
  {
    from: '/settings/accounting/accountingAccounts',
    to: '/settings/accounting/plan',
  },
  {
    from: '/settings/tiersprestation',
    to: '/settings/billing/immediate-advance',
  },
  {
    from: '/document/mine/incomestatement',
    to: '/accounting/books/income-statement',
  },
  {
    from: '/document/mine/incomebook',
    to: '/accounting/books/incomes',
  },
  {
    from: '/document/mine/purchaseregister',
    to: '/accounting/books/expenses',
  },
  {
    from: '/document/mine/result',
    to: '/accounting/books/results',
  },
  {
    from: '/settings/urssaf',
    to: '/settings/accounting/urssaf',
  },
  {
    from: '/settings/vat',
    to: '/settings/accounting/vat',
  },

  {
    from: '/settings/accounting/declarations',
    to: '/settings/accounting/social-contribution',
  },
  {
    from: '/declarations',
    to: '/accounting/declarations/urssaf',
  },
  {
    from: '/declarations/current',
    to: '/accounting/declarations/urssaf/current',
  },
  {
    from: '/declarations/current-urssaf',
    to: '/accounting/declarations/urssaf/current-urssaf',
  },
  {
    from: '/declarations/next',
    to: '/accounting/declarations/urssaf/next',
  },
  {
    from: '/vat',
    to: '/accounting/declarations/vat',
  },
  {
    from: '/ce-by-abby',
    to: '/more/committee',
  },
  {
    from: '/advantages',
    to: '/more/advantages',
  },
  {
    from: '/share',
    to: '/more/sponsorship',
  },
  {
    from: '/integrations',
    to: '/more/integrations',
  },
  {
    from: '/exports',
    to: '/more/exports',
  },
  {
    from: '/academy',
    to: '/more/academy',
  },
  {
    from: '/start_2*',
    to: '/start',
  },
  {
    from: '/start_3*',
    to: '/start',
  },
  {
    from: '/start/gestion/*',
    to: '/start',
  },
]

Vue.use(Router)

function migratedRoutes () {
  return migratedPaths.map(p => ({
    path: p.from,
    redirect: { path: p.to },
  }))
}

export function createRouter (ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  return new Router({
    ...options,
    routes: [...options.routes, ...migratedRoutes()],
  })
}
