
import Vue from 'vue'
import { Component, Getter } from 'nuxt-property-decorator'
import IntercomButton from '~/components-legacy/helpCenter/IntercomButton.vue'

@Component({
  middleware: ['redirection'],
  components: { IntercomButton },
  layout: 'error-layout',
})
export default class Error extends Vue {
  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

chatIsOpen = false

head () {
  return this.$brandManager.head()
}

mounted () {
  this.$help.waitUntilIntercomIsSet(() => {
    this.$help.onChatShow(() => {
      this.chatIsOpen = true
    })
    this.$help.onChatHide(() => {
      this.chatIsOpen = false
    })
  })
  this.$help.waitUntilAppIsLoaded(() => {
    this.loadAnalytics()
  })
}

loadGTM () {
  if (process.env.NODE_ENV !== 'production') { return }
  const script = document.createElement('script')
  script.async = true
  script.id = 'gtm'
  script.src = '/scripts/mtg-app.js'
  document.head.appendChild(script)
}

loadAnalytics () {
  setTimeout(() => {
    this.loadGTM()
    setTimeout(() => {
      this.$intercom.init()
    }, 1000)
  }, 2000)
}

reloadPage () {
  // @ts-ignore
  window.location = '/'
}
}

