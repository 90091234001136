import { StripeProductFrequency } from './StripeProductFrequency';
import { StripeProductType } from './StripeProductType';

const isProduction = process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'staging';
export enum ABGroupPricing {
  DEFAULT = 'default',
  GROUP_A = 'group_A',
  GROUP_B = 'group_B',
  GROUP_C = 'group_C',
  RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9 = 'renew_offer_2_year_price_1Lzgk0I21zkxZU1xi4tu1Yc9',
  RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9 = 'renew_offer_3_year_price_1Lzgk0I21zkxZU1xi4tu1Yc9',
  RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS = 'renew_offer_1_year_price_1M7TPuI21zkxZU1xH06XYnPS',
  RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS = 'renew_offer_2_year_price_1M7TPuI21zkxZU1xH06XYnPS',
}

export const ABGroupPricingConfig = {
  [ABGroupPricing.GROUP_A]: {
    min: 0,
    max: 34,
  },
  [ABGroupPricing.GROUP_B]: {
    min: 35,
    max: 66,
  },
  [ABGroupPricing.GROUP_C]: {
    min: 67,
    max: 100,
  },
};
export enum TrialMode {
  FOURTEEN_DAYS = 'fourteen_days',
  BF_2024 = 'bf_2024',
}

export enum PlanFeature {
  // Création
  CREATION_CREATE_MICRO = 'creation_create_micro',
  CREATION_CALL_EXPERT = 'creation_call_expert',
  CREATION_PRIORITY = 'creation_priority',

  // Administratif
  ADMINISTRATION_CFE_ACRE = 'administration_cfe_acre',

  // Facturation
  BILLING_INVOICES__PURCHASE_ORDER__ASSETS = 'billing_invoices__purchase_order__assets',
  BILLING_ARTICLES_LIBRARY = 'billing_articles_library',
  BILLING_CUSTOMERS_DIRECTORY = 'billing_customers_directory',
  BILLING_ONLINE_PAYMENT = 'billing_online_payment',
  BILLING_OLD_STRIPE_FEES = 'billing_old_stripe_fees',
  BILLING_TEMPLATE_CUSTOMISATION = 'billing_template_customisation',
  BILLING_ADVANCE_CREATION = 'billing_advance_creation',
  BILLING_WATERMARK_REMOVAL = 'billing_watermark_removal',
  BILLING_API_TIERS_PRESTATION = 'billing_api_tiers_prestation',
  BILLING_ARCHIVE_DOCUMENTS = 'billing_archive_documents',
  BILLING_ESTIMATE_ONLINE_SIGNATURE = 'billing_estimate_online_signature',
  BILLING_ESTIMATE_UNLIMITED_ONLINE_SIGNATURE = 'billing_estimate_unlimited_online_signature',
  BILLING_EMAIL_CUSTOMISATION = 'billing_email_customisation',
  BILLING_CUSTOMER_NOTE = 'billing_customer_note',
  BILLING_REMINDER = 'billing_reminder',
  BILLING_FREQUENCY = 'billing_frequency',
  BILLING_TITLE = 'billing_title',
  BILLING_TAX_RETURN_DOCUMENT = 'billing_tax_return_document',
  BILLING_LOGOS_FOOTER = 'billing_logos_footer',
  BILLING_NOVA_EMA = 'billing_nova_ema',
  BILLING_ATTACHMENT = 'billing_attachment',
  BILLING_LOCALE = 'billing_locale',
  BILLING_MULTI_CURRENCY = 'billing_multi_currency',
  BILLING_VAT = 'billing_vat',
  BILLING_SEND_BY_EMAIL = 'billing_send_by_email',
  BILLING_SET_EMAIL_SIGNATURE = 'billing_set_email_signature',
  BILLING_NOTES = 'billing_notes',
  BILLING_FILE_ATTACHMENT = 'billing_file_attachment',

  // Comptabilité
  ACCOUNTING_INCOMES_BOOK = 'accounting_incomes_book',
  ACCOUNTING_RESULT = 'accounting_result',
  ACCOUNTING_EXPENSES_BOOK = 'accounting_expenses_book',
  ACCOUNTING_URSSAF_ESTIMATION = 'accounting_urssaf_estimation',
  ACCOUNTING_URSSAF_DECLARATION = 'accounting_urssaf_declaration',
  ACCOUNTING_BANKING_SYNCHRONIZATION = 'accounting_banking_synchronization',
  ACCOUNTING_URSSAF_CFE_IMPOTS_REMINDERS = 'accounting_urssaf_cfe_impots_reminders',
  ACCOUNTING_CSV_EXCEL_EXPORT = 'accounting_csv_excel_export',
  ACCOUNTING_INCOME_STATEMENT = 'accounting_income_statement',
  ACCOUNTING_ACCOUNTING_ACCOUNT_CUSTOMISATION = 'accounting_accounting_account_customisation',
  ACCOUNTING_TVA_DECLARATION = 'accounting_tva_declaration',
  ACCOUNTING_2042_C_PRO_DECLARATION = 'accounting_2042_c_pro_declaration',

  // Document integrity manager
  ELECTRONIC_SEAL = 'electronic_seal',

  // Productivity
  PRODUCTIVITY_TASKS = 'productivity_tasks',
  PRODUCTIVITY_INTEGRATIONS = 'productivity_integrations',
  PRODUCTIVITY_OPPORTUNITY_BOARD = 'productivity_opportunity_board',
  PRODUCTIVITY_TIME_TRACKING = 'productivity_time_tracking',
  PRODUCTIVITY_ABBY_ACADEMY = 'productivity_abby_academy',

  // Statistiques
  STATISTICS_4_MONTHS_HISTORY = 'statistics_4_months_history',

  // Exports
  EXPORT_ALL_OPTIONS = 'export_all_options',

  // Support
  SUPPORT_TICKET = 'support_ticket',
  SUPPORT_CHAT = 'support_chat',
  SUPPORT_CHAT_PRIORITY = 'support_chat_priority',
  SUPPORT_PHONE = 'support_phone',

  // Users
  USERS_ADD_COLLABORATOR = 'users_add_collaborator',
  USERS_ADD_ACCOUNTANT = 'users_add_accountant',

  // Transaction
  TRANSACTIONS_MANAGE_CATEGORIZATION_RULES = 'transactions_manage_categorization_rules',

  // Stock
  STOCK_MANAGEMENT = 'stock_management',

  // Tiptap
  TIPTAP_IMAGE_UPLOAD = 'tiptap_image_upload',
  ABBY_EDITOR_AI = 'abby_editor_ai',
}

export const NoWithTrialFeatures = [
  PlanFeature.CREATION_CREATE_MICRO,
  PlanFeature.CREATION_CALL_EXPERT,
  PlanFeature.CREATION_PRIORITY,
  PlanFeature.ADMINISTRATION_CFE_ACRE,
];

export const CreationFeatures = [
  PlanFeature.CREATION_CREATE_MICRO,
  PlanFeature.CREATION_CALL_EXPERT,
  PlanFeature.CREATION_PRIORITY,
];

export enum AbbyAddons {
  ABBY_CE = 'abby_ce',
}

export enum AbbyPlans {
  ABBY_FREE = 'abby_free',
  ABBY_START = 'abby_start',
  ABBY_CREATION_START = 'abby_creation_start',
  ABBY_CREATION_START_PREMIUM = 'abby_creation_start_premium',
  ABBY_CREATION_START_BUSINESS = 'abby_creation_start_business',
  ABBY_PRO = 'abby_pro',
  ABBY_BUSINESS = 'abby_business',
}

export type FeaturePlan = {
  plan: StripeProductType,
  feature: PlanFeature
};

export type FeaturesByPlanType = {
  [k in AbbyPlans]?: Array<PlanFeature>
};
export const FeaturesByPlan: FeaturesByPlanType = {
  [AbbyPlans.ABBY_FREE]: [
    PlanFeature.BILLING_INVOICES__PURCHASE_ORDER__ASSETS,
    PlanFeature.BILLING_ARTICLES_LIBRARY,
    PlanFeature.BILLING_CUSTOMERS_DIRECTORY,
    PlanFeature.ACCOUNTING_INCOMES_BOOK,
    PlanFeature.ACCOUNTING_EXPENSES_BOOK,
    PlanFeature.PRODUCTIVITY_TASKS,
    PlanFeature.SUPPORT_TICKET,
    PlanFeature.PRODUCTIVITY_INTEGRATIONS,
  ],
  [AbbyPlans.ABBY_START]: [
    PlanFeature.CREATION_CREATE_MICRO,
    PlanFeature.CREATION_PRIORITY,
    PlanFeature.ADMINISTRATION_CFE_ACRE,
    PlanFeature.BILLING_TEMPLATE_CUSTOMISATION,
    PlanFeature.BILLING_ONLINE_PAYMENT,
    PlanFeature.BILLING_ADVANCE_CREATION,
    PlanFeature.BILLING_WATERMARK_REMOVAL,
    PlanFeature.BILLING_ARCHIVE_DOCUMENTS,
    PlanFeature.BILLING_CUSTOMER_NOTE,
    PlanFeature.BILLING_TITLE,
    PlanFeature.ACCOUNTING_URSSAF_CFE_IMPOTS_REMINDERS,
    PlanFeature.ACCOUNTING_URSSAF_DECLARATION,
    PlanFeature.SUPPORT_CHAT,
    PlanFeature.BILLING_SEND_BY_EMAIL,
  ],
  [AbbyPlans.ABBY_PRO]: [
    PlanFeature.CREATION_CALL_EXPERT,
    PlanFeature.BILLING_ESTIMATE_ONLINE_SIGNATURE,
    PlanFeature.BILLING_EMAIL_CUSTOMISATION,
    PlanFeature.BILLING_LOGOS_FOOTER,
    PlanFeature.BILLING_ATTACHMENT,
    PlanFeature.BILLING_API_TIERS_PRESTATION,
    PlanFeature.BILLING_REMINDER,
    PlanFeature.BILLING_FREQUENCY,
    PlanFeature.BILLING_VAT,
    PlanFeature.ACCOUNTING_CSV_EXCEL_EXPORT,
    PlanFeature.ACCOUNTING_INCOME_STATEMENT,
    PlanFeature.ACCOUNTING_RESULT,
    PlanFeature.ACCOUNTING_ACCOUNTING_ACCOUNT_CUSTOMISATION,
    PlanFeature.ACCOUNTING_TVA_DECLARATION,
    PlanFeature.ACCOUNTING_BANKING_SYNCHRONIZATION,
    PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD,
    PlanFeature.PRODUCTIVITY_TIME_TRACKING,
    PlanFeature.PRODUCTIVITY_ABBY_ACADEMY,
    PlanFeature.STATISTICS_4_MONTHS_HISTORY,
    PlanFeature.SUPPORT_CHAT_PRIORITY,
    PlanFeature.SUPPORT_CHAT_PRIORITY,
    PlanFeature.SUPPORT_PHONE,
    PlanFeature.EXPORT_ALL_OPTIONS,
  ],
  [AbbyPlans.ABBY_BUSINESS]: [
    PlanFeature.BILLING_TAX_RETURN_DOCUMENT,
    PlanFeature.BILLING_NOVA_EMA,
    PlanFeature.BILLING_ESTIMATE_ONLINE_SIGNATURE,
    PlanFeature.BILLING_ESTIMATE_UNLIMITED_ONLINE_SIGNATURE,
    PlanFeature.ACCOUNTING_2042_C_PRO_DECLARATION,
    PlanFeature.BILLING_MULTI_CURRENCY,
    PlanFeature.BILLING_LOCALE,
    PlanFeature.USERS_ADD_COLLABORATOR,
    PlanFeature.USERS_ADD_ACCOUNTANT,
    PlanFeature.TRANSACTIONS_MANAGE_CATEGORIZATION_RULES,
    PlanFeature.BILLING_SET_EMAIL_SIGNATURE,
    PlanFeature.TIPTAP_IMAGE_UPLOAD,
    PlanFeature.ELECTRONIC_SEAL,
    PlanFeature.STOCK_MANAGEMENT,
    PlanFeature.ABBY_EDITOR_AI,
    PlanFeature.BILLING_NOTES,
    PlanFeature.BILLING_FILE_ATTACHMENT,
  ],
};

const startConfig = {
  features: [
    ...(FeaturesByPlan[AbbyPlans.ABBY_FREE] || []),
    ...(FeaturesByPlan[AbbyPlans.ABBY_START] || []),
  ],
  stripe: {
    rate: 1.9, // 3,4 - 1,5 (stripe commission)
    flat: 35, // 60 - 25 (stripe commission)
  },
  oldStripeFees: {
    rate: 0.9, // 2,4 - 1,5 (stripe commission)
    flat: 25, // 50 - 25 (stripe commission)
  },
  opportunity: {
    max: 10,
  },
  timeTracking: {
    max: 20,
  },
  bankAccount: {
    max: 0,
  },
  pricing: {
    [ABGroupPricing.DEFAULT]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
    [ABGroupPricing.GROUP_A]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
    [ABGroupPricing.GROUP_B]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
    [ABGroupPricing.GROUP_C]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
    [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
    [ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
    [ABGroupPricing.RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
    [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
      [StripeProductFrequency.MONTH]: {
        amount: 1100,
        amountByMonth: 1100,
        priceId: isProduction ? 'price_1OHkqNI21zkxZU1xfW7b6Bvc' : 'price_1OHVJ6I21zkxZU1xzmKMgxAD',
      },
      [StripeProductFrequency.YEAR]: {
        amount: 10800,
        amountByMonth: 900,
        priceId: isProduction ? 'price_1OHkqXI21zkxZU1xbf9sXTML' : 'price_1OHUtZI21zkxZU1xdg31F7Z8',
      },
    },
  },
};

export type PlanConfigType = Omit<{
  [k1 in AbbyPlans]: {
    features: Array<PlanFeature>
    stripe: {
      rate: number
      flat: number
    }
    oldStripeFees: {
      rate: number
      flat: number
    }
    opportunity: {
      max: number,
    },
    timeTracking: {
      max: number,
    },
    bankAccount: {
      max: number,
    },
    pricing: {
      [k2 in ABGroupPricing]: {
        [StripeProductFrequency.MONTH]: {
          amount: number,
          amountByMonth: number,
          priceId: string
        }
        [StripeProductFrequency.YEAR]: {
          amount: number,
          amountByMonth: number,
          priceId: string
        }
      }
    }
  }
}, AbbyPlans.ABBY_FREE> & {
  [AbbyPlans.ABBY_FREE]: {
    features: Array<PlanFeature>
    stripe: {
      rate: number
      flat: number
    },
    oldStripeFees: {
      rate: number
      flat: number
    },
    opportunity: {
      max: number,
    },
    timeTracking: {
      max: number,
    },
    bankAccount: {
      max: number,
    },
  }
};
export const PlansConfig: PlanConfigType = {
  [AbbyPlans.ABBY_FREE]: {
    features: FeaturesByPlan[AbbyPlans.ABBY_FREE] || [],
    stripe: { // fake because in free plan, we don't have online payment
      rate: 1.9, // 3,5 - 1,5 (stripe commission)
      flat: 35, // 60 - 25 (stripe commission)
    },
    oldStripeFees: {
      rate: 1.9, // 3,4 - 1,5 (stripe commission)
      flat: 35, // 60 - 25 (stripe commission)
    },
    opportunity: {
      max: 5,
    },
    timeTracking: {
      max: 10,
    },
    bankAccount: {
      max: 0,
    },
  },
  [AbbyPlans.ABBY_START]: startConfig,
  [AbbyPlans.ABBY_CREATION_START]: startConfig,
  [AbbyPlans.ABBY_CREATION_START_PREMIUM]: startConfig,
  [AbbyPlans.ABBY_CREATION_START_BUSINESS]: startConfig,
  [AbbyPlans.ABBY_PRO]: {
    features: [
      ...(FeaturesByPlan[AbbyPlans.ABBY_FREE] || []),
      ...(FeaturesByPlan[AbbyPlans.ABBY_START] || []),
      ...(FeaturesByPlan[AbbyPlans.ABBY_PRO] || []),
    ],
    stripe: {
      rate: 0.4, // 1,9 - 1,5 (stripe commission)
      flat: 25, // 50 - 25 (stripe commission)
    },
    oldStripeFees: {
      rate: 0, // 1,5 - 1,5 (stripe commission)
      flat: 0, // 25 - 25 (stripe commission)
    },
    opportunity: {
      max: -1,
    },
    timeTracking: {
      max: -1,
    },
    bankAccount: {
      max: 1,
    },
    pricing: {
      [ABGroupPricing.DEFAULT]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 18000,
          amountByMonth: 1500,
          priceId: isProduction ? 'price_1OHqDiI21zkxZU1x4YPdTfaM' : 'price_1OHVfPI21zkxZU1xRVwC3L9H',
        },
      },
      [ABGroupPricing.GROUP_A]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 18000,
          amountByMonth: 1500,
          priceId: isProduction ? 'price_1OHqDiI21zkxZU1x4YPdTfaM' : 'price_1OHVfPI21zkxZU1xRVwC3L9H',
        },
      },
      [ABGroupPricing.GROUP_B]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 18000,
          amountByMonth: 1500,
          priceId: isProduction ? 'price_1OHqDiI21zkxZU1x4YPdTfaM' : 'price_1OHVfPI21zkxZU1xRVwC3L9H',
        },
      },
      [ABGroupPricing.GROUP_C]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 18000,
          amountByMonth: 1500,
          priceId: isProduction ? 'price_1OHqDiI21zkxZU1x4YPdTfaM' : 'price_1OHVfPI21zkxZU1xRVwC3L9H',
        },
      },
      [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 33600,
          amountByMonth: 1400,
          priceId: isProduction ? 'price_1QUqpWI21zkxZU1xB3LoaVtW' : 'price_1QUEucI21zkxZU1xpu9dJiZt',
        },
      },
      [ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39990,
          amountByMonth: 1111,
          priceId: isProduction ? 'price_1QUqpyI21zkxZU1xDX6LwDmb' : 'price_1QUFE9I21zkxZU1x6ZLB4JkN',
        },
      },
      [ABGroupPricing.RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 19200,
          amountByMonth: 1400,
          priceId: isProduction ? 'price_1QUqrWI21zkxZU1xrVSQzbPl' : 'price_1QUm0ZI21zkxZU1xcYbp9pv6',
        },
      },
      [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
        [StripeProductFrequency.MONTH]: {
          amount: 1900,
          amountByMonth: 1900,
          priceId: isProduction ? 'price_1OHkpFI21zkxZU1xKns4idMz' : 'price_1OHWESI21zkxZU1xhqFj4b8j',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 33600,
          amountByMonth: 1400,
          priceId: isProduction ? 'price_1QUqpWI21zkxZU1xB3LoaVtW' : 'price_1QUEucI21zkxZU1xpu9dJiZt',
        },
      },
    },
  },
  [AbbyPlans.ABBY_BUSINESS]: {
    features: [
      ...(FeaturesByPlan[AbbyPlans.ABBY_FREE] || []),
      ...(FeaturesByPlan[AbbyPlans.ABBY_START] || []),
      ...(FeaturesByPlan[AbbyPlans.ABBY_PRO] || []),
      ...(FeaturesByPlan[AbbyPlans.ABBY_BUSINESS] || []),
    ],
    stripe: {
      rate: 0, // 1,5 - 1,5 (stripe commission)
      flat: 0, // 25 - 25 (stripe commission)
    },
    oldStripeFees: {
      rate: 0, // 1,5 - 1,5 (stripe commission)
      flat: 0, // 25 - 25 (stripe commission)
    },
    opportunity: {
      max: -1,
    },
    timeTracking: {
      max: -1,
    },
    bankAccount: {
      max: -1,
    },
    pricing: {
      [ABGroupPricing.DEFAULT]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
      [ABGroupPricing.GROUP_A]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
      [ABGroupPricing.GROUP_B]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
      [ABGroupPricing.GROUP_C]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
      [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
      [ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
      [ABGroupPricing.RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
      [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
        [StripeProductFrequency.MONTH]: {
          amount: 3900,
          amountByMonth: 3900,
          priceId: isProduction ? 'price_1PkjTeI21zkxZU1xKO0GZoow' : 'price_1Pskx4I21zkxZU1xTG6LuXtR',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 39600,
          amountByMonth: 3300,
          priceId: isProduction ? 'price_1PkjWKI21zkxZU1xdWQNiqKi' : 'price_1PskwfI21zkxZU1xaP4WYL1X',
        },
      },
    },
  },
};

export const AddonsConfig = {
  [AbbyAddons.ABBY_CE]: {
    pricing: {
      [ABGroupPricing.DEFAULT]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1MQpV2I21zkxZU1xWua1fn7o' : 'price_1MQpEII21zkxZU1xspqgHYQw',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1MX23WI21zkxZU1x1vBaX2ng' : 'price_1NZ7LqI21zkxZU1xHZ6aFxil',
        },
      },
      [ABGroupPricing.GROUP_A]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1OHkoBI21zkxZU1xbDErmlLv' : 'price_1OHkcKI21zkxZU1xz2I44RaK',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1OHkomI21zkxZU1xXMhq4NdR' : 'price_1OHkchI21zkxZU1xJR8SfqtK',
        },
      },
      [ABGroupPricing.GROUP_B]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1MQpV2I21zkxZU1xWua1fn7o' : 'price_1MQpEII21zkxZU1xspqgHYQw',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1MX23WI21zkxZU1x1vBaX2ng' : 'price_1NZ7LqI21zkxZU1xHZ6aFxil',
        },
      },
      [ABGroupPricing.GROUP_C]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1MQpV2I21zkxZU1xWua1fn7o' : 'price_1MQpEII21zkxZU1xspqgHYQw',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1MX23WI21zkxZU1x1vBaX2ng' : 'price_1NZ7LqI21zkxZU1xHZ6aFxil',
        },
      },
      [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1MQpV2I21zkxZU1xWua1fn7o' : 'price_1MQpEII21zkxZU1xspqgHYQw',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1MX23WI21zkxZU1x1vBaX2ng' : 'price_1NZ7LqI21zkxZU1xHZ6aFxil',
        },
      },
      [ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1MQpV2I21zkxZU1xWua1fn7o' : 'price_1MQpEII21zkxZU1xspqgHYQw',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1MX23WI21zkxZU1x1vBaX2ng' : 'price_1NZ7LqI21zkxZU1xHZ6aFxil',
        },
      },
      [ABGroupPricing.RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1MQpV2I21zkxZU1xWua1fn7o' : 'price_1MQpEII21zkxZU1xspqgHYQw',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1MX23WI21zkxZU1x1vBaX2ng' : 'price_1NZ7LqI21zkxZU1xHZ6aFxil',
        },
      },
      [ABGroupPricing.RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS]: {
        [StripeProductFrequency.MONTH]: {
          amount: 500,
          amountByMonth: 500,
          priceId: isProduction ? 'price_1MQpV2I21zkxZU1xWua1fn7o' : 'price_1MQpEII21zkxZU1xspqgHYQw',
        },
        [StripeProductFrequency.YEAR]: {
          amount: 4800,
          amountByMonth: 400,
          priceId: isProduction ? 'price_1MX23WI21zkxZU1x1vBaX2ng' : 'price_1NZ7LqI21zkxZU1xHZ6aFxil',
        },
      },
    },
  },
};
