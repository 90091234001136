
import { Action, Component, Getter, Mutation, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import {
  BillingAction,
  BillingLocale,
  BillingState,
  BillingType,
  cleanBillingDocument,
  getAvailableBillingActions,
  getCurrentFile,
  IBilling,
  IBillingConfiguration,
  ICompany,
  isHumanNameValidYousign,
  isMailValidYousign,
  PaymentRequestStatusUrssafTp, PlanFeature,
  ReadCountSignature,
  signatureNotAvailable,
  isFinalizable, messageForDisabledAction, ReadStripeAccount, ReadStripeAccountLink,
} from '@abby/core-legacy'
import { saveAs } from 'file-saver'
import * as PhoneNumber from 'awesome-phonenumber'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import { useOpenBillingMarkAsPaidModal } from '~/composables/billing/action/useOpenBillingMarkAsPaidModal'

enum BillingActionGroup {
  ADVICE,
  ADMIN,
  OTHER,
  ACCOUNTING_BILLING,
  MARK,
  SEND,
  CREATE,
  DUPLICATE,
  AUTO,
}

@Component({
  components: { AbbyPlanChip },
  setup () {
    const openBillingMarkAsPaidModal = useOpenBillingMarkAsPaidModal()
    return {
      openBillingMarkAsPaidModal,
    }
  },
})
export default class BillingTableActionMenu extends Vue {
  @Prop({ required: true })
  billing!: IBilling

  @PropSync('actionLoading', { default: false })
  loading!: boolean

  @Getter('billingCreation/isBillingCreationModalMinimized')
  isBillingCreationModalMinimized!: boolean

  @Getter('billingCreation/isBillingEditionModalMinimized')
  isBillingEditionModalMinimized!: boolean

  @Getter('billingCreation/billingDocument')
  billingDocumentInEdition!: IBilling

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration

  @Getter('company/company')
  company!: ICompany;

  @Getter('billingConfiguration/stripeAccount')
  stripeAccount!: ReadStripeAccount | null

  @Mutation('billingCreation/ADD_BILLING_DOCUMENT_LOADING_FOR_ACTION')
  addBillingDocumentLoadingForAction!: (id?: string) => void

  @Mutation('billingCreation/REMOVE_BILLING_DOCUMENT_LOADING_FOR_ACTION')
  removeBillingDocumentLoadingForAction!: (id?: string) => void

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  @Getter('signature/counts')
  counts!: ReadCountSignature;

  @Getter('billingConfiguration/stripeAccountLink')
  stripeAccountLink!: ReadStripeAccountLink | null;

  @Action('billing/setBillingValidityDateModal')
  setBillingValidityDateModal!: (value: boolean) => Promise<void>

  @Action('billingCreation/openCreationBilling')
  openCreationBilling!: (payload: { createFormBilling?: IBilling, billingDocument?: Partial<IBilling> }) => Promise<void>

  @Action('billingCreation/askBeforeEraseTheEditingDocument')
  askBeforeEraseTheEditingDocument!: (confirmText: string) => Promise<boolean>

  @Getter('billing/isAlreadyOpenedOnSidePanel')
  _isAlreadyOpenedOnSidePanel!: (id: string) => boolean

  @Action('billing/openBillingSidePanel')
  openBillingSidePanel!: (payload: IBilling) => Promise<void>;

  @Action('billing/setBillingDocumentForAction')
  setBillingDocumentForAction!: (value: IBilling | null) => Promise<void>;

  @Action('billing/openBillingTitleModal')
  openBillingTitleModal!: () => Promise<void>;

  @Action('billing/setBillingCancelModal')
  setBillingCancelModal!: (value: boolean) => Promise<void>;

  @Action('billing/activateOnlineSignature')
  activateOnlineSignature!: ({ id, validityDate }: { id: string, validityDate: Date }) => Promise<void>;

  @Action('billing/activateStripePayment')
  activateStripePayment!: (id: string) => Promise<void>;

  @Action('signature/fetchCounts')
  fetchCounts!: () => Promise<void>;

  openBillingMarkAsPaidModal!: Function

  get BillingActionGroup () {
    return BillingActionGroup
  }

  get isAlreadyOpenedOnSidePanel () {
    return this._isAlreadyOpenedOnSidePanel(this.billing._id as string)
  }

  get isArchived (): boolean {
    return !!this.billing.archivedAt
  }

  get BillingAction () {
    return BillingAction
  }

  get billingHasAlreadyHadFinalizedState () {
    return [BillingState.FINALIZED, BillingState.PAID, BillingState.SIGNED, BillingState.REFUSED].includes(this.billing.billingState)
  }

  get signatureNotAvailable () {
    const client = this.billing?.customer?.client?.[0]
    const phone = PhoneNumber.parsePhoneNumber(client?.phone || '')
    const isPhoneValid = phone.valid && phone.typeIsMobile
    const email = client?.email || ''
    return !email || !client?.phone || !isPhoneValid ||
        !isHumanNameValidYousign(client?.firstname) ||
        !isHumanNameValidYousign(client?.lastname) ||
        !isMailValidYousign(email)
  }

  get messageForDisabledAction () {
    return messageForDisabledAction(this.billing, this.stripeAccount)
  }

  get onlinePaymentIsConfigured () {
    return this.stripeAccount && (!this.stripeAccount.requirements || (this.stripeAccount.requirements && !this.stripeAccount.requirements.disabled_reason))
  }

  // La fonction permet de grouper les actions dans la liste
  get availableBillingActions () {
    const signature = signatureNotAvailable(this.billing)
    const allActions = {
      [BillingActionGroup.ADVICE]: {
        [BillingAction.ACTIVATE_ONLINE_SIGNATURE]: {
          warning: signature.hasError,
          disabled: signature.hasError,
          tooltip: signature.hasError ? signature.errorYousignClient : null,
          action: this.activateSignature,
          feature: this.$planManager.features.billing_estimate_online_signature,
        },
        [BillingAction.ACTIVATE_ONLINE_PAYMENT]: {
          action: this.activatePayment,
          warning: !this.onlinePaymentIsConfigured,
          disabled: !this.onlinePaymentIsConfigured,
          tooltip: !this.onlinePaymentIsConfigured ? 'Vous devez configurer votre compte Stripe avant de pouvoir utiliser le paiement en ligne.' : null,
          feature: this.$planManager.features.billing_online_payment,
        },
        [BillingAction.UNPAID_INVOICE]: { action: this.openGCollectModal, img: '/advantages/avantage-gcollect.png' },
      },
      [BillingActionGroup.ADMIN]: {
        [BillingAction.ADMIN_DELETE_DOCUMENT]: { action: this.adminDeleteDocument },
        [BillingAction.ADMIN_COPY_DOCUMENT_ID]: { action: this.adminCopyDocumentId },
        [BillingAction.ADMIN_COPY_ISSUE_PARTICULIER_INCONNU]: { action: this.adminCopyParticulierInconnu },
        [BillingAction.ADMIN_COPY_BILLING_CONFIGURATION_ID]: { action: this.adminCopyBillingConfigurationId },
        [BillingAction.ADMIN_REGENERATE_DOCUMENT]: { action: this.regenerateDocument },
        // [BillingAction.ADMIN_UNLINK_FROM_CREATE_FROM]: { action: this.unlinkFromCreateFrom },
      },
      [BillingActionGroup.OTHER]: {
        [BillingAction.UPDATE_DOCUMENT]: { action: this.updateDocument },
        [BillingAction.DELETE_DOCUMENT]: { action: this.deleteDocument },
        [BillingAction.CANCEL_BILLING]: { action: this.cancelBilling },
        [BillingAction.UPDATE_TITLE]: {
          action: this.openUpdateTitleModal,
          feature: this.$planManager.features.billing_title,
        },
        [BillingAction.DOWNLOAD_SIGNATURE_PROOF]: { action: this.downloadProof },
        [BillingAction.ARCHIVE]: {
          action: this.toggleArchiveDocument,
          feature: this.$planManager.features.billing_archive_documents,
        },
        [BillingAction.UNARCHIVE]: {
          action: this.toggleArchiveDocument,
          feature: this.$planManager.features.billing_archive_documents,
        },
      },
      [BillingActionGroup.ACCOUNTING_BILLING]: {
        [BillingAction.MARK_AS_PAID]: {
          action: this.markAsPaid,
          feature: this.$planManager.features.billing_vat,
          disabled: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          warning: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          tooltip: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount) ? '<p>Ce document ne peut pas être marqué comme payé</p>' + this.messageForDisabledAction : undefined,
        },
      },
      [BillingActionGroup.MARK]: {
        [BillingAction.CHECK_URSSAF_TP_STATUT]: {
          action: this.checkUrssafTpStatut,
        },
        [BillingAction.MARK_AS_FINALIZED]: {
          action: this.markAsFinalized,
          feature: this.$planManager.features.billing_vat,
          disabled: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          warning: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          tooltip: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount) ? '<p>Ce document ne peut pas être finalisé</p>' + this.messageForDisabledAction : undefined,
        },
        [BillingAction.MARK_AS_FINALIZED_AND_SEND]: {
          action: this.markAsFinalizedAndSend,
          feature: this.$planManager.features.billing_vat,
          disabled: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          warning: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          tooltip: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount) ? '<p>Ce document ne peut pas être finalisé</p>' + this.messageForDisabledAction : undefined,
        },
        [BillingAction.MARK_AS_NOT_REFUSED]: { action: this.markAsNotRefused },
        [BillingAction.MARK_AS_NOT_SIGNED]: { action: this.markAsNotSigned },
        [BillingAction.MARK_AS_REFUSED]: {
          action: this.markAsRefused,
          disabled: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          warning: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          tooltip: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount) ? '<p>Ce document ne peut pas être marqué comme refusé</p>' + this.messageForDisabledAction : undefined,
        },
        [BillingAction.MARK_AS_SIGNED]: {
          action: this.markAsSigned,
          disabled: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          warning: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount),
          tooltip: !this.billingHasAlreadyHadFinalizedState && !isFinalizable(this.billing, this.stripeAccount) ? '<p>Ce document ne peut pas être marqué comme signé</p>' + this.messageForDisabledAction : undefined,
        },
        [BillingAction.MARK_AS_UN_PAID]: { action: this.markAsUnPaid },
      },
      [BillingActionGroup.SEND]: {
        ...(!this.isAlreadyOpenedOnSidePanel ? { [BillingAction.OPEN]: { action: this.open } } : {}),
        [BillingAction.DOWNLOAD]: { action: this.download },
        [BillingAction.DOWNLOAD_FRENCH_LOCALE]: { action: this.downloadFrenchLocale },
        ...(this.billing.billingState !== BillingState.DRAFT ? { [BillingAction.SEND_BY_EMAIL]: { action: this.sendByEmail } } : {}),
        [BillingAction.COPY_STRIPE_PAYMENT_URL]: { action: () => this.copyPaymentLink('payment') },
        [BillingAction.COPY_SIGNATURE_URL]: { action: () => this.copyPaymentLink('signature') },
      },
      [BillingActionGroup.CREATE]: {
        [BillingAction.CREATE_AN_ADVANCE]: {
          feature: this.$planManager.features.billing_advance_creation,
          action: this.createAnAdvance,
        },
        [BillingAction.CREATE_AN_ASSET]: { action: this.createAnAsset },
        [BillingAction.CREATE_AN_INVOICE]: {
          action: this.createAnInvoice,
        },
      },
      [BillingActionGroup.DUPLICATE]: {
        [BillingAction.DUPLICATE_AS_INVOICE]: { action: this.duplicateAsInvoice },
        [BillingAction.DUPLICATE_AS_ESTIMATE]: { action: this.duplicateAsEstimate },
        [BillingAction.DUPLICATE_AS_PURCHASE_ORDER]: { action: this.duplicateAsPurchaseOrder },
      },
      [BillingActionGroup.AUTO]: {
        ...(!this.billing.tiersPrestationIsActivatedForThisBilling
          ? {
            [BillingAction.MANAGE_FREQUENCY]: {
              action: this.updateFrequency,
              feature: this.$planManager.features.billing_frequency,
            },
            [BillingAction.UPDATE_REMINDER]: {
              action: this.openUpdateReminderModal,
              feature: this.$planManager.features.billing_reminder,
            },
          }
          : {}),
      },
    }

    return Object.entries(allActions).map(([i, actions]) => {
      const allowedActions = this.$lodash.pick(actions, getAvailableBillingActions(this.billing, this.connectedFromDashboard))

      return {
        header: this.$t(`billingActionGroup.${BillingActionGroup[+i]}`),
        type: BillingActionGroup[+i],
        actions: Object.entries(allowedActions),
      }
    }).filter(a => a.actions?.length)
  }

  async downloadProof () {
    try {
      const result = await this.$api.signature.downloadProof(this.billing?._id || '')
      const isPdf = result.contentType === 'application/pdf'

      saveAs(new Blob([result.file], { type: isPdf ? 'application/pdf' : 'application/zip' }), `Attestation de signature ${this.billing.number}.${isPdf ? 'pdf' : 'zip'}`)
    } catch (e: any) {
      return this.$alertsManager.autoError(e as any)
    }
  }

  async copyPaymentLink (type: 'payment' | 'signature') {
    if (this.billing.token) {
      await this.$clipboard.copy(`${process.env.baseAppUrl}/payment?id=${this.billing._id}&token=${this.billing.token}`)
    } else {
      await this.$clipboard.copy(`${process.env.baseAppUrl}/clientPortal?id=${this.billing.customer._id}&billing=${this.billing._id}&token=${this.billing.customer.token}`)
    }
    switch (type) {
    case 'payment':
      return this.$alertsManager.success('Lien de paiement copié')
    case 'signature':
      return this.$alertsManager.success('Lien de signature copié')
    }
  }

  async createAnAdvance () {
    if (!this.$planManager.hasAccessTo(this.$planManager.features.billing_advance_creation)) {
      await this.$planManager.openAbbyPlansModal({ feature: this.$planManager.features.billing_advance_creation })
      this.$emit('close-menu')
      return
    }

    await this.create({
      billingType: BillingType.ADVANCE,
      frequency: this.billing.frequency,
      opportunityId: this.billing.opportunityId,
      currency: this.billing.currency,
      currencyDate: this.billing.currencyDate,
      currencyRate: this.billing.currencyRate,
      locale: this.billing.locale,
    })
  }

  async createAnAsset () {
    await this.create({
      billingType: BillingType.ASSET,
      product: this.billing.product,
      discountAmount: this.billing.discountAmount,
      discountMode: this.billing.discountMode,
      opportunityId: this.billing.opportunityId,
      currency: this.billing.currency,
      currencyDate: this.billing.currencyDate,
      currencyRate: this.billing.currencyRate,
      locale: this.billing.locale,
    })
  }

  async createAnInvoice () {
    await this.create({
      billingType: BillingType.INVOICE,
      product: this.billing.product,
      discountAmount: this.billing.discountAmount,
      discountMode: this.billing.discountMode,
      frequency: this.billing.frequency,
      currency: this.billing.currency,
      currencyDate: this.billing.currencyDate,
      currencyRate: this.billing.currencyRate,
      locale: this.billing.locale,
      opportunityId: this.billing.opportunityId,
    })
  }

  async create (payload: Partial<IBilling>) {
    if ([BillingType.ADVANCE, BillingType.INVOICE, BillingType.ASSET].includes(payload.billingType || BillingType.INVOICE)) {
      payload.useSignature = false
    } else if ([BillingType.ESTIMATE, BillingType.PURCHASE_ORDER, BillingType.ASSET].includes(payload.billingType || BillingType.INVOICE)) {
      payload.useStripePayment = false
    }
    await this.openCreationBilling({
      createFormBilling: {
        ...this.billing,
        ...([BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(this.billing.billingType)
          ? {
            displayGoodForApproval: false,
          }
          : {}),
      },
      billingDocument: payload,
    })
  }

  cancelBilling () {
    if (this.billing.tiersPrestationIsActivatedForThisBilling) {
      this.$help.openArticle('6466692#h_4c56c50816')
      return
    }
    this.setBillingCancelModal(true)
    this.setBillingDocumentForAction(this.billing)
  }

  async deleteDocument () {
    if (!this.billing._id) { return }
    try {
      const confirm = await this.$confirmManager.ask({
        title: this.$t(`confirmBilling.${BillingAction[BillingAction.DELETE_DOCUMENT]}.title`) as string,
        content: this.$t(`confirmBilling.${BillingAction[BillingAction.DELETE_DOCUMENT]}.content`) as string,
        confirmText: this.$t(`confirmBilling.${BillingAction[BillingAction.DELETE_DOCUMENT]}.button`) as string,
        icon: 'remove',
      })
      if (!confirm) { return }
      this.loading = true
      this.addBillingDocumentLoadingForAction(this.billing._id)
      const result = await this.$api.billing.deleteBillingDocument(this.billing._id)
      this.$busManager.emit('billingRemoved', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  async adminDeleteDocument () {
    if (!this.billing._id) { return }
    try {
      const confirm = await this.$confirmManager.ask({
        title: this.$t(`confirmBilling.${BillingAction[BillingAction.ADMIN_DELETE_DOCUMENT]}.title`) as string,
        content: this.$t(`confirmBilling.${BillingAction[BillingAction.ADMIN_DELETE_DOCUMENT]}.content`) as string,
        confirmText: this.$t(`confirmBilling.${BillingAction[BillingAction.ADMIN_DELETE_DOCUMENT]}.button`) as string,
      })
      if (!confirm) { return }
      this.loading = true
      this.addBillingDocumentLoadingForAction(this.billing._id)
      const result = await this.$api.billing.deleteBillingDocument(this.billing._id)
      this.$busManager.emit('billingRemoved', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  async adminCopyDocumentId () {
    if (!this.billing._id) { return }
    await this.$clipboard.copy(`_id: ObjectId("${this.billing._id}")`)
    this.$alertsManager.info('Copié avec succès')
  }

  async adminCopyParticulierInconnu () {
    if (!this.billing._id) { return }
    await this.$clipboard.copy(`Bonjour, \nNous avons une erreur ERR_PARTICULIER_INCONNU avec "idClient" : "${this.billing.customer.tiersPrestation?.idClient}".\nLe client ID de l’OSP est : clientId" : "${this.billingConfiguration.tiersPrestationCredentials?.clientId}",\nLe particulier a correctement activé son compte.\nMerci de résoudre le problème.\nBien cordialement`)
    this.$alertsManager.info('Copié avec succès')
  }

  async adminCopyBillingConfigurationId () {
    if (!this.billing._id) { return }
    await this.$clipboard.copy(`companyId: "${this.billing.companyId}"`)
    this.$alertsManager.info('Copié avec succès')
  }

  async regenerateDocument () {
    if (!this.billing._id) { return }
    try {
      const confirm = await this.$confirmManager.ask({
        title: this.$t(`confirmBilling.${BillingAction[BillingAction.ADMIN_REGENERATE_DOCUMENT]}.title`) as string,
        content: this.$t(`confirmBilling.${BillingAction[BillingAction.ADMIN_REGENERATE_DOCUMENT]}.content`) as string,
        confirmText: this.$t(`confirmBilling.${BillingAction[BillingAction.ADMIN_REGENERATE_DOCUMENT]}.button`) as string,
      })
      if (!confirm) { return }
      this.loading = true
      this.addBillingDocumentLoadingForAction(this.billing._id)
      const result = await this.$api.billing.regenerateDocument(this.billing._id)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  async unlinkFromCreateFrom () {
    if (!this.billing._id) { return }
    try {
      const confirm = await this.$confirmManager.ask({
        title: 'Enlever les liens de création entre les documents',
        content: 'Cette action est irréversible. Voulez-vous vraiment enlever les liens entre une facture, un acompte et son devis ?',
        confirmText: 'Continuer',
      })
      if (!confirm) { return }
      this.loading = true
      this.addBillingDocumentLoadingForAction(this.billing._id)
      const result = await this.$api.billing.unlinkFromCreateFrom(this.billing._id)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  get isCurrencyDisabled () {
    return !this.company.multipleCurrency || !this.$planManager.hasAccessTo(this.$planManager.features.billing_multi_currency)
  }

  async activateSignature () {
    if (!this.billing._id) { return }
    try {
      await this.fetchCounts()

      const confirmText = this.counts.remaining ? 'Activer' : 'Acheter des crédits'
      const contentCountText = this.counts.remaining ? `Il vous reste <b>${this.counts.remaining} ${this.counts.remaining > 1 ? 'crédits' : 'crédit'} de signature.</b>` : '<b>Attention</b> : vous n\'avez plus de crédits de signature.'
      const confirm = await this.$confirmManager.ask({
        confirmText,
        title: 'Activer la signature en ligne',
        content: `Souhaitez-vous activer la signature en ligne pour le document ${this.billing.number} ?` + (this.$planManager.hasAccessTo(PlanFeature.BILLING_ESTIMATE_ONLINE_SIGNATURE) && this.counts.remaining > -1 ? `<br><br>${contentCountText}` : ''),
        confirmButton: { color: 'primary', depressed: true },
        feature: this.$planManager.features.billing_estimate_online_signature,
      })
      if (!confirm) {
        return
      }

      const diff = this.$dayjs(this.billing.validityDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).diff(this.$dayjs(), 'day')
      if (!this.counts.remaining) {
        this.$help.openArticle('6762053#h_5f3293dc1d')
      } else if (diff < 0 || !this.billing.validityDate) {
        await this.setBillingDocumentForAction(this.billing)
        await this.setBillingValidityDateModal(true)
      } else {
        const billing = await this.activateOnlineSignature({ id: this.billing._id, validityDate: this.billing.validityDate })
        this.$busManager.emit('billingUpdated', billing)
        this.$alertsManager.success('La signature en ligne a été activée avec succès sur votre document')
      }
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }

  async activatePayment () {
    if (!this.billing._id) { return }
    try {
      const confirm = await this.$confirmManager.ask({
        title: 'Activer le paiement en ligne',
        confirmText: 'Activer',
        content: `Souhaitez-vous activer le paiement en ligne pour le document ${this.billing.number} ?`,
        confirmButton: { color: 'primary', depressed: true },
        feature: this.$planManager.features.billing_online_payment,
      })
      if (!confirm) { return }
      await this.activateStripePayment(this.billing._id)
      this.$alertsManager.success('Le paiement en ligne a été activé avec succès sur votre document')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }

  async duplicate (billingType: BillingType) {
    const billingDocument = cleanBillingDocument(this.billing) as IBilling

    // On réinitialise les infos de la payment Request et du TP
    delete billingDocument.tiersPrestationIsActivatedForThisBilling
    delete billingDocument.paymentRequest
    delete billingDocument.number
    delete billingDocument.attachments
    delete billingDocument.canceledAt
    delete billingDocument.archivedAt

    if (this.isCurrencyDisabled) {
      delete billingDocument.currency
      delete billingDocument.currencyRate
      delete billingDocument.currencyDate
    }

    // Lors de la duplication on injecte tous les params du présent document dans le nouveau du store et on change son type en fonction de l'action désirée
    // Aucune requête n'est envoyée à ce moment l, on ouvre simplement la modal de création et l'utilisateur l'enregistrera lui-même
    await this.openCreationBilling({
      billingDocument: {
        ...billingDocument,
        billingType,
        validityDate: this.$dayjs().add(1, 'month').subtract(1, 'day').toDate(),
      },
    })
  }

  async duplicateAsEstimate () {
    await this.duplicate(BillingType.ESTIMATE)
  }

  async duplicateAsPurchaseOrder () {
    await this.duplicate(BillingType.PURCHASE_ORDER)
  }

  async duplicateAsInvoice () {
    await this.duplicate(BillingType.INVOICE)
  }

  confirmBeforeMarkAs (billingAction: BillingAction) {
    const confirmTextMode = this.billingConfiguration.test && billingAction === BillingAction.MARK_AS_FINALIZED ? '_TEST' : ''
    return this.$confirmManager.ask({
      title: this.$t(`confirmBilling.${BillingAction[billingAction]}${confirmTextMode}.title`) as string,
      content: this.$t(`confirmBilling.${BillingAction[billingAction]}${confirmTextMode}.content`) as string,
      confirmText: this.$t(`confirmBilling.${BillingAction[billingAction]}${confirmTextMode}.button`) as string,
      confirmButton: { color: 'primary', depressed: true },
    })
  }

  async checkUrssafTpStatut () {
    // @ts-expect-error
    await this.$api.billing.checkUrssafTpStatut(this.billing._id)
    await this.$store.dispatch('billing/fetchBillings')
  }

  async markAsFinalized (): Promise<boolean> {
    if (!this.billing._id) { return false }
    if (!this.$planManager.hasAccessTo(this.$planManager.features.billing_vat)) {
      this.$planManager.openAbbyPlansModal({ feature: this.$planManager.features.billing_vat })
      this.$emit('close-menu')
      return false
    }
    try {
      const confirm = await this.confirmBeforeMarkAs(BillingAction.MARK_AS_FINALIZED)

      if (!confirm) { return false }

      this.addBillingDocumentLoadingForAction(this.billing._id)
      this.loading = true
      const result = await this.$api.billing.markAsFinalized(this.billing._id)
      if (result?.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_PARTICULIER_INCONNU) {
        await this.$confirmManager.ask({
          noCancelButton: true,
          maxWidth: 600,
          title: 'L\'espace particulier Urssaf de votre client n\'est pas activé',
          content: `<p>Votre client ne peut pas recevoir de demande de paiement tant qu'il n'a pas activé son compte particulier Urssaf.</p>
<p>Il a dû recevoir un e-mail à l'adresse <b>${result.customer?.client?.[0]?.email}</b> de la part de l'Urssaf lui demandant d'activer son espace particulier.</p>
<p>Si cela fait plus de 7 jours que votre client n’a pas activé son compte, il doit se rende sur la page suivante :
<a href="https://www.particulier.urssaf.fr/#/lien-activation-compte">https://www.particulier.urssaf.fr/#/lien-activation-compte</a> et qu'il saisisse son e-mail avec lequel vous l’avez enregistré sur Abby. Il recevra alors un nouvel e-mail lui demandant d’activer son compte.</p>`,
          confirmText: 'J\'ai compris',
          confirmButton: { depressed: true, color: 'primary' },
        })
      }
      this.trackIntercomBillingFinalizedEvent(this.billing)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
      return true
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
      return false
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  trackIntercomBillingFinalizedEvent (billingDocument: IBilling) {
    const type = {
      [BillingType.INVOICE]: 'invoice',
      [BillingType.ESTIMATE]: 'estimate',
      [BillingType.ASSET]: 'asset',
      [BillingType.ADVANCE]: 'advance',
      [BillingType.PURCHASE_ORDER]: 'purchaseOrder',
    }

    this.$help.sendChatEvent('BillingFinalized', {
      type: type[billingDocument.billingType],
      ...[BillingType.INVOICE, BillingType.ADVANCE].includes(billingDocument.billingType) ? { onlinePaymentActivated: billingDocument.useStripePayment } : {},
      ...[BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billingDocument.billingType) ? { onlineSignatureActivated: billingDocument.useSignature } : {},
    })
  }

  async markAsFinalizedAndSend () {
    if (!this.$planManager.hasAccessTo(this.$planManager.features.billing_vat)) {
      this.$planManager.openAbbyPlansModal({ feature: this.$planManager.features.billing_vat })
      this.$emit('close-menu')
      return
    }
    const finalized = await this.markAsFinalized()
    if (!finalized) { return }
    await this.sendByEmail()
  }

  async confirmArchive () {
    let content = ''
    if (this.billing.frequency?.activatedAt || this.billing.reminder?.active) {
      content += '<p>Certains paramètres de ce document requièrent votre attention :</p><ul class="as-bullet-list">'
      if (this.billing.reminder?.active) {
        content += '<li>la relance automatique sera désactivée</li>'
      }

      if (this.billing.frequency?.activatedAt) {
        content += '<li>la récurrence sera toujours effective</li>'
      }
    }

    content += '</ul<>'

    content += `<p>Archiver un document permet de le masquer de votre liste principale de documents et de l'exclure dans les contextes ci-dessous. Vous pourrez toujours retrouver vos documents archivés en cliquant sur <b>Filtrer par état > Archivé.</b></p>
<ul class="as-bullet-list">
<li>Relation devis, acomptes et factures finales</li>
<li>Tableau statistiques</li>
<li>Attestations fiscales / Déclaration NOVA</li>
<li>Portail client (signature et paiement en ligne)</li>
</ul>`

    return await this.$confirmManager.ask({
      title: 'Confirmation',
      content,
      confirmButton: {
        color: 'primary',
        depressed: true,
      },
    })
  }

  async toggleArchiveDocument () {
    if (!this.$planManager.hasAccessTo(this.$planManager.features.billing_archive_documents)) {
      await this.$planManager.openAbbyPlansModal({ feature: this.$planManager.features.billing_archive_documents })
      this.$emit('close-menu')
      return
    }

    if (!this.billing._id) { return }

    try {
      if (!this.isArchived && !(await this.confirmArchive())) {
        return
      }

      this.addBillingDocumentLoadingForAction(this.billing._id)
      this.loading = true

      const billing = await this.$api.billing.archiveDocument(this.billing._id)

      this.removeBillingDocumentLoadingForAction(this.billing._id)
      await this.$store.dispatch('billing/fetchBillings')
      this.$busManager.emit('billingUpdated', billing)
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing._id)
      this.loading = false
    }
  }

  open () {
    this.openBillingSidePanel(this.billing)
  }

  async markAsNotRefused () {
    if (!this.billing._id) { return }
    try {
      this.addBillingDocumentLoadingForAction(this.billing._id)
      this.loading = true
      const result = await this.$api.billing.markAsNotRefused(this.billing._id)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  async markAsNotSigned () {
    if (!this.billing._id) { return }
    try {
      this.addBillingDocumentLoadingForAction(this.billing._id)
      this.loading = true
      const result = await this.$api.billing.markAsNotSigned(this.billing._id)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  markAsPaid () {
    if (!this.billing._id) { return }
    this.openBillingMarkAsPaidModal({ legacyBilling: this.billing })
    this.$emit('close-menu')
  }

  async markAsRefused () {
    if (!this.billing._id) { return }
    try {
      let confirm = true
      if (this.billing.billingState === BillingState.DRAFT) {
        confirm = await this.confirmBeforeMarkAs(BillingAction.MARK_AS_REFUSED)
      }
      if (!confirm) { return }
      this.addBillingDocumentLoadingForAction(this.billing._id)
      this.loading = true
      const result = await this.$api.billing.markAsRefused(this.billing._id)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  async markAsSigned () {
    if (!this.billing._id) { return }
    try {
      let confirm = true
      if (this.billing.billingState === BillingState.DRAFT) {
        confirm = await this.confirmBeforeMarkAs(BillingAction.MARK_AS_SIGNED)
      }
      if (!confirm) { return }
      this.addBillingDocumentLoadingForAction(this.billing._id)
      this.loading = true
      const result = await this.$api.billing.markAsSigned(this.billing._id)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  async markAsUnPaid () {
    if (!this.billing._id) { return }
    try {
      this.addBillingDocumentLoadingForAction(this.billing._id)
      this.loading = true
      const result = await this.$api.billing.markAsUnPaid(this.billing._id)
      this.$busManager.emit('billingUpdated', result)
      await this.$store.dispatch('billing/fetchBillings')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.removeBillingDocumentLoadingForAction(this.billing?._id)
      this.loading = false
    }
  }

  async updateDocument () {
    if ((this.isBillingCreationModalMinimized || this.isBillingEditionModalMinimized) && this.billingDocumentInEdition) {
      const confirm = await this.askBeforeEraseTheEditingDocument('Modifier')
      if (!confirm) { return }
    }
    // Injecter le from depuis le populate
    if (this.billing.createdFromEstimate) {
      this.$store.commit('billingCreation/SET_CREATE_FROM_BILLING_DOCUMENT', this.billing.createdFromEstimate)
    } else if (this.billing.createdFromInvoice) {
      this.$store.commit('billingCreation/SET_CREATE_FROM_BILLING_DOCUMENT', this.billing.createdFromInvoice)
    } else if (this.billing.createdFromPurchaseOrder) {
      this.$store.commit('billingCreation/SET_CREATE_FROM_BILLING_DOCUMENT', this.billing.createdFromPurchaseOrder)
    }
    this.$store.commit('billingCreation/SET_BILLING_DOCUMENT', this.billing)
    this.$store.commit('billingCreation/SET_IS_BILLING_EDITION_MODAL_OPENED', true)
  }

  updateFrequency () {
    if (!this.$planManager.hasAccessTo(this.$planManager.features.billing_frequency)) {
      this.$planManager.openAbbyPlansModal({ feature: this.$planManager.features.billing_frequency })
      this.$emit('close-menu')
      return
    }
    this.$store.commit('billing/SET_BILLING_DOCUMENT_FOR_ACTION', this.billing)
    this.$store.commit('billing/SET_IS_BILLING_FREQUENCY_MODAL_OPENED', true)
  }

  async downloadFrenchLocale () {
    await this.download({ locale: BillingLocale.FR })
  }

  async download (options?: { locale: BillingLocale}) {
    if (!this.billing._id) { return }
    const { id, url } = getCurrentFile(this.billing)
    // si le document a déjà été généré et que l'on ne force pas des options on le télécharge
    if (id && !options) {
      this.$file.saveAs(id, () => (this.billing.number || 'Brouillon') + '.pdf')
      await this.$api.billing.addDownloadedActivity(this.billing._id, {
        name: this.billing.number || 'Brouillon',
        url,
      })
      // await refreshPaginate()
      return
    }
    // on génère à la volée le document demandé par le client
    const document = await this.$api.billing.generateFileToDownload(this.billing._id, options || { locale: this.billing.locale as BillingLocale || BillingLocale.FR })
    const fileByState = {
      [BillingState.DRAFT]: document.draftFile,
      [BillingState.FINALIZED]: document.finalizedFile,
      [BillingState.SIGNED]: document.signedFile,
      [BillingState.PAID]: document.paidFile,
      [BillingState.REFUSED]: document.refusedFile,
    }
    const generatedFile = fileByState[document.billingState]
    this.$file.saveAs(generatedFile?.id || '', () => (this.billing.number || 'Brouillon') + '.pdf')
    await this.$api.billing.addDownloadedActivity(this.billing._id, {
      name: this.billing.number || 'Brouillon',
      url: generatedFile?.url || '',
    })
    this.$busManager.emit('billingUpdated', this.billing)
  }

  openGCollectModal () {
    this.$store.dispatch('billing/setBillingDocumentForAction', this.billing)
    this.$store.dispatch('abbyStore/findPartner', 'gcollect')
  }

  sendByEmail () {
    this.$store.commit('billing/SET_BILLING_DOCUMENT_FOR_ACTION', this.billing)
    this.$store.commit('billing/SET_IS_SEND_BILLING_BY_EMAIL_MODAL_OPENED', true)
  }

  openUpdateReminderModal () {
    if (!this.$planManager.hasAccessTo(this.$planManager.features.billing_reminder)) {
      this.$planManager.openAbbyPlansModal({ feature: this.$planManager.features.billing_reminder })
      this.$emit('close-menu')
      return
    }
    this.$store.commit('billing/SET_BILLING_DOCUMENT_FOR_ACTION', this.billing)
    this.$store.commit('billing/SET_IS_UPDATE_REMINDER_MODAL_OPENED', true)
  }

  async openUpdateTitleModal () {
    await this.setBillingDocumentForAction(this.billing)
    await this.openBillingTitleModal()
  }
}

