export type FormError = { [key: string]: string[] }

export class FormErrorMessageBuilder {
  static build (errors: FormError) {
    if (!errors) { return '' }
    const fields = Object.keys(errors)
    const lines = []
    for (const fieldName of fields) {
      const fieldErrors = errors[fieldName]
      if (fieldErrors.length) {
        lines.push(`\n${fieldName}`)
        for (const error of fieldErrors) {
          lines.push(`- ${error}`)
        }
      }
    }
    return lines.join('\n')
  }
}
