import { useProductRepository } from '~/logic/contexts/product/infrastructure/repository/product.repository'
import { ProductForm } from '~/logic/contexts/product/domain/form/product.form'

export const useCreateProduct = () => {
  const productRepository = useProductRepository()

  const createProduct = async (product: ProductForm) => {
    const productToCreate = { ...product }
    return await productRepository.createProduct(productToCreate)
  }

  return async (product: ProductForm) => {
    return await createProduct(product)
  }
}
