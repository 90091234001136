import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  CampaignType,
  CompanyDocumentStatus,
  CreationCompanyStep,
  RegisteredType,
  LegalStatus,
} from '@abby/core-legacy'

import Vue from 'vue'
import dayjs from 'dayjs'
import type {
  CreateAcreDocument,
  CreateCommercialTask,
  ICompany,
  ICompanyFile,
  ICreationCompany,
  ICreationFile,
  ReadCommercialTask,
  ReadStripeProduct,
  UpdateCompany,
  UpdateCompanyFile,
  UpdateCreationCompany,
} from '@abby/core-legacy'
import { CampaignTrigger } from '~/plugins/campaignsManager'

export interface CompanyState {
  company: ICompany | null,
  plans: Array<ReadStripeProduct>;
  creation: ICreationCompany | null;
  creationFiles: Array<ICreationFile>
  commercialTasks: ReadCommercialTask | null,
  isEditModalOpened: boolean,
  isFilesViewerOpened: boolean,
  isAcreEligibilityModalOpened: boolean,
  isAssuranceChoiceModalOpened: boolean,
  isCreationDocumentVerificationModalOpened: boolean,
  isCreationDocumentDepositModalOpened: boolean,
  isCompletenessTaskInfoModalOpened: boolean,
  files: ICompanyFile[] | null,
  mandatCreationToFill: { data: string } | null,
  nonCondamnationToFill: { data: string } | null,
  stepsToCheck: { text: string, complete: boolean }[],
  paymentMethods: any[] | null,
  isPaymentMethodModalOpened: boolean,
  isCreationToGestionModalOpened: boolean,
  isAskActivityModalOpened: boolean,
  isExtendedTrialModalOpened: boolean,
}

export const state = (): CompanyState => ({
  company: null,
  creation: null,
  plans: [],
  creationFiles: [],
  commercialTasks: null,
  isEditModalOpened: false,
  isFilesViewerOpened: false,
  isCreationDocumentVerificationModalOpened: false,
  isCreationDocumentDepositModalOpened: false,
  isAcreEligibilityModalOpened: false,
  isAssuranceChoiceModalOpened: false,
  isCompletenessTaskInfoModalOpened: false,
  files: null,
  mandatCreationToFill: null,
  nonCondamnationToFill: null,
  stepsToCheck: [],
  paymentMethods: null,
  isPaymentMethodModalOpened: false,
  isCreationToGestionModalOpened: false,
  isAskActivityModalOpened: false,
  isExtendedTrialModalOpened: false,
})

export const getters: GetterTree<CompanyState, CompanyState> = {
  company: state => state.company,
  plans: state => state.plans,
  creation: state => state.creation,
  creationFiles: state => state.creationFiles,
  commercialTasks: state => state.commercialTasks,
  isEditModalOpened: state => state.isEditModalOpened,
  isCreationDocumentVerificationModalOpened: state => state.isCreationDocumentVerificationModalOpened,
  isCreationDocumentDepositModalOpened: state => state.isCreationDocumentDepositModalOpened,
  isFilesViewerOpened: state => state.isFilesViewerOpened,
  isAcreEligibilityModalOpened: state => state.isAcreEligibilityModalOpened,
  isAssuranceChoiceModalOpened: state => state.isAssuranceChoiceModalOpened,
  isCompletenessTaskInfoModalOpened: state => state.isCompletenessTaskInfoModalOpened,
  files: state => state.files,
  mandatCreationToFill: state => state.mandatCreationToFill,
  nonCondamnationToFill: state => state.nonCondamnationToFill,
  isNewUser: state => !dayjs(state.company?.createdAt as any).isBefore(dayjs().subtract(14, 'd')),
  isMicroEntreprise: state => state.company?.legalStatus === LegalStatus.MICRO_ENTREPRISE,
  isEI: state => [LegalStatus.MICRO_ENTREPRISE, LegalStatus.EI, LegalStatus.EIRL].includes(state.company?.legalStatus || LegalStatus.MICRO_ENTREPRISE),
  isInCreation: (state) => {
    const { verifiedAt, registeredType } = state.company || {}
    return !verifiedAt && registeredType === RegisteredType.CREATION
  },
  isCompanyCreated: (state, getters) => {
    if (!getters.isInCreation) { return true }
    return state.creation?.steps?.[CreationCompanyStep.COMPANY_CREATED]?.finishedAt
  },
  numberOfFilesMissing: (state) => {
    return (state.files || []).filter((file: ICompanyFile) => [CompanyDocumentStatus.NOT_UPLOADED, CompanyDocumentStatus.INVALIDATED].includes(file.status)).length
  },
  nbOfStepsNotCompleted: (_, getters) => getters.stepsToCheck.filter((step: any) => !step.complete).length,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stepsToCheck: (state, getters, rootState, rootGetters) => {
    return [{
      text: 'Créer mon compte auto-entrepreneur urssaf',
      complete: !!state.creation?.steps[CreationCompanyStep.URSSAF_ACCOUNT_CREATED]?.finishedAt,
    }, {
      text: 'Faire ma demande d\'ACRE',
      complete: !!state.creation?.steps[CreationCompanyStep.ACRE_REQUESTED]?.finishedAt,
    }, {
      text: 'Créer mon compte impôts.gouv',
      complete: !!state.creation?.steps[CreationCompanyStep.IMPOTS_ACCOUNT_CREATED]?.finishedAt,
    }, ...(process.browser && state.company && (window.$nuxt.$dayjs(state.company.creationDate).year() === 2022 || !state.company.creationDate)
      ? [{
        text: 'Faire ma déclaration initiale de CFE',
        complete: !!state.creation?.steps[CreationCompanyStep.CFE_DECLARED]?.finishedAt,
      }]
      : [])]
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isVerified: (store, getters) => {
    const count = getters.stepsToCheck.length
    const completeCount = getters.stepsToCheck.filter((s:any) => s.complete).length
    return completeCount === count
  },
  paymentMethods: state => state.paymentMethods,
  isPaymentMethodModalOpened: state => state.isPaymentMethodModalOpened,
  isCreationToGestionModalOpened: state => state.isCreationToGestionModalOpened,
  isAskActivityModalOpened: state => state.isAskActivityModalOpened,
  isExtendedTrialModalOpened: state => state.isExtendedTrialModalOpened,
}

export const mutations: MutationTree<CompanyState> = {
  SET_COMPANY: (state, value: ICompany) => {
    state.company = value
  },
  SET_PLANS: (state, value: ReadStripeProduct[]) => {
    state.plans = value
  },
  SET_CREATION: (state, value: ICreationCompany | null) => {
    state.creation = value
  },
  SET_CREATION_FILES: (state, value: ICreationFile[]) => {
    state.creationFiles = value
  },
  SET_COMMERCIAL_TASKS: (state, value: ReadCommercialTask) => {
    state.commercialTasks = value
  },
  SET_IS_EDIT_MODAL_OPENED: (state, value: boolean) => {
    state.isEditModalOpened = value
  },
  SET_IS_ACRE_ELIGIBILITY_MODAL_OPENED: (state, value: boolean) => {
    state.isAcreEligibilityModalOpened = value
  },
  SET_IS_EXTENDED_TRIAL_MODAL_OPENED: (state, value: boolean) => {
    state.isExtendedTrialModalOpened = value
  },
  SET_IS_ASSURANCE_CHOICE_MODAL_OPENED: (state, value: boolean) => {
    state.isAssuranceChoiceModalOpened = value
  },
  SET_IS_FILES_VIEWER_OPENED: (state, value: boolean) => {
    state.isFilesViewerOpened = value
  },
  SET_IS_CREATION_DOCUMENT_VERIFICATION_MODAL_OPEN: (state, value: boolean) => {
    state.isCreationDocumentVerificationModalOpened = value
  },
  SET_IS_CREATION_DOCUMENT_DEPOSIT_MODAL_OPEN: (state, value: boolean) => {
    state.isCreationDocumentDepositModalOpened = value
  },
  SET_IS_COMPLETENESS_TASK_INFO_MODAL_OPEN: (state, value: boolean) => {
    state.isCompletenessTaskInfoModalOpened = value
  },
  SET_FILES: (state, value: ICompanyFile[]) => {
    state.files = value
  },
  SET_FILE: (state, updatedFile: ICompanyFile) => {
    const index : number | undefined = state.files?.findIndex((file: ICompanyFile) => file._id === updatedFile._id)
    if (state.files && (index !== undefined && index !== -1)) {
      state.files[index] = updatedFile
    }
  },
  SET_PAYMENT_METHODS: (state, value: any[] | null) => {
    state.paymentMethods = value
  },
  SET_PAYMENT_METHODS_MODAL_OPEN: (state, value: boolean) => { state.isPaymentMethodModalOpened = value },
  SET_CREATION_TO_GESTION_MODAL_OPENED: (state, value: boolean) => { state.isCreationToGestionModalOpened = value },
  SET_ASK_ACTIVITY_MODAL_OPENED: (state, value: boolean) => { state.isAskActivityModalOpened = value },
  SORT_COMPANY_STRIPE_PRODUCTS (state: CompanyState) {
    if (state.company && state.company.productsCompany) {
      state.company.productsCompany = state.company.productsCompany?.sort((x: any, y: any) => +new Date(y?.createdAt) - +new Date(x?.createdAt))
    }
  },
  RESET (_currentState: CompanyState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<CompanyState, CompanyState> = {

  // TODO: refacto, ne pas ajouter de dispatch
  async fetchCompany ({ commit, dispatch }) {
    const company = await this.$api.company.get()
    commit('SET_COMPANY', company)
    // FIXME obligé de fetch la billing config tout le temps sinon on a des problème d'hydratation notamment dans les settings billing
    await dispatch('billingConfiguration/fetchBillingConfiguration', null, { root: true })
  },
  async fetchCreationFiles ({ commit }) {
    const creationFiles = await this.$api.creationFile.getAll()
    commit('SET_CREATION_FILES', creationFiles)
  },
  async updateCompany ({ commit }, { id, payload }: { id: string, payload: UpdateCompany }) {
    if (payload.products) {
      delete payload.products
    }
    // @ts-ignore
    delete payload.hubspotId
    // @ts-ignore
    delete payload.deletedAt
    const result = await this.$api.company.update(id, payload)
    commit('SET_COMPANY', result)
  },
  async updateCreationCompanyStep ({ commit }, { id, userId, steps }: { id: string } & UpdateCreationCompany) {
    const creationCompany = await this.$api.creationCompany.update(id, { userId, steps })
    commit('SET_CREATION', creationCompany)
  },
  async updateFile ({ commit }, { id, payload }: { id: string, payload: UpdateCompanyFile }) {
    const result = await this.$api.company.updateFile(id, payload)
    commit('SET_FILE', result)
  },
  async fetchCreation ({ commit, getters }, params: { onlyIfNotExists?: boolean }) {
    if (params?.onlyIfNotExists && getters.creation) { return }
    const result = await this.$api.company.getCreation()
    commit('SET_CREATION', result)
  },
  async fetchCommercialTasks ({ commit }) {
    const result = await this.$api.company.getCommercialTasks()
    commit('SET_COMMERCIAL_TASKS', result)
  },
  async fetchFiles ({ commit }) {
    const result = await this.$api.company.getCompanyFiles()
    commit('SET_FILES', result)
  },
  async fetchPlans ({ commit }) {
    const result = await this.$api.company.getPlans()
    commit('SET_PLANS', result)
  },
  setPlans ({ commit }, value: ReadStripeProduct[]) {
    commit('SET_PLANS', value)
  },
  async submitAcre (_, payload: CreateAcreDocument) {
    await this.$api.company.submitAcre(payload)
  },
  async completeCommercialTask ({ commit, state }, payload: CreateCommercialTask) {
    try {
      const result = await this.$api.company.completeCommercialTask({ ...payload, id: state.commercialTasks?._id })
      commit('SET_COMMERCIAL_TASKS', result)
      this.$alertsManager.success('Vous venez de compléter l\'étape sur le choix de l\'assurance')
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async fetchPaymentMethods ({ commit }) {
    const results = await this.$api.stripe.getPaymentMethods()
    commit('SET_PAYMENT_METHODS', results)
  },
  async deletePaymentMethod ({ dispatch }, id: string) {
    await this.$api.stripe.deletePaymentMethod(id)
    dispatch('fetchPaymentMethods')
  },
  setIsCreationDocumentDepositModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_CREATION_DOCUMENT_DEPOSIT_MODAL_OPEN', value)
  },
  setPaymentMethodsModal ({ commit }, value: boolean) {
    commit('SET_PAYMENT_METHODS_MODAL_OPEN', value)
  },
  sortCompanyStripeProducts ({ commit }) {
    commit('SORT_COMPANY_STRIPE_PRODUCTS')
  },
  openAssuranceChoiceModal ({ commit }, value: boolean) {
    commit('SET_IS_ASSURANCE_CHOICE_MODAL_OPENED', value)
  },
  openIsAcreEligibilityModal ({ commit }, value: boolean) {
    commit('SET_IS_ACRE_ELIGIBILITY_MODAL_OPENED', value)
  },
  openIsExtendedModal ({ commit }, value: boolean) {
    commit('SET_IS_EXTENDED_TRIAL_MODAL_OPENED', value)
  },
  openCreationToGestionModalOpened ({ commit, getters }, value: boolean) {
    if (getters.isVerified && value) {
      this.$campaignsManager.register({
        type: CampaignType.CREATION_TO_GESTION,
        trigger: CampaignTrigger.INSTANT,
        priority: 1,
        action: () => {
          commit('SET_CREATION_TO_GESTION_MODAL_OPENED', true)
        },
      })
    } else {
      commit('SET_CREATION_TO_GESTION_MODAL_OPENED', false)
    }
  },
  openAskActivityModal ({ commit }) {
    commit('SET_ASK_ACTIVITY_MODAL_OPENED', true)
  },
  closeAskActivityModal ({ commit }) {
    commit('SET_ASK_ACTIVITY_MODAL_OPENED', false)
  },
}
