
import { Component, Getter, PropSync, Vue } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  PlansConfig,
  SegmentEvent,
  StripeProductFrequency,
  PersonaFeature,
  IBillingConfiguration, ReadPersona, CampaignType, RegisteredType, ABGroupPricing,
} from '@abby/core-legacy'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import { IAuthUser } from '~/store/auth'
import { usePromotionManager } from '~/composables/marketing/usePromotionManager'
import { Promotion } from '~/services/marketing/promotion'
@Component({
  components: { AbbyPlanChip },
  setup () {
    const { getActivePromotion } = usePromotionManager()
    return {
      getActivePromotion,
    }
  },
})
export default class AbbyNewPricingPlan extends Vue {
  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration;

  @Getter('guide/persona') persona!: ReadPersona | null;

  @Getter('auth/user') user!: IAuthUser

  @PropSync('frequency')
  _frequency!: StripeProductFrequency;

  getActivePromotion!: Function

  get activePromotion (): Promotion | undefined {
    return this.getActivePromotion()
  }

  get StripeProductFrequency () {
    return StripeProductFrequency
  }

  get hasDouble2Offer () {
    return !this.$campaignsManager.isDisplayable(CampaignType.DOUBLE_2) && (this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_FREE || this.$planManager.isTrial())
  }

  get AbbyPlans () {
    return AbbyPlans
  }

  get taxText () {
    return 'mois ht'
  }

  selectedPlan: AbbyPlans = AbbyPlans.ABBY_PRO

  get plansToDisplay () {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.plans.filter(p => p.plan === this.selectedPlan)
    }
    return this.plans
  }

  get plansOnPromotion () {
    return this.activePromotion && this.isPromotionDay ? this.activePromotion.plans : []
  }

  get promotionRate () {
    if (this.isPromotionDay && this.activePromotion) {
      return this._frequency === StripeProductFrequency.YEAR ? this.activePromotion.ratioYear : this.activePromotion.ratioMonth
    }
    return 1
  }

  priceAmount (plan: any) {
    if (this._frequency === StripeProductFrequency.YEAR && this.isPromotionDay && this.plansOnPromotion.includes(plan.plan)) {
      return this.$amount.formatCents(plan.pricing[this._frequency].amountByMonth * this.promotionRate).text
    } else if (this._frequency === StripeProductFrequency.MONTH && this.isPromotionDay && this.plansOnPromotion.includes(plan.plan)) {
      return this.$amount.formatCents(plan.pricing[this._frequency].amountByMonth * this.promotionRate).text
    }
    return this.$amount.formatCents(plan.pricing[this._frequency].amountByMonth).text
  }

  get isVP45PromotionActive () {
    return !this.$campaignsManager.isDisplayable(CampaignType.ABBY_BUSINESS_LAUNCH) &&
      this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO &&
      this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
  }

  get isPromotionDay () {
    const isSubBusinessYearly = this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_BUSINESS && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    return !isSubBusinessYearly && this.activePromotion
  }

  get hasTiersPrestation () {
    return this.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || this.billingConfiguration?.tiersPrestationActivated
  }

  get SAPProPlan () {
    return {
      title: 'Offre SAP',
      subtitle: 'Tous les outils pour facturer avec l\'avance immédiate',
      plan: AbbyPlans.ABBY_PRO,
      software: [
        {
          text: 'L\'offre Start',
        },
        {
          text: 'Avance de crédit d\'impôts (API tiers de prestation)',
          highlighted: true,
        },
        {
          text: 'Synchronisation bancaire',
        },
        {
          text: 'Association bancaire et facturation',
        },
        {
          text: 'S’assurer d’être payé dans les temps : Relance d’impayés',
        },
        {
          text: 'Signature des docs. en ligne',
        },
        {
          text: 'Outils de productivité en illimité (suivi du temps, ventes, clients..)',
        },
        {
          text: 'Intégrations aux applications',
        },
        {
          text: 'Facturation récurrente auto.',
        },
        {
          text: 'Facturation, gestion et déclaration TVA',
        },
        {
          text: 'Devises et langues étrangères',
        },
      ],
    }
  }

  get plans () {
    return [{
      title: 'Basique',
      callToAction: 'Je m\'inscris',
      subtitle: 'Facturez vos clients simplement',
      condition: 'Sans engagement',
      plan: AbbyPlans.ABBY_FREE,
      free: true,
      pricing: {
        [StripeProductFrequency.MONTH]: { text: 'Gratuit' },
        [StripeProductFrequency.YEAR]: { text: 'Gratuit' },
      },
      software: [
        {
          text: 'Devis & factures illimités',
        },
        {
          text: 'Livre des recettes',
        },
        {
          text: 'Livre des achats',
        },
        {
          text: 'Estimation CA et cotisations Urssaf',
        },
      ],
      support: [
        {
          text: 'Support limité',
        },
      ],
    }, {
      title: 'Commencer',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Mettez en valeur votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_START,
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_START].pricing[this.$planManager.abGroupPricing()],
      },
      software: [
        {
          text: 'L\'offre Basique',
        },
        {
          text: 'Documents personnalisés',
        },
        {
          text: 'Retirer le logo Abby des factures',
        },
        {
          text: 'Paiement en ligne des factures',
        },
        {
          text: 'Facturation avec acompte',
        },
        {
          text: 'Déclaration et paiement Urssaf',
        },
        {
          text: 'Rappel Urssaf, Impôts et CFE',
        },
        {
          text: 'Mode archive des documents',
        },
        {
          text: 'Envoi des documents par e-mail',
        },
      ],
      support: [
        {
          text: 'Support basique',
        },
      ],
    }, {
      title: 'Maitriser',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Travaillez l’esprit libre avec un environnement tout-en-un',
      condition: '14 jours offerts, sans engagement',
      popular: true,
      plan: AbbyPlans.ABBY_PRO,
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_PRO].pricing[this.$planManager.abGroupPricing()],
      },
      software: [
        {
          text: 'L\'offre Start',
        },
        {
          text: 'Connexion bancaire',
        },
        {
          text: 'Association bancaire et facturation',
        },
        {
          text: 'S’assurer d’être payé dans les temps : Relance d’impayés',
        },
        {
          text: 'Signature des docs. en ligne',
        },
        {
          text: 'Outils de productivité en illimité (suivi du temps, ventes, clients..)',
        },
        {
          text: 'Intégrations aux applications',
        },
        {
          text: 'Facturation récurrente auto.',
        },
        {
          text: 'Facturation, gestion et déclaration TVA',
        },
        {
          text: 'Avance de crédit d\'impôts (API tiers de prestation)',
        },
      ],
      support: [
        {
          text: 'Support basique',
        },
      ],
      ...this.hasTiersPrestation
        ? this.SAPProPlan
        : {},
    }, {
      title: 'Aller plus loin',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Utilisez Abby sans limite pour assurer la croissance de votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_BUSINESS,
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_BUSINESS].pricing[this.$planManager.abGroupPricing()],
      },
      software: [
        {
          text: 'L\'offre Pro',
        },
        {
          text: 'Gestion des stocks',
        },
        {
          text: 'Déclaration 2042 C PRO',
        },
        {
          text: 'Crédits de signatures illimités',
        },
        {
          text: 'Connexion bancaire illimitée',
        },
        {
          text: 'Certification des justificatifs',
        },
        {
          text: 'Devises et langues étrangères',
        },
        {
          text: 'Déclaration NOVA et attestation fiscale en un clic',
          highlighted: this.hasTiersPrestation,
        },
        {
          text: 'Gestion multi-utilisateurs',
        },
        {
          text: 'Accès expert-comptable',
        },
        {
          text: 'Gain de parrainage x2,5',
        },
      ],
      support: [
        {
          text: 'Support prioritaire',
        },
        {
          text: 'Chat et rendez-vous en visio avec votre conseiller',
        },
      ],
    }]
  }

  setFrequency (value: StripeProductFrequency) {
    this.$emit('update:frequency', value)
  }
}
