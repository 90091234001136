export enum ProductType{
  SALE_OF_GOODS = 1,
  SERVICE_DELIVERY = 2,
  COMMERCIAL_OR_CRAFT_SERVICES = 3,
  SALE_OF_MANUFACTURED_GOODS = 4,
  DISBURSEMENT = 5,
}

export enum ProductUnit{
  UNITE = 14,
  GRAMME = 1,
  HEURE = 2,
  JOUR = 3,
  FORFAIT = 22,
  ANNEE = 23,
  CARACTERE = 24,
  LIGNE = 25,
  LICENCE = 26,
  ARTICLE = 21,
  MOIS = 20,
  KILOGRAMME = 4,
  KILOMETRE = 5,
  LITRE = 6,
  LOT = 7,
  METRE = 8,
  METRE_CARRE = 9,
  METRE_CUBE = 10,
  METRE_LINEAIRE = 11,
  PERSONNE = 12,
  TONNE = 13,
  MOT = 15,
  PAGE = 16,
  FEUILLET = 17,
  PARAGRAPHE = 18,
  MINUTE = 19,
  SEMAINE = 27,
}
