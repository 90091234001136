import { BillingState, BillingType } from '../../../types/Enum/BillingConfiguration';
import { LegalStatus } from '../../../types/Enum/LegalStatus';

import { BillingDocumentPDFData, BillingTemplate } from '../../../types/Interface/api/pdf/PDF';

import {
  BillingLocalText, BillingText, GenerateBillingPdfOptions, injectItem,
} from './billing.utils';

const getIcon = (icon: string, link: string) => ({
  link,
  text: icon,
  font: 'Fontello',
  style: 'primary',
  fontSize: 16,
  width: 20,
});

const recipient = (value: BillingDocumentPDFData['recipient'], template: BillingTemplate, localText: BillingLocalText) => [
  ...injectItem(!!value.name, {
    text: value.name,
    bold: true,
    style: 'body',
    margin: [0, template !== BillingTemplate.TEMPLATE_3 ? 30 : 10, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.commercialName && value.commercialName !== value.name, {
    text: value.commercialName,
    bold: true,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.recipientCompanyContactName && value.hasCompany, {
    text: value.recipientCompanyContactName,
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.address, {
    text: value.address,
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.additionalAddress, {
    text: value.additionalAddress,
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.zipCode || !!value.city || !!value.country, {
    text: `${value.zipCode || ''} ${value.city || ''} ${value.country || ''}`.trim(),
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(value.hasCompany && !!value.siret, {
    text: `${localText[BillingText.SIRET_NUMBER]} : ${value.siret}`,
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(value.hasCompany && !!value.vatNumber, {
    text: `${localText[BillingText.VAT_NUMBER]} : ${value.vatNumber}`,
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.deliveryAddress && value.displayDeliveryAddress, {
    text: `\n${localText[BillingText.DELIVERY_ADDRESS]}`,
    bold: true,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.deliveryAddress && value.displayDeliveryAddress, {
    text: value.deliveryAddress,
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem(!!value.deliveryAdditionalAddress && !!value.deliveryAddress && value.displayDeliveryAddress, {
    text: value.deliveryAdditionalAddress,
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
  ...injectItem((!!value.deliveryZipCode || !!value.deliveryCity) && !!value.deliveryAddress && value.displayDeliveryAddress, {
    text: `${value.deliveryZipCode || ''} ${value.deliveryCity || ''} ${value.deliveryCountry || ''}`.trim(),
    bold: false,
    style: 'body',
    margin: [0, 0, 0, 2],
    alignment: template === BillingTemplate.TEMPLATE_1 ? 'left' : 'right',
  }),
];

const stateTag: { [K in BillingState]?: (localText: BillingLocalText) => { lineColor: string, color: string, text: string } } = {
  [BillingState.PAID]: (localText: BillingLocalText) => ({
    lineColor: '#D6F7EB',
    color: '#1CB158',
    text: localText[BillingText.PAID],
  }),
  [BillingState.DRAFT]: (localText: BillingLocalText) => ({
    lineColor: '#F3F4F6',
    color: '#5A7294',
    text: localText[BillingText.DRAFT],
  }),
  [BillingState.SIGNED]: (localText: BillingLocalText) => ({
    lineColor: '#D6F7EB',
    color: '#1CB158',
    text: localText[BillingText.SIGNED],
  }),
  [BillingState.REFUSED]: (localText: BillingLocalText) => ({
    lineColor: '#FBDCE2',
    color: '#E94E6E',
    text: localText[BillingText.REFUSED],
  }),
};

const makeTag = (billingState: BillingState, localText: BillingLocalText, width = 45, height = 15, offset = 13) => {
  const tag = stateTag[billingState]?.(localText);
  if (!tag) { return []; }
  const canvas = {
    type: 'rect',
    x: 0,
    y: 0,
    w: width,
    h: height,
    r: 3,
    color: tag.lineColor,
    lineColor: tag.lineColor,
  };
  const column = {
    text: tag.text,
    width,
    color: tag.color,
    alignment: 'center',
    noWrap: true,
    bold: true,
  };
  return [{
    stack: [
      { canvas: [canvas] },
      {
        columns: [column],
        relativePosition: { y: -offset },
        style: { fontSize: 9, bold: true },
      },
    ],
  }];
};

const billingDates = (options: BillingDocumentPDFData, localText: BillingLocalText, template: BillingTemplate) => ([
  ...injectItem(!!options.date, [
    {
      text: localText[BillingText.EMISSION_DATE],
      border: [false, false, false, false],
      alignment: template !== BillingTemplate.TEMPLATE_3 ? 'left' : 'right',
      style: ['body', 'grey'],
      bold: true,
    },
    {
      bold: true,
      style: 'body',
      text: options.date,
    },
  ]),
  ...injectItem(!!options.billingType && [BillingType.INVOICE, BillingType.ADVANCE].includes(options.billingType) && !!options.dueDate, [
    {
      text: localText[BillingText.PAYMENT_DUE_DATE],
      border: [false, false, false, false],
      alignment: template !== BillingTemplate.TEMPLATE_3 ? 'left' : 'right',
      style: ['body', 'grey'],
      bold: true,
    },
    {
      bold: true,
      style: 'body',
      text: options.dueDate,
    },
  ]),
  ...injectItem(options.billingType === BillingType.ESTIMATE, [
    {
      text: localText[BillingText.VALIDITY_DATE],
      border: [false, false, false, false],
      alignment: template !== BillingTemplate.TEMPLATE_3 ? 'left' : 'right',
      style: ['body', 'grey'],
      bold: true,
    },
    {
      bold: true,
      style: 'body',
      text: options.validityDate,
    },
  ]),
  ...injectItem(!!options.deliveryDate, [
    {
      text: localText[BillingText.DELIVERY_DATE],
      border: [false, false, false, false],
      alignment: template !== BillingTemplate.TEMPLATE_3 ? 'left' : 'right',
      style: ['body', 'grey'],
      bold: true,
    },
    {
      bold: true,
      style: 'body',
      text: options.deliveryDate,
    },
  ]),
  ...injectItem(!!options.paidDate, [
    {
      text: localText[BillingText.PAYMENT_DATE],
      border: [false, false, false, false],
      alignment: template !== BillingTemplate.TEMPLATE_3 ? 'left' : 'right',
      style: ['body', 'grey'],
      bold: true,
    },
    {
      bold: true,
      style: 'body',
      text: options.paidDate,
    },
  ]),
]);

const convertPxToPt = (px: number) => Math.round(px * 0.75292857248934);
const generateLogo = (logo: GenerateBillingPdfOptions['logo']) => {
  if (logo.isPreset) {
    return {
      image: 'logo',
      background: '#fff',
      width: 70,
    };
  }
  const desiredHeight = convertPxToPt(logo.dimensions.height);
  const outputHeight = desiredHeight > 60 ? 60 : desiredHeight;
  return {
    image: 'logo',
    height: outputHeight,
    width: (outputHeight * convertPxToPt(logo.dimensions.width)) / desiredHeight,
  };
};

const headers = {
  [BillingTemplate.TEMPLATE_1]: (documentTypeText: string, options: BillingDocumentPDFData, logo: GenerateBillingPdfOptions['logo'], localText: BillingLocalText) => ([
    [
      {
        text: documentTypeText,
        style: 'primary',
        width: '*',
        fontSize: 25,
        bold: true,
        alignment: 'left',
        margin: [0, 0, 0, 0],
      },
      {
        width: 'auto',
        alignment: 'left',
        layout: 'noBorders',
        margin: [0, 0, 0, 20],
        table: {
          body: [
            [{
              style: 'grey',
              fontSize: 15,
              bold: true,
              text: options.billingState === BillingState.DRAFT ? localText[BillingText.DRAFT_DOCUMENT] : options.preRenderNumber || options.number ? `N° ${options.preRenderNumber || options.number}` : localText[BillingText.DRAFT_DOCUMENT],
            }, ...makeTag(options.billingState || BillingState.DRAFT, localText, 45, 15, 12)],
          ],
        },
      },
    ],
    ...injectItem(!!options.logo, () => [generateLogo(logo)]),
  ]),
  [BillingTemplate.TEMPLATE_2]: (documentTypeText: string, options: BillingDocumentPDFData, logo: GenerateBillingPdfOptions['logo'], localText: BillingLocalText) => ([
    ...injectItem(!!options.logo, () => [generateLogo(logo)]),
    [
      {
        text: documentTypeText,
        style: 'primary',
        width: '*',
        fontSize: 25,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            alignment: 'right',
            layout: 'noBorders',
            table: {
              body: [
                [{
                  style: 'grey',
                  fontSize: 15,
                  bold: true,
                  text: options.billingState === BillingState.DRAFT ? localText[BillingText.DRAFT_DOCUMENT] : options.number || localText[BillingText.DRAFT_DOCUMENT],
                }, ...makeTag(options.billingState || BillingState.DRAFT, localText, 45, 15, 12)],
              ],
            },
          },
        ],
      },
    ],
  ]),
  [BillingTemplate.TEMPLATE_3]: (documentTypeText: string, options: BillingDocumentPDFData, logo: GenerateBillingPdfOptions['logo'], localText: BillingLocalText) => ([
    ...injectItem(!!options.logo, () => [generateLogo(logo)]),
    [
      {
        text: documentTypeText,
        style: 'primary',
        width: '*',
        fontSize: 25,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 0],
      },
      recipient(options.recipient, options.template, localText),
    ],
  ]),
};

export const header = (documentTypeText: string, options: BillingDocumentPDFData, logo: GenerateBillingPdfOptions['logo'], localText: BillingLocalText) => {
  const { emitter, social, template } = options;
  const tradLegalStatus = {
    [LegalStatus.MICRO_ENTREPRISE]: ' EI',
    [LegalStatus.EI]: ' EI',
    [LegalStatus.SAS]: ' SAS',
    [LegalStatus.SARL]: ' SARL',
    [LegalStatus.SASU]: ' SASU',
    [LegalStatus.EURL]: ' EURL',
    [LegalStatus.EIRL]: ' EIRL',
    [LegalStatus.SA]: ' SA',
    [LegalStatus.SNC]: ' SNC',
    [LegalStatus.SASU_SAS]: '',
    [LegalStatus.EURL_SARL]: '',
    [LegalStatus.SCI]: ' SCI',
    [LegalStatus.ASSOCIATION]: ' Association',
    [LegalStatus.OTHER]: '',
  };
  const suffix = emitter.legalStatus && options.displayLegalStatus ? tradLegalStatus[emitter.legalStatus] : '';
  const hasSocial = social?.facebook || social?.instagram || social?.linkedin || social?.website;
  return [
    {
      columns: headers[template](documentTypeText, options, logo, localText),
    },
    ...(template === BillingTemplate.TEMPLATE_3 ? [{
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 0,
          x2: 546,
          y2: 0,
          lineWidth: 2,
          lineColor: '#E7E7F3',
        },
      ],
      margin: [0, 10, 0, 10],
    }] : []),
    {
      ...(options.template === BillingTemplate.TEMPLATE_3 ? {
        border: [false, true, false, false],
      } : {}),
      columns: [
        [
          {
            ...injectItem(options.template === BillingTemplate.TEMPLATE_2, () => ({
              margin: [0, 10, 0, 0],
            })),
            text: `${emitter.name || emitter.commercialName}${suffix}`,
            bold: true,
            style: 'body',
            alignment: 'left',
          },
          ...injectItem(!!emitter.commercialName && emitter.commercialName !== emitter.name && (emitter.name || emitter.commercialName) !== emitter.commercialName, {
            text: `${emitter.commercialName}${!emitter.name ? suffix : ''}`,
            bold: true,
            style: 'body',
            alignment: 'left',
          }),
          ...injectItem(options.displayFullName && !!emitter.fullName, {
            text: emitter.fullName,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(options.displayEmail && !!emitter.email, {
            text: emitter.email,
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(options.displayPhoneNumber && !!emitter.phone, {
            text: emitter.phone,
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(!!emitter.address, {
            text: emitter.address,
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(!!emitter.additionalAddress, {
            text: emitter.additionalAddress,
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(!!emitter.zipCode || !!emitter.city || !!emitter.country, {
            text: `${emitter.zipCode || ''} ${emitter.city || ''} ${emitter.country || ''}`.trim(),
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          {
            text: emitter.siret ? `${localText[BillingText.SIRET_NUMBER]} : ${emitter.siret}` : localText[BillingText.SIRET_ATTRIBUTION],
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          },
          ...injectItem(!!emitter.sapAgreement, {
            text: `${localText[BillingText.APPROVAL]} : ${emitter.sapAgreement}`,
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(!!emitter.vatNumber, {
            text: `${localText[BillingText.VAT_NUMBER]} : ${emitter.vatNumber}`,
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(!!emitter.rcsNumber, {
            text: `${localText[BillingText.RCS_NUMBER]} : ${emitter.rcsNumber}`,
            bold: false,
            style: 'body',
            alignment: 'left',
            margin: [0, 2, 0, 0],
          }),
          ...injectItem(!!hasSocial && template !== BillingTemplate.TEMPLATE_1, {
            margin: [0, 5, 0, 0],
            columns: [
              ...injectItem(!!social?.facebook?.length, getIcon('f', social?.facebook || '')),
              ...injectItem(!!social?.linkedin?.length, getIcon('l', social?.linkedin || '')),
              ...injectItem(!!social?.website?.length, getIcon('w', social?.website || '')),
              ...injectItem(!!social?.instagram?.length, getIcon('i', social?.instagram || '')),
            ],
          }),
          ...injectItem(options.template !== BillingTemplate.TEMPLATE_3, [
            { text: '', margin: [0, 5, 0, 0] },
            {
              layout: 'noBorders',
              style: 'baseColor',
              table: {
                widths: ['auto', '*'],
                body: [
                  ...billingDates(options, localText, options.template),
                ],
              },
            },
          ]),
        ],
        ...injectItem(options.template !== BillingTemplate.TEMPLATE_3, () => ([
          recipient(options.recipient, template, localText),
        ])),
        ...injectItem(options.template === BillingTemplate.TEMPLATE_3, [{
          layout: 'noBorders',
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              ...injectItem(options.billingState !== BillingState.FINALIZED, [
                {
                  text: localText[BillingText.STATUS],
                  border: [false, false, false, false],
                  alignment: 'right',
                  margin: [0, 2, 0, 0],
                  style: ['body', 'grey'],
                  bold: true,
                },
                ...makeTag(options.billingState || BillingState.DRAFT, localText, 45, 12, 10)]),
              [
                {
                  text: localText[BillingText.DOCUMENT_NUMBER],
                  border: [false, false, false, false],
                  alignment: 'right',
                  style: ['body', 'grey'],
                  bold: true,
                },
                {
                  bold: true,
                  style: 'body',
                  text: options.preRenderNumber ? `${options.preRenderNumber} provisoire` : (options.number || 'provisoire'),
                }],
              ...billingDates(options, localText, options.template),
            ],
          },
        },
        ]),
      ],
    },
    { text: '', margin: [0, 5, 0, 0] },
  ];
};
