import { styles } from './style';
import { header } from './header';
import { lines } from './lines';
import { total } from './total';
import { footer } from './footer';
import { background } from './background';
import { BillingDocumentPDFData, BillingTemplate, GeneratePdfOptions } from '../../../types/Interface/api/pdf/PDF';

export const injectItem = (condition: boolean, item: any) => {
  if (typeof item === 'function') {
    return condition ? item() : [];
  }
  return condition ? [item] : [];
};

export const preprocessPDFMakeHTML = (html: string) => html
  .replace(/data-type="taskList"/gm, `data-pdfmake='${JSON.stringify({ type: 'taskList' })}'`)
  .replace(/<p/g, '<p style="white-space:break-spaces"')
  .replace(/checked="checked"/g, `data-pdfmake='${JSON.stringify({ type: 'checked' })}'`);

export const postprocessPDFMakeHTML = (data: any[]) => {
  const output = [];
  for (let i = 0; i < data.length; i += 1) {
    const result = data[i];
    if (result.type === 'taskList') {
      const { ul, ..._result } = result;
      const taskList = {
        ..._result,
        stack: ul.map((ulItem: any) => ({
          text: [{ text: ulItem?.stack?.[0]?.stack?.[0]?.text?.[0]?.type === 'checked' ? 'c' : 'e', font: 'Fontello', fontSize: 8 }, { text: ' ' }, ...ulItem.stack[1].stack],
        })),
      };
      output.push(taskList);
    } else if (result.nodeName === 'P' && result.text === '') {
      output.push({
        ...result,
        text: ' ',
      });
    } else {
      output.push(result);
    }
  }
  return output;
};

export enum BillingText {
  EMISSION_DATE = 'emissionDate',
  SIRET_NUMBER = 'siretNumber',
  SIRET_ATTRIBUTION = 'siretAttribution',
  APPROVAL = 'approval',
  VAT_NUMBER = 'vatNumber',
  RCS_NUMBER = 'rcsNumber',
  STATUS = 'status',
  DOCUMENT_NUMBER = 'documentNumber',
  PAYMENT_DUE_DATE = 'paymentDueDate',
  VALIDITY_DATE = 'validityDate',
  DELIVERY_DATE = 'deliveryDate',
  DELIVERY_ADDRESS = 'deliveryAddress',
  PAYMENT_DATE = 'paymentDate',
  DRAFT_DOCUMENT = 'draftDocument',
  PAID = 'paid',
  DRAFT = 'draft',
  SIGNED = 'signed',
  REFUSED = 'refused',
  PAYMENT_TERM = 'paymentTerm',
  LATE_PAYMENT_PENALTY = 'latePaymentPenalty',
  FLAT_RATE_COLLECTION_FEES = 'flatRateCollectionFees',
  DISCOUNT_ADVANCE_PAYMENT = 'discountAdvancePayment',
  PAYMENT_METHODS = 'paymentMethod',
  BANK_DETAILS = 'bankDetails',
  BANK = 'bank',
  TOTAL_WITH_OUT_TAX = 'totalWithoutTax',
  TOTAL_WITH_TAX = 'totalWithTax',
  FINAL_TOTAL_WITH_OUT_TAX = 'finalTotalWithoutTax',
  TOTAL_DISCOUNT = 'totalDiscount',
  VAT = 'vat',
  SIGN_DOCUMENT_ONLINE = 'signDocumentOnline',
  GO_TO_DOCUMENT_SIGNATURE_PAGE = 'goToDocumentSignaturePage',
  PAY_DOCUMENT_ONLINE = 'payDocumentOnline',
  GO_TO_DOCUMENT_PAYMENT_PAGE = 'goToDocumentPaymentPage',
  WHICH = 'which',
  GOOD_FOR_APPROVAL = 'goodForApproval',
  SIGNED_AT = 'signedAt',
  TO = 'to',
  ADVANCES_PAID = 'advancesPaid',
  REMAINING_TOTAL_WITH_OUT_TAX = 'remainingTotalWithoutTax',
  REMAINING_TOTAL_WITH_TAX = 'remainingTotalWithTax',
  APPLIED_EXCHANGE_RATE = 'appliedExchangeRate',
}

export type BillingLocalText = {
  [K in BillingText]: string
};

export type GenerateBillingPdfOptions = {
  memberOfAnApprovedAssociation: string,
  data: BillingDocumentPDFData,
  localeText: BillingLocalText,
  documentTypeText: string,
  options: GeneratePdfOptions['options'],
  columns: { text: string, type: string }[],
  logo: { image: string, isPreset: boolean, dimensions: { width: number, height: number } },
  defaultLogo: string,
  base64Shape?: string,
  footerLogos: { image: string, dimensions: { width: number, height: number } }[],
  isLogoSVG?: boolean
  products: BillingDocumentPDFData['products']
};

export const generateBillingPdf = ({
  data,
  columns,
  logo,
  defaultLogo,
  base64Shape,
  products,
  documentTypeText,
  localeText,
  footerLogos,
  memberOfAnApprovedAssociation,
  isLogoSVG,
  options,
}: GenerateBillingPdfOptions) => ({
  ...(options?.specimen ? {
    watermark: { text: 'SPECIMEN', bold: true, opacity: 0.2 },
  } : {}),
  styles: styles(data.style),
  content: [
    ...header(documentTypeText, data, logo, localeText),
    ...injectItem(!!data.headerNote, () => ([
      data.headerNote,
      '\n',
    ])),
    ...lines(columns, products, data),
    total(data, localeText),
    ...injectItem(!!data.mentionMediator, () => ([
      data.mentionMediator,
      '\n',
    ])),
    '\n',
    ...injectItem(!!data.footerNote, () => ([data.footerNote])),
    ...injectItem(data.displayMemberOfAnApprovedAssociation, {
      margin: [0, 5, 0, 0],
      fontSize: 8,
      style: 'baseColor',
      unbreakable: true,
      text: memberOfAnApprovedAssociation,
    }),
    ...injectItem(!!data.generalTermsAndConditionsOfSale, () => ([
      {
        pageBreak: 'before',
        text: '',
      },
      data.generalTermsAndConditionsOfSale,
    ])),
  ],
  images: {
    ...logo ? {
      logo: logo.image,
    } : {},
  },
  footer: (currentPage: number, pageCount: number) => footer(currentPage, pageCount),
  background: (currentPage: number, pageSize: { width: number, height: number }) => background(currentPage,
    pageSize,
    footerLogos,
    logo && data.displayAbbyLogo ? defaultLogo : null,
    isLogoSVG,
    data.template !== BillingTemplate.TEMPLATE_1 ? base64Shape : null),
  pageBreakBefore(currentNode: any, followingNodesOnPage: any) {
    return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
  },
  pageMargins: [25, base64Shape ? 60 : 17.5, 25, 50],
});
