import { AbbyPlans } from '@abby/core-legacy'
import { useContext } from '@nuxtjs/composition-api'
import { Promotion } from '~/services/marketing/promotion'

export const usePromotionManager = () => {
  const { $planManager } = useContext()

  const promotions = [
    {
      title: 'Black Friday',
      promoCode: 'BF40',
      promoText: '-40%',
      labelYear: '-40% sur l\'année',
      labelMonth: '-40% sur 3 mois',
      labelYearMobile: '-40% annuel',
      labelMonthMobile: '-40% sur 3 mois',
      ratioYear: 0.60,
      ratioMonth: 0.60,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-11-25'),
      endDate: new Date('2024-11-28T23:59:50'),

      bannerApp: {
        title: 'Black Friday sur Abby',
        subtitle: '<b>-40% sur tous les abonnements</b>',
        mobileTitle: '<b>-40% sur tous les abonnements</b>',
        miniTitle: '<b>-40% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Profitez du Black Friday sur Abby',
        subtitle: 'Tous nos abonnements à partir de -40%',
        promotionText: '-40 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Black Friday -40%',
    },
    {
      title: 'Black Friday',
      promoCode: 'BF45',
      promoText: '-45%',
      labelYear: '-45% sur l\'année',
      labelMonth: '-45% sur 3 mois',
      labelYearMobile: '-45% annuel',
      labelMonthMobile: '-45% sur 3 mois',
      ratioYear: 0.55,
      ratioMonth: 0.55,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-11-29'),
      endDate: new Date('2024-12-02T23:59:50'),

      bannerApp: {
        title: 'Black Friday sur Abby (c\'est le dernier jour)',
        subtitle: '<b>-45% sur tous les abonnements</b>',
        mobileTitle: '<b>-45% sur tous les abonnements</b>',
        miniTitle: '<b>-45% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Profitez du Black Friday sur Abby',
        subtitle: 'Tous nos abonnements à partir de -45%',
        promotionText: '-45 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Black Friday -45%',
    },
    {
      title: 'Black Friday',
      promoCode: 'BF35',
      promoText: '-35%',
      labelYear: '-35% sur l\'année',
      labelMonth: '-35% sur 3 mois',
      labelYearMobile: '-35% annuel',
      labelMonthMobile: '-35% sur 3 mois',
      ratioYear: 0.60,
      ratioMonth: 0.60,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-12-03'),
      endDate: new Date('2024-12-09T23:59:50'),

      bannerApp: {
        title: 'Dernière promo',
        subtitle: '<b>-35% sur tous les abonnements</b>',
        mobileTitle: '<b>-35% sur tous les abonnements</b>',
        miniTitle: '<b>-35% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Profitez du Black Friday sur Abby',
        subtitle: 'Tous nos abonnements à partir de -35%',
        promotionText: '-35 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Black Friday -35%',
    },
    {
      title: 'Promotion de Noël',
      promoCode: 'NOEL25',
      promoText: '-25%',
      labelYear: '-25% sur l\'année',
      labelMonth: '-25% sur 3 mois',
      labelYearMobile: '-25% annuel',
      labelMonthMobile: '-25% sur 3 mois',
      ratioYear: 0.75,
      ratioMonth: 0.75,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-12-16'),
      endDate: new Date('2024-12-31T23:59:50'),

      bannerApp: {
        title: 'Promo de Noël',
        subtitle: '<b>-25% sur tous les abonnements</b>',
        mobileTitle: '<b>-25% sur tous les abonnements</b>',
        miniTitle: '<b>-25% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Profitez de Noël sur Abby',
        subtitle: 'Tous nos abonnements à -25%',
        promotionText: '-25 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Noël -25%',
    },
  ] as Promotion[]

  const seasonalPromotions = [
    {
      title: 'Promotions de Printemps',
      promoCode: 'SPRINGSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2025-03-20'),
      endDate: new Date('2025-06-20T23:59:50'),

      bannerApp: {
        title: 'Promotions de Printemps',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre de Printemps sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo de Printemps -20%',
    },
    {
      title: 'Promotions d\'Été',
      promoCode: 'SUMMERSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2025-06-21'),
      endDate: new Date('2025-09-21T23:59:50'),

      bannerApp: {
        title: 'Promotions d\'Été',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre d\'Été sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo d\'Été -20%',
    },
    {
      title: 'Promotions d\'Automne',
      promoCode: 'AUTUMNSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-09-22'),
      endDate: new Date('2024-12-20T23:59:50'),

      bannerApp: {
        title: 'Promotions d\'Automne',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre d\'Automne sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo d\'Automne -20%',
    },
    {
      title: 'Promotions d\'Hiver',
      promoCode: 'WINTERSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-12-21'),
      endDate: new Date('2025-03-19T23:59:50'),

      bannerApp: {
        title: 'Promotions d\'Hiver',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre d\'Hiver sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo d\'Hiver -20%',
    },
  ] as Promotion[]

  const getActivePromotion = (): Promotion | undefined => {
    const now = new Date()
    const isDateBetween = (date: Date, startDate: Date, endDate: Date): boolean => {
      return date >= startDate && date <= endDate
    }
    const promotion = promotions.find(p => isDateBetween(now, p.startDate, p.endDate))
    if (promotion) { return promotion }
    return seasonalPromotions.find(p => isDateBetween(now, p.startDate, p.endDate))
  }

  return {
    getActivePromotion,
  }
}
