import { Plugin } from '@nuxt/types'

export interface MixpanelInstance {
  init: () => void;
}

export const MixpanelPlugin: Plugin = ({ store, $site }, inject) => {

  const waitUntilMixpanelIsSet = (callback?: () => any) => {
    if (!process.browser) { return }
    // @ts-ignore
    if (typeof window?.mixpanel !== 'undefined') {
      callback?.()
    } else {
      setTimeout(() => { waitUntilMixpanelIsSet(callback) }, 1000)
    }
  }

  const init = () => {
    if (store.getters['auth/connectedFromDashboard'] || $site.isDashboard || (window as any).mixpanel) { return }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = '/scripts/mixpanel.js'
    const firstScript: any = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
    waitUntilMixpanelIsSet(() => {
      // @ts-ignore
      window?.mixpanel?.init(
        process.env.NODE_ENV === 'production' ? '702b55977f47ba09b09f357b787de407' : '9741558844a01ef1f8b696927b762f68',
        {
          record_sessions_percent: 1, // records 1% of all sessions
          record_mask_text_selector: '',
        },
      )
    })

  }

  inject('mixpanel', {
    init,
  })
}
export default MixpanelPlugin

declare module 'vue/types/vue' {
  interface Vue {
    $mixpanel: MixpanelInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $mixpanel: MixpanelInstance
  }
  interface Context {
    $mixpanel: MixpanelInstance
  }
}

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $mixpanel: MixpanelInstance
  }
}
