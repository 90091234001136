
import { Component, Vue } from 'nuxt-property-decorator'
import { AbbyPlans, PlanFeature, StripeProductType } from '@abby/core-legacy'

@Component({
  components: { },
})
export default class AbbyProAction extends Vue {
  get isTrial () {
    return this.$planManager.isTrial()
  }

  get isPaidAndMonthly () {
    return [AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_START].includes(this.$planManager.whichPlanCompanyHas()) && this.$planManager.whichFrequencyPlanCompanyHas() === 'month'
  }

  get isTrialExpired () {
    return this.$planManager.isTrialExpired()
  }

  openAbbyPlansModal () {
    this.$planManager.openAbbyPlansModal({
      feature: 'action' as PlanFeature,
      openPlan: true,
    })
  }

  get remainingDays () {
    const plan = this.$planManager.companyPlan()
    if (!plan) {
      return null
    }
    const days = this.$dayjs(plan.extendedTrialAt || plan.trialAt).toNow(true)
    return {
      days,
    }
  }
}
