import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { PartnerChoiceStep } from '@abby/core-legacy'
import type {
  IPartner, IService,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface ServiceState {
  services: IService[];
  selectedService: IService | null;
  selectedPartner: IPartner | null;
  currentPartnerStep: PartnerChoiceStep;
  loading: boolean;
  isPartnersModalOpened: boolean;
}

export const state = (): ServiceState => ({
  services: [],
  selectedService: null,
  selectedPartner: null,
  currentPartnerStep: PartnerChoiceStep.LIST,
  loading: false,
  isPartnersModalOpened: false,
})

export const getters: GetterTree<ServiceState, RootState> = {
  services: state => state.services,
  selectedService: state => state.selectedService,
  selectedPartner: state => state.selectedPartner,
  currentPartnerStep: state => state.currentPartnerStep,
  loading: state => state.loading,
  isPartnersModalOpened: state => state.isPartnersModalOpened,
}

export const mutations: MutationTree<ServiceState> = {
  SET_SERVICES (state, value: IService[]) {
    state.services = value
  },
  SET_SELECTED_SERVICE (state, value: IService | null) {
    state.selectedService = value
  },
  SET_SELECTED_PARTNER (state, value: IPartner | null) {
    state.selectedPartner = value
  },
  SET_CURRENT_PARTNER_STEP (state, value: PartnerChoiceStep) {
    state.currentPartnerStep = value
  },
  SET_LOADING (state, value: boolean) {
    state.loading = value
  },
  SET_IS_PARTNERS_MODAL_OPENED (state, value: boolean) {
    state.isPartnersModalOpened = value
  },
}

export const actions: ActionTree<ServiceState, RootState> = {
  async findServices ({ commit }: any) {
    commit('SET_LOADING', true)
    try {
      const result = await this.$api.services.getAll({
        populate: [
          'thumbnail.*',
          'icon.*',
          'partners.*',
          'partners.logo.*',
          'partners.categoryLogo.*',
          'partners.advantages.*',
          'partners.advantages.icon.*',
        ],
      }) as IService[]
      commit('SET_SERVICES', result)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async findPartner ({ state, commit }: any, slug: string) {
    commit('SET_LOADING', true)
    try {
      const result = await this.$api.services.getPartner({
        populate: [
          '*',
          'logo.*',
          'categoryLogo.*',
          'advantages.*',
          'advantages.icon.*',
        ],
        filters: {
          slug: {
            $eq: slug,
          },
        },
      }) as IPartner
      commit('SET_SELECTED_PARTNER', result)
      if (state.selectedPartner) {
        commit('SET_CURRENT_PARTNER_STEP', PartnerChoiceStep.LIST)
        commit('SET_IS_PARTNERS_MODAL_OPENED', true)
      }
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    } finally {
      commit('SET_LOADING', false)
    }
  },
}
