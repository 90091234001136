
import Vue from 'vue'
import { Component, Getter, Watch } from 'nuxt-property-decorator'

import { CampaignType, ReadUrssafJourneyDocument, Regime, UrssafRateType, UrssafInformationCase } from '@abby/core-legacy'
import Modal from '~/components-legacy/layout/containers/Modal.vue'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import AsListSelection from '~/components-legacy/inputs/ASListSelection.vue'
import Link from '~/components-legacy/utils/Link.vue'
import AbbyAlert from '~/components/abby/messaging/AbbyAlert.vue'
import ASDatePicker from '~/components-legacy/inputs/ASDatePicker.vue'
import ASChoiceInput from '~/components-legacy/inputs/ASChoiceInput.vue'
import { CompanyState } from '~/store/company'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import { UrssafState } from '~/store/urssaf'

enum Activity {
  MICRO_BNC = 'MICRO_BNC',
  MICRO_BIC = 'MICRO_BIC',
  MICRO_BNC_BIC = 'MICRO_BNC_BIC',
  OTHER = 'OTHER',
}

@Component({
  components: { AsTextField, ASChoiceInput, ASDatePicker, AbbyAlert, Link, AsListSelection, Modal, ASDialog },
})
export default class UpdateTaxRateJuly2024Modal extends Vue {
  @Getter('company/company')
  company!: CompanyState['company']

  @Getter('urssaf/urssafJourney')
  urssafJourney!: ReadUrssafJourneyDocument | null

  @Getter('urssaf/modals')
  modals!: UrssafState['modals'] | null

  activity: Activity | null = null
  creationDate: string | null = null
  regime: Regime | null = null
  acre: boolean | null = null

  step: number = 1
  rate: string | null = null

  loadingUpdateRateConfig: boolean = false

  async mounted () {
    await this.$store.dispatch('urssaf/fetchUrssafJourney')
  }

  @Watch('modals.updateTaxRateJuly2024')
  handleOpenedChange (value: boolean) {
    if (!this.company) { return }
    if (value) {
      this.creationDate = this.company.creationDate ? this.$dayjs(this.company.creationDate).format('YYYY-MM-DD') : null
      this.rate = (this.urssafJourney?.rateConfiguration!.find(r => r.type === UrssafRateType.PRESTATIONS_DE_SERVICES_BNC)?.value || 0).toString()
    }
  }

  @Watch('configInformationCase.rate')
  handleRateChange (value: number) {
    this.rate = value.toString()
  }

  get UrssafInformationCase () {
    return UrssafInformationCase
  }

  get configInformationCase () {
    const creationDate = this.creationDate ? this.$dayjs(this.creationDate, 'YYYY-MM-DD') : null
    if (this.regime === Regime.CIPAV) {
      return {
        informationCase: UrssafInformationCase.CIPAV,
        rate: this.acre ? 11.6 : 23.2,
      }
    } else if (creationDate && creationDate.isSameOrAfter(this.$dayjs('2018-01-01', 'YYYY-MM-DD'))) {
      return {
        informationCase: UrssafInformationCase.AFTER_2018,
        rate: this.acre ? 11.55 : 23.1,
      }
    } else {
      return {
        informationCase: UrssafInformationCase.BEFORE_2018,
        rate: this.acre ? 11.6 : 23.2,
      }
    }

  }

  async updateRateConfig () {
    if (!this.company) { return }
    try {
      this.loadingUpdateRateConfig = true
      await this.$store.dispatch('urssaf/changeRateConfig', {
        rateConfiguration: [
          ...(this.urssafJourney?.rateConfiguration || []).filter(r => r.type !== UrssafRateType.PRESTATIONS_DE_SERVICES_BNC),
          {
            type: UrssafRateType.PRESTATIONS_DE_SERVICES_BNC,
            value: this.rate?.replace(',', '.'),
          },
        ],
      })
      await this.$store.dispatch('company/updateCompany', {
        id: this.company.id,
        payload: {
          id: this.company.id,
          hasAcre: this.acre,
          creationDate: this.creationDate || undefined,
        },
      })
      this.$alertsManager.success('Vos taux de cotisations ont bien été mis à jour. Vous êtes en règle pour l\'année 2024.')
      this.$campaignsManager.doNotDisplayAnymore(CampaignType.UPDATE_TAX_RATE_JULY_2024)
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.loadingUpdateRateConfig = false
    }
  }

  get Regime () {
    return Regime
  }

  get regimeTooltip () {
    return '<p>\n' +
        '                            Uniquement pour les professions libérales. Les taux de cotisations entre le régime de la CIPAV et la SSI ne sont pas les mêmes.\n' +
        '                          </p>\n' +
        '                          <p>\n' +
        "                            J'exerce une activité auto-entrepreneur (après 2019) : Je suis automatiquement affilié à la SSI\n" +
        '                          </p>\n' +
        '                          <p>\n' +
        "                            J'exerce une activité libérale non réglementée (avant 2019) : Je suis à la CIPAV mais j'ai un droit d'option : je peux demander à être transféré à la SSI\n" +
        '                          </p>\n' +
        '                          <p class="mb-0">\n' +
        "                            J'exerce une activité libérale réglementée (ex: architecte, maître d'œuvre, experts, ingénieurs...) : Je cotise à la CIPAV\n" +
        '                          </p>'
  }

  openHelpToDetermineActivity () {
    window.open('https://entreprendre.service-public.fr/vosdroits/F32887', '_blank')
  }

  openTaxRateUpdateArticle () {
    window.open('https://lamicrobyflo.fr/augmentation-des-cotisations-pour-les-activites-liberales-les-explications?utm_source=abby', '_blank')
  }

  get taxRateUpdateMandatory () {
    if (!this.activity) { return false }
    return [Activity.MICRO_BNC, Activity.MICRO_BNC_BIC].includes(this.activity)
  }

  get noTaxRateUpdateNeeded () {
    return !this.taxRateUpdateMandatory && this.activity
  }

  get config () {
    if (this.step === 1 && !this.activity) {
      return {
        actionText: 'Mettre à jour mon taux',
        actionDisabled: true,
        hideIgnore: false,
        hideRemindMe: false,
        hidePrevious: true,
      }
    } else if (this.taxRateUpdateMandatory && this.step === 1) {
      return {
        actionText: 'Mettre à jour mon taux',
        hideIgnore: false,
        hidePrevious: true,
        next: () => {
          this.step = 2
        },
        previous: () => {},
      }
    } else if (this.taxRateUpdateMandatory && this.step === 2) {
      return {
        actionText: 'Configurer mon nouveau taux',
        hideRemindMe: true,
        hideIgnore: true,
        hidePrevious: false,
        actionDisabled: !this.creationDate || this.regime === null || this.acre === null,
        next: () => {
          this.step = 3
        },
        previous: () => {
          this.step = 1
        },
      }
    } else if (this.taxRateUpdateMandatory && this.step === 3) {
      return {
        actionText: 'Enregistrer mon taux',
        hideRemindMe: true,
        hideIgnore: true,
        hidePrevious: false,
        actionLoading: this.loadingUpdateRateConfig,
        next: async () => {
          await this.updateRateConfig()
          this.close()
        },
        previous: () => {
          this.step = 2
        },
      }
    } else if (this.noTaxRateUpdateNeeded && this.step === 1) {
      return {
        actionText: 'Fermer cette fenêtre',
        hideRemindMe: true,
        hideIgnore: true,
        hidePrevious: true,
        next: () => {
          this.close()
          this.$campaignsManager.doNotDisplayAnymore(CampaignType.UPDATE_TAX_RATE_JULY_2024)
        },
      }
    } else {
      return {}
    }
  }

  get activities () {
    return [{
      value: Activity.MICRO_BNC,
      text: 'Micro-BNC',
    }, {
      value: Activity.MICRO_BIC,
      text: 'Micro-BIC',
    }, {
      value: Activity.MICRO_BNC_BIC,
      text: 'Micro-BIC + Micro-BNC',
    }, {
      value: Activity.OTHER,
      text: 'Je ne suis pas sous le régime micro',
    },
    ]
  }

  async ignore () {
    const confirm = await this.$confirmManager.ask(
      {
        title: 'Ignorer la mise à jour des taux de cotisations',
        content: '<p>Si votre nature relève d\'une activité libérale, la mise à jour de vos taux de cotisations est obligatoire.</p> <p class="mb-0"><span class="tw-text-red-base tw-font-bold">Si vous ne les mettez pas à jour les calculs effectués par Abby sur votre CA ne seront pas corrects.</span></p>',
        confirmText: 'Oui, ignorer',
        confirmButton: { color: 'error', depressed: true },
      })
    if (confirm) { this.close(); this.$campaignsManager.doNotDisplayAnymore(CampaignType.UPDATE_TAX_RATE_JULY_2024) }
  }

  close () {
    this.$store.dispatch('urssaf/closeModal', 'updateTaxRateJuly2024')
    this.activity = null
    this.step = 1
    this.creationDate = null
    this.regime = null
    this.acre = null
    this.rate = null
    this.loadingUpdateRateConfig = false
  }
}
