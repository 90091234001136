import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { DeclarationCfeStep } from '@abby/core-legacy'
import { saveAs } from 'file-saver'
import type {
  CreateInitialDeclarationCfe,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface CfeState {
    form: CreateInitialDeclarationCfe | null,
    formStep: DeclarationCfeStep,
    loading: boolean,
    isCfeDeclarationModalOpened: boolean,
}

export const state = (): CfeState => ({
  form: null,
  formStep: DeclarationCfeStep.A1,
  loading: false,
  isCfeDeclarationModalOpened: false,
})

export const getters: GetterTree<CfeState, RootState> = {
  form: (state: CfeState) => state.form,
  formStep: (state: CfeState) => state.formStep,
  loading: (state: CfeState) => state.loading,
  isCfeDeclarationModalOpened: (state: CfeState) => state.isCfeDeclarationModalOpened,
}

export const mutations: MutationTree<CfeState> = {
  UPDATE_FORM (state, form: CreateInitialDeclarationCfe) {
    state.form = { ...form }
  },
  SET_FORM_STEP (state, step: DeclarationCfeStep) {
    state.formStep = step
  },
  SET_LOADING (state, value: boolean) {
    state.loading = value
  },
  SET_IS_CFE_DECLARATION_MODAL_OPENED (state, value: boolean) {
    state.isCfeDeclarationModalOpened = value
  },
}

export const actions: ActionTree<CfeState, RootState> = {
  async downloadDocument ({ getters, commit }) {
    commit('SET_LOADING', true)
    try {
      const data = await this.$api.cerfa.sendForm(getters.form)
      saveAs(new Blob([data]), 'declaration-cfe.pdf')
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  openCfeDeclarationModal ({ commit }, value: boolean) {
    commit('SET_IS_CFE_DECLARATION_MODAL_OPENED', value)
  },
}
