
import { Action, Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import {
  ProductUnit,
  ReadTimeRecord,
  UpdateTimeRecord,
  calculateDuration,
  calculateTotalPrice, formattedDuration, ReadOpportunity,
} from '@abby/core-legacy'
import AbbyAmountLabel from '~/components/abby/labels/AbbyAmountLabel.vue'
import TimeRecordMenu from '~/components-legacy/timeTracking/TimeRecordMenu.vue'
import ProductivityOpportunityChip from '~/components/productivity/ProductivityOpportunityChip.vue'
import TimeTrackingChips from '~/components-legacy/timeTracking/TimeTrackingChips.vue'

@Component({
  components: { TimeTrackingChips, ProductivityOpportunityChip, TimeRecordMenu, AbbyAmountLabel },
})
export default class TimeRecordsGroupItem extends Vue {
  @Prop({ required: true })
  timeRecord!: ReadTimeRecord

  @Getter('selection/isActivated')
  _isSelectionModeActivated!: boolean

  get isSelectionModeActivated () {
    if (this.disableSelection) { return false }
    return this._isSelectionModeActivated
  }

  @Getter('selection/timeRecords')
  selectedTimeRecords!: ReadTimeRecord[]

  @Action('timeTracking/updateTimeRecord')
  updateTimeRecord!: (body: { payload: UpdateTimeRecord, id: string }) => Promise<void>

  @Action('selection/toggle')
  toggleSelection!: (timeRecord: ReadTimeRecord) => void

  @Action('opportunity/setOpportunity')
  setOpportunity!: (opportunity: ReadOpportunity | null) => Promise<void>;

  @Prop()
  list!: boolean

  @Prop()
  disableSelection!: boolean

  @Prop()
  disableSuppression!: boolean

  opportunityRemovedId = ''
  opportunityUpdatedId = ''
  timeRecordUpdatedId = ''

  serverTimeRecord: ReadTimeRecord | null = null

  get timeRecordToDisplay () {
    return this.serverTimeRecord || this.timeRecord
  }

  get start () {
    const { startDate, endDate } = this.timeRecordToDisplay
    if (this.$dayjs(startDate).isSame(endDate, 'd')) {
      return this.$dayjs(startDate).format('HH:mm')
    }
    return this.$dayjs(startDate).format('DD MMM HH:mm')
  }

  get end () {
    const { startDate, endDate } = this.timeRecordToDisplay
    if (this.$dayjs(startDate).isSame(endDate, 'd')) {
      return this.$dayjs(endDate).format('HH:mm')
    }
    return this.$dayjs(endDate).format('DD MMM HH:mm')
  }

  get durationInSeconds () {
    const { startDate, endDate } = this.timeRecordToDisplay
    return calculateDuration(startDate, endDate)
  }

  get formattedDuration () {
    return formattedDuration(this.durationInSeconds)
  }

  get totalCostTooltip () {
    const { unitRate, unit } = this.timeRecordToDisplay
    if (!unitRate || !unit) {
      return ''
    }
    const textUnit = this.$t('legacyProductUnits.' + ProductUnit[unit])
    const textUnitRate = this.$amount.formatCents(unitRate).text
    return `${textUnitRate} / ${textUnit}`
  }

  get totalCost () {
    return calculateTotalPrice({
      seconds: this.durationInSeconds,
      unit: this.timeRecordToDisplay.unit,
      unitRate: this.timeRecordToDisplay.unitRate,
    }) / 100
  }

  get isTimeRecordSelected () {
    return this.selectedTimeRecords.some(t => t.id === this.timeRecordToDisplay.id)
  }

  mounted () {
    this.opportunityRemovedId = this.$busManager.on('opportunityRemoved', (opportunity: ReadOpportunity) => {
      if (opportunity.id !== this.timeRecord.opportunityId) { return }
      this.$store.commit('timeTracking/UPDATE_TIME_RECORD', {
        ...this.timeRecord,
        opportunityId: null,
        opportunity: null,
      })
    })

    this.opportunityUpdatedId = this.$busManager.on('opportunityUpdated', (opportunity: ReadOpportunity) => {
      if (opportunity.id !== this.timeRecord.opportunityId) { return }
      this.$store.commit('timeTracking/UPDATE_TIME_RECORD', {
        ...this.timeRecord,
        opportunityId: opportunity.id,
        opportunity,
      })
    })

    this.timeRecordUpdatedId = this.$busManager.on('timeRecordUpdated', (timeRecord) => {
      if (timeRecord.id === this.timeRecord.id) { this.serverTimeRecord = timeRecord }
    })
  }

  beforeDestroy () {
    this.$busManager.off(this.opportunityRemovedId)
    this.$busManager.off(this.opportunityUpdatedId)
    this.$busManager.off(this.timeRecordUpdatedId)
  }

  handleAction () {
    if (this.isSelectionModeActivated) {
      this.toggleSelection(this.timeRecordToDisplay)
    } else {
      this.openTimeTrackingPanel()
    }
  }

  openTimeTrackingPanel () {
    this.setOpportunity(this.timeRecordToDisplay?.opportunity || null)
    this.$store.dispatch('timeTracking/openCreateOrEditPanel', this.timeRecordToDisplay)
  }
}

