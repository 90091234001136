export enum ProductUnit {
  UNIT = 'unit',
  GRAM = 'gram',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  FIXED_RATE = 'fixed_rate',
  YEAR = 'year',
  CHARACTER = 'character',
  LINE = 'line',
  LICENSE = 'license',
  ARTICLE = 'article',
  MONTH = 'month',
  KILOGRAM = 'kilogram',
  KILOMETER = 'kilometer',
  LITER = 'liter',
  BATCH = 'batch',
  METER = 'meter',
  SQUARE_METER = 'square_meter',
  CUBIC_METER = 'cubic_meter',
  LINEAR_METER = 'linear_meter',
  PERSON = 'person',
  TON = 'ton',
  WORD = 'word',
  PAGE = 'page',
  LEAFLET = 'leaflet',
  PARAGRAPH = 'paragraph',
  MINUTE = 'minute',
}
