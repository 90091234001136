import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  AcreDashboardStep,
} from '@abby/core-legacy'

import debounce from 'lodash/debounce'
import type {
  QueryAcreDocument,
  ReadAcreDocument,
  ReadAcreDocumentKanban,
  UpdateAcreDocument,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

export interface AcrePaginationQuery { page: number, limit: number }

export interface AcreBoardQuery {
  search?: string | null,
}

export interface AcreBoardState {
  boardColumns: ReadAcreDocumentKanban[];
  defaultPaginationLimit: number;
  defaultPaginationPage: number;
  currentAcre: ReadAcreDocument | null,
  lastAcreDocument: ReadAcreDocument | null,
  isAcreManagerOpened: boolean;
  paginationQuery: { [key in AcreDashboardStep]?: { page: number, limit: number } }
  query: AcreBoardQuery
}

export const state = (): AcreBoardState => ({
  boardColumns: [],
  defaultPaginationLimit: 25,
  defaultPaginationPage: 1,
  currentAcre: null,
  lastAcreDocument: null,
  isAcreManagerOpened: false,
  paginationQuery: {},
  query: {
    search: undefined,
  },
})

export const getters: GetterTree<AcreBoardState, RootState> = {
  boardColumns: (state: AcreBoardState) => state.boardColumns,
  paginationQuery: (state: AcreBoardState) => state.paginationQuery,
  currentAcre: (state: AcreBoardState) => state.currentAcre,
  lastAcreDocument: (state: AcreBoardState) => state.lastAcreDocument,
  isAcreManagerOpened: (state: AcreBoardState) => state.isAcreManagerOpened,
  query: (state: AcreBoardState) => state.query,
}

export const mutations: MutationTree<AcreBoardState> = {
  SET_BOARD_COLUMNS (state: AcreBoardState, boardColumns: ReadAcreDocumentKanban[]) {
    if (boardColumns.length === 1) {
      const index = state.boardColumns.findIndex(b => b.acreDashboardStep === boardColumns[0].acreDashboardStep)
      state.boardColumns.splice(index, 1, boardColumns[0])
    } else {
      state.boardColumns = boardColumns
    }
  },
  SET_CURRENT_ACRE (state: AcreBoardState, currentAcre: ReadAcreDocument) {
    state.currentAcre = currentAcre
  },
  SET_LAST_ACRE_DOCUMENT (state: AcreBoardState, lastAcreDocument: ReadAcreDocument) {
    state.lastAcreDocument = lastAcreDocument
  },
  SET_ACRE_MANAGER_OPENED (state: AcreBoardState, isAcreManagerOpened: boolean) {
    state.isAcreManagerOpened = isAcreManagerOpened
  },
  SET_PAGINATION_QUERY (state: AcreBoardState, paginationQuery: { [key in AcreDashboardStep]?: { page: number, limit: number } }) {
    state.paginationQuery = paginationQuery
  },
  REMOVE_CARD (state: AcreBoardState, {
    acreDashboardStep,
    cardIndex,
  }: { acreDashboardStep: AcreDashboardStep, cardIndex: number }) {
    const column = state.boardColumns.find(column => column.acreDashboardStep === acreDashboardStep)

    if (!column) {
      return
    }
    column.data.splice(cardIndex, 1)
    column.totalItems -= 1
  },
  UPDATE_QUERY (state: AcreBoardState, query: AcreBoardQuery) {
    state.query = Object.assign(state.query, query)
  },
  REMOVE_ACRE (state, payload: { acreDashboardStep: AcreDashboardStep, index: number }) {
    // @ts-ignore
    state.boardColumns
      .find((bc: ReadAcreDocumentKanban) => bc.acreDashboardStep === payload.acreDashboardStep)
      ?.data?.splice(payload.index, 1)
  },
}

export const actions: ActionTree<AcreBoardState, RootState> = {
  async fetchBoard ({ commit, state }) {
    try {
      const results = await this.$api.acreDocument.getAllKanban({
        page: state.defaultPaginationPage,
        limit: state.defaultPaginationLimit,
        oneCol: false,
        ...state.query,
      }) as ReadAcreDocumentKanban[]

      results.forEach((a) => {
        commit('SET_PAGINATION_QUERY', {
          acreDashboardStep: a.acreDashboardStep,
          paginationQuery: {
            page: state.defaultPaginationPage,
            limit: state.defaultPaginationLimit,
          },
        })
      })
      commit('SET_BOARD_COLUMNS', results)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },

  async fetchLastAcreDocumentByCompanyId ({ commit }, companyId: string) {
    try {
      const result = await this.$api.acreDocument.getLastDocumentByCompanyId(companyId)
      commit('SET_LAST_ACRE_DOCUMENT', result)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },

  async fetchCol ({ state, commit }, params: QueryAcreDocument) {
    try {
      const results = await this.$api.acreDocument.getAllKanban({
        page: params.page,
        limit: params.limit,
        oneCol: true,
        acreDashboardStep: params.acreDashboardStep,
        ...state.query,
      }) as ReadAcreDocumentKanban[]

      commit('SET_PAGINATION_QUERY', {
        acreDashboardStep: params.acreDashboardStep,
        paginationQuery: {
          page: params.page,
          limit: params.limit,
        },
      })

      const _data = state.boardColumns.find(cl => cl.acreDashboardStep === params.acreDashboardStep)?.data || []
      const newPagination = results[0]
      newPagination.data = [
        ..._data,
        ...newPagination.data,
      ]
      commit('SET_BOARD_COLUMNS', [{ ...newPagination }])
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },

  async updateAcreDocument ({ commit, dispatch }, payload: UpdateAcreDocument) {
    try {
      await this.$api.acreDocument.update(payload._id, payload)
      dispatch('fetchBoard')

      commit('SET_ACRE_MANAGER_OPENED', false)
      commit('SET_CURRENT_ACRE', null)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  setBoardColumns ({ commit }, value: ReadAcreDocumentKanban[]) {
    commit('SET_BOARD_COLUMNS', value)
  },
  setPaginationQuery ({ commit }, value: { [key in AcreDashboardStep]?: { page: number, limit: number } }) {
    commit('SET_PAGINATION_QUERY', value)
  },
  setCurrentAcre ({ commit }, currentAcre: ReadAcreDocument) {
    commit('SET_CURRENT_ACRE', currentAcre)
  },
  setLastAcreDocument ({ commit }, acre: ReadAcreDocument) {
    commit('SET_CURRENT_ACRE', acre)
  },
  removeCard ({ commit }, {
    acreDashboardStep,
    cardIndex,
  }: { acreDashboardStep: AcreDashboardStep, cardIndex: number }) {
    commit('REMOVE_CARD', { acreDashboardStep, cardIndex })
  },
  setAcreManagerOpened ({ commit }, { open, currentAcre }: {open: boolean, currentAcre: ReadAcreDocument | null }) {
    commit('SET_CURRENT_ACRE', currentAcre)
    commit('SET_ACRE_MANAGER_OPENED', open)
  },
  debounceFetchBoard: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchBoard')
  }, 300),
  updateQuery ({ commit, dispatch }, query: AcreBoardQuery) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchBoard')
  },
  async deleteAcre ({ commit, getters }, payload: ReadAcreDocument) {
    if (!payload._id) { return false }
    const result = await this.$api.acreDocument.delete(payload._id)
    if (result) {
      const acreDocuments = getters.boardColumns.find((bc: ReadAcreDocumentKanban) => bc.acreDashboardStep === payload.acreDashboardStep)
      const index = acreDocuments?.data?.findIndex((i: ReadAcreDocument) => i._id === payload._id)
      commit('REMOVE_ACRE', {
        acreDashboardStep: payload.acreDashboardStep,
        index,
      })
      this.$alertsManager.success('La demande d\'ACRE a été supprimée avec succès')
    } else {
      this.$alertsManager.error('Un problème a été rencontré')
    }
  },
}
