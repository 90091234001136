
import { Component, Vue, Getter } from 'nuxt-property-decorator'
import { AbbyPlans, PlanFeature, StripeProductFrequency } from '@abby/core-legacy'
import { IAuthUser } from '~/store/auth'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import { usePromotionManager } from '~/composables/marketing/usePromotionManager'
import { Promotion } from '~/services/marketing/promotion'

@Component({
  components: { AbbyPlanChip },
  setup () {
    const { getActivePromotion } = usePromotionManager()
    return {
      getActivePromotion,
    }
  },
})
export default class AbbyProAction extends Vue {
  @Getter('auth/user')
  user!: IAuthUser

  getActivePromotion!: Function

  get activePromotion (): Promotion | undefined {
    return this.getActivePromotion()
  }

  get isPromotionDay () {
    const isSubBusinessYearly = this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_BUSINESS && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    return !isSubBusinessYearly && this.activePromotion
  }

  get isTrial () {
    return this.$planManager.isTrial()
  }

  get isPaidAndMonthly () {
    return [AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_START, AbbyPlans.ABBY_BUSINESS].includes(this.$planManager.whichPlanCompanyHasSubscribed()) && this.$planManager.whichFrequencyPlanCompanyHas() === 'month'
  }

  get isTrialExpired () {
    return this.$planManager.isTrialExpired()
  }

  openAbbyPlansModal () {
    this.$planManager.openAbbyPlansModal({
      feature: 'nav_bottom_button' as PlanFeature,
      openPlan: true,
    })
  }

  get remainingDays () {
    const plan = this.$planManager.companyPlan()
    if (!plan) {
      return null
    }
    const days = this.$dayjs(plan.extendedTrialAt || plan.trialAt).toNow(true)
    return {
      days,
    }
  }
}
